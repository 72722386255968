import React, {useState, useEffect, useContext} from 'react'
import{
  Button,
  TextField,
  Card,
  Grid,
  Box,
  Typography,
  CircularProgress,
  Dialog,
  DialogContent,
  } from "@mui/material";

  import { GenGet, GenGetTemp, GenDel } from 'Loja/state/api';
import { CartContext } from 'Ecommerce';
import { useNavigate } from "react-router-dom";
import NovoCliente from '../novoCliente/Index';
import NovoEnd from '../novoEnd/Index';
import AtualizarEnd from '../novoEnd/PutEnd';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import PutCliente from '../novoCliente/PutCliente';

function PainelCliente() {
  const [user, setUser] = useState(false);
  const [userId, setUserId] = useState(false);
  const [avisos, setAvisos] = useState(false);
  const [enderecos, setEnd] = useState(false);
  const [pedidos, setPedidos] = useState(false);
  const [formCliente, setformCliente] = useState(false);
  const [formPutCliente, setformPutCliente] = useState(false);
  const [formEnd, setformEnd] = useState(false);
  const [formPutEnd, setformPutEnd] = useState(false);  
  const [endPointer, setendPointer] = useState(null);

  const [ completo, setCompleto] = useState(false);
  const [ message, setMessage] = useState('');
  const [ carregando, setCarregando] = useState(true);
  const [msg, setMsg] = useState(0);
  const { setTrack, appUrl } = useContext(CartContext);
  const navigate = useNavigate();
  
  const handleModalCliente = () => {
    setformCliente(true);
  };

  const handleModalPutCliente = () => {
    setformPutCliente(true);
  };

  const handleClosePutCliente = () => {
    setformPutCliente(false);
  };

  const handleCloseCliente = () => {
    setformCliente(false);
  };
  
  const handleModalEnd = () => {
    setformEnd(true);
  };

  const handleCloseEnd = () => {
    setformEnd(false);
  };

  const handleModalPutEnd = (id) => {
    setendPointer(id);
    setformPutEnd(true);
  };

  const handleClosePutEnd = () => {
    setendPointer(null);
    setformPutEnd(false);
  };

   async function  handleDelEnd(id){
    let res = await GenDel(`/api/uendcliente/${id}`);

      if (res.message  === "Endereço excluido com sucesso."){
        const novaListaDeEnderecos = enderecos.filter((endereco) => endereco.id !== id);
        setEnd(novaListaDeEnderecos)
      }
    }
  
  const [formData, setFormData] = useState({
    mensagem: "",
    status: "enviada",
    origem: userId,
    destino: "",
  });
   

async function getUser() {  
  try {
    const response = await GenGet(`api/apiuser`);
    let token = localStorage.getItem('token');
    let res = await GenGetTemp("/api/step", token);
    if ( res === 3 ){
        setCompleto(true);
        setMessage(''); 
    } 
    else if ( res === 1 ){
      setTrack(`${appUrl}meupainel`)
      navigate('/novousuario');
    }
    else if (res === 2 ){
      setMessage('Cadastre um endereço');
    }
    const ultimos_pedidos = response.pedidos.slice(-5);
    setPedidos(ultimos_pedidos);
    setAvisos(response.avisos);

    setUser(response.dados);
    setUserId(response.dados.id);
    setEnd(response.enderecos);
    
  {/*setMsg(response.mensagens);*/}

    setFormData({
      ...formData,
      origem: response.dados.id,
    });

    setCarregando(false);
  } catch (err) {
    
  }
}

useEffect(() => {
  async function fetchData() {
    getUser();
  }
  fetchData();
  
}, []);


function EnderecoItem({ endereco }) {
  
  return (
    <Box key={endereco.id}>
      <Typography sx={{ marginTop: 1 }}>Rua: {endereco.endereco}</Typography>
      <Typography sx={{ marginTop: 1 }}>Número: {endereco.numero}</Typography>
      <Typography sx={{ marginTop: 1 }}>Complemento: {endereco.complemento}</Typography>
      <Typography sx={{ marginTop: 1 }}>CEP: {endereco.cep}</Typography>
      <Typography sx={{ marginTop: 1 }}>Tipo: {endereco.tipo}</Typography>
      <Typography sx={{ marginTop: 1 }}>Cidade: {endereco.cidade}</Typography>
      <Typography sx={{ marginTop: 1 }}>Estado: {endereco.estado}</Typography>
        <Box marginTop={4} display={'flex'} flexDirection={'row-reverse'}>
        <Button onClick={() => handleModalPutEnd(endereco.id)}>Ver | Editar</Button>
        <IconButton onClick={() => handleDelEnd(endereco.id)} aria-label="delete">
            <DeleteIcon />
        </IconButton>
        </Box>    
      <hr />
      <br />
    </Box>
  );
}
  if ( carregando ){
    return (
      <Box sx={{ p: 1, m: 1, width: {md:"100%"}, minHeight:'64vh'}} display="flex"
      justifyContent="center" flexDirection={'column'}>
        <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}><CircularProgress color="success"/></Box>
      </Box>)
  }
  else{
    return (
      <Box className='container' sx={{padding:5}} minHeight={'90vh'}>

        <Dialog open={formCliente} onClose={() => handleCloseCliente()}>
          <DialogContent>
            <NovoCliente/>
          </DialogContent>
        </Dialog> 

        <Dialog open={formPutCliente} onClose={() => handleClosePutCliente()}>
          <DialogContent>
            <PutCliente formData={user}/>
          </DialogContent>
        </Dialog> 

        <Dialog open={formEnd} onClose={() => handleCloseEnd()}>
          <DialogContent>
            <NovoEnd handleModal={setformEnd} />
          </DialogContent>
        </Dialog>  

        <Dialog open={formPutEnd} onClose={() => handleClosePutEnd()}>
          <DialogContent>
            <AtualizarEnd id={endPointer}/>
          </DialogContent>
        </Dialog> 

        <Typography variant='h5' marginBottom={2}>
          Meu Painel
        </Typography>
        <Grid container spacing={2}>
          {/* DADOS DO USUARIO */}
          <Grid item xs={12} >
            <Card className='shadow' sx={{p:2}} >
              <Box sx={{maxHeight: '40vh'}} height={'50vh'}>
                <Typography variant='h6'>Dados do usuário</Typography>
                <hr/>        
                <Box display={'flex'} flexDirection='column' sx={{marginLeft:1, overflowY: 'auto', height:'100%'}}>
                  <Typography >Nome de Usuário: {user.uname}</Typography>
                  <Typography >Email: {user.email}</Typography>
                  {user.nome ? (<>
                    <Typography marginTop={1}>Nome : {user.nome} {user.sobrenome}</Typography>
                    <Typography>CPF: {user.cpf}</Typography>
                    <Typography>Telefone: {user.telefone}</Typography>
                    <Box marginTop={1} display={'flex'} flexDirection={'row-reverse'}>
                      <Button onClick={() => handleModalPutCliente()}>Editar Dados</Button>
                    </Box> 
                    </>
                  ): ( 
                  <Box marginTop={1} display={'flex'} flexDirection={'row-reverse'}>
                    <Button onClick={() => handleModalCliente()}>Completar Dados</Button>
                  </Box>  ) }

                          
                </Box>
              </Box>          
            </Card>
          </Grid>
          {/* ENDEREÇOS */}
          <Grid item xs={12}>
            <Card className='shadow' sx={{p:2}}>
              <Box sx={{padding:1}}>
                <Box display={'flex'} justifyContent={'space-between'}> 
                  <Typography variant='h6'>Endereços</Typography>                               
                  <Button onClick={() => handleModalEnd()}>Cadastrar Endereço</Button>
                </Box>
                <hr/>
              
                <Box display={'flex'} flexDirection='column' sx={{marginLeft:1, overflowY: 'auto'}} height={'100%'}>
                {enderecos && enderecos.length > 0  ? 
                        <>
                        {enderecos.map((endereco) => (
                          <EnderecoItem endereco={endereco} key={endereco.id} />
                        ))}
                      </>
                : <Typography sx={{marginTop:1}}>Nenhum endereço cadastrado. </Typography>}

                </Box>
              </Box>
            </Card>
          </Grid>
          {/* PEDIDOS */}
          <Grid item xs={12}>
              <Card className='shadow' sx={{p:2}}>
              <Box sx={{padding:1 }}>
                <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography variant='h6'>Meus Pedidos</Typography>
                  <Button onClick={() => navigate(`/pedidos`)}>Ver todos</Button>
                </Box>
                  
                  <hr/>
                  <Box display={'flex'} flexDirection='column' sx={{marginLeft:1, overflowY: 'auto', height:'100%'}}>
                  {pedidos && pedidos.length > 0  ? 
                  <>
                  
                  {pedidos.map((item) =>{
                      const dateString = item.created_at;
                      const date = new Date(dateString);
                      const year = date.getFullYear();
                      const month = String(date.getMonth() + 1).padStart(2, "0");
                      const day = String(date.getDate()).padStart(2, "0");
                      const formattedDate = `${year}-${month}-${day}`;

                      return (
                        <Box key={item.id}>
                          <Typography sx={{marginTop:1}}>Numero do pedido : {item.id}</Typography>
                          <Typography sx={{marginTop:1}}>Data do Pedido: {formattedDate}</Typography>
                          <Typography sx={{marginTop:1}}>Valor Total: {item.valor_total}</Typography>
                          <Typography sx={{marginTop:1}}>Status: {item.status}</Typography>

                          <hr/><br/>
                        </Box>
                      )
                    })}
                  </> : <Typography sx={{marginTop:1}}>Nenhum pedido. </Typography> }
                              
                </Box>

                </Box>
              </Card>
          </Grid>
          {/* AVISOS */}
          <Grid item xs={12}>
            <Card className='shadow' sx={{p:2}}>
              <Box sx={{padding:1 }} height={'100%'}>
                <Typography variant='h6'>Meus Avisos</Typography>
                <hr/>
                <Box display={'flex'} flexDirection='column' sx={{marginLeft:1, maxHeight: '400px', overflowY: 'auto'}}  height='100%'>
                {avisos && avisos.length > 0  ? <>

                {avisos.map((item) =>{
                  const isoDateString = item.created_at;
                  const date = new Date(isoDateString);
                  const formattedDate = date.toISOString().split('T')[0]; // Pega a parte da data do ISO e remove a hora
                  
                  return (
                    <Box key={item.id}>
                      <Typography sx={{marginTop:1}}>{formattedDate}<br/> {item.aviso}</Typography>
                      <hr/><br/>
                    </Box>
                  )
                })}

                </>
                : <Typography sx={{marginTop:1}}>Nenhum Aviso </Typography>}          
                </Box>
              </Box>
            </Card>
          </Grid>
          
      </Grid>
        
      </Box>
    )
  }
}

export default PainelCliente