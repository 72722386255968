import React, { useEffect, useState } from 'react';
import ParametrosEmail from './ParametrosEmail'
import { GenGet, GenPost } from 'Admin/state/api';

function ParametrosGerais() {
  const [refresh, setRefresh] = useState(false);
  const [msg, setMsg] = useState(null);
  const [loading, setLoading] = useState(true);
  const [freteFormData, setFreteFormData] = useState({
    integracao_frete: 'melhorenvio',
    frete_isento: false,
    frete_isento_tipo: 'regiao',
  });

  const [paramsFormData, setParamsFormData] = useState({
    facebook: '',
    instagram: '',
    whatsapp: '',
    exibir_estoque:false
  });

  const [pagFormData, setPagFormData] = useState({
    integracao_pagamento: 'mercadopago',
    cupom_ativo: false,
  });

  const [pagvalidationErrors, setPagValidationErrors] = useState({
    integracao_pagamento: 'melhorenvio',
    cupom_ativo: false,
  });

  const [validationErrors, setValidationErrors] = useState({
    integracao_frete: 'melhorenvio',
    frete_isento: false,
    frete_isento_tipo: 'regiao',
  });

  const handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFreteFormData({
      ...freteFormData,
      [e.target.name]: value,
    });
    setValidationErrors({
      ...validationErrors,
      [e.target.name]: value ? '' : 'Campo obrigatório.',
    });
  };

  const handleParamsChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setParamsFormData({
      ...paramsFormData,
      [e.target.name]: value,
    });
  };

  const handlePagChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setPagFormData({
      ...pagFormData,
      [e.target.name]: value,
    });
    setPagValidationErrors({
      ...pagvalidationErrors,
      [e.target.name]: value ? '' : 'Campo obrigatório.',
    });
  };

  const handlePost = async () => {
    try {
      // Ensure frete_isento_tipo has a valid value before posting
      const postData = {
        ...freteFormData,
        frete_isento: freteFormData.frete_isento ? 1 : 0,
      };
  
      const response = await GenPost('/params/frete', postData);
      setRefresh(true); // Trigger refresh after successful post
      setMsg(response.message);
    } catch (err) {
      console.error('Error posting data:', err);
      setMsg(err.message);
    } finally {
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    }
  };

  const handlePagPost = async () => {
    try {
      // Ensure frete_isento_tipo has a valid value before posting
      const postData = {
        ...pagFormData,
        cupom_ativo: pagFormData.cupom_ativo ? 1 : 0,
      };
  
      const response = await GenPost('/params/pagamento', postData);
      setRefresh(true); // Trigger refresh after successful post
      setMsg(response.message);
    } catch (err) {
      console.error('Error posting data:', err);
      setMsg(err.message);
    } finally {
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    }
  };

  const handleParamPost = async () => {
    try {
      // Ensure frete_isento_tipo has a valid value before posting
      const postData = {
        ...paramsFormData,
        exibir_estoque: paramsFormData.exibir_estoque ? 1 : 0,
      };
  
      const response = await GenPost('/params/geral', postData);
      setRefresh(true); // Trigger refresh after successful post
      setMsg(response.message);
    } catch (err) {
      console.error('Error posting data:', err);
      setMsg(err.message);
    } finally {
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        //
        const response = await GenGet('/params/fretes');
        const responseGeral = await GenGet('/params/geral');
        const { frete_isento,  ...rest } = response;
        const { cupom_ativo, exibir_estoque, ...restGeral} = responseGeral 
        const updatedFormData = {
          ...rest,
          frete_isento: frete_isento  === 1 ? true : false,
          cupom_ativo: cupom_ativo === 1 ? true : false,
        };

        setParamsFormData({
          facebook: restGeral.facebook,
          instagram: restGeral.facebook,
          whatsapp: restGeral.facebook,
          exibir_estoque: exibir_estoque  === 1 ? true : false,
        });

        setPagFormData({
          integracao_pagamento: updatedFormData.integracao_pagamento,
          cupom_ativo: updatedFormData.cupom_ativo,
        })

        setFreteFormData({
          integracao_frete: updatedFormData.integracao_frete,
          frete_isento: updatedFormData.frete_isento,
          frete_isento_tipo: updatedFormData.frete_isento_tipo,
        })
  
        setLoading(false);
      } catch (err) {
        console.error('Error fetching fretes:', err);
      }
    };
  
    fetchData();
  }, [refresh]);
  
  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh]);

  return (
    <div className='flex flex-col w-full h-[100%] p-2 overflow-y-scroll text-[10pt]'>
      <h3 className='text-[14pt]'>Parâmetros Gerais</h3>
      <p className='text-[9pt] -mt-2'>configurações gerais de sua loja</p>

      <h3 className='input-classes text-[12pt]'>Configurações de pagamento</h3>
      <div className='w-full p-2'>
          <div className='flex flex-row text-left gap-2 justify-between'>
            <div className='flex flex-row justify-start gap-2 mr-4'>
              <label className='font-semibold'>Cupom de desconto ativo:</label>
              <input
                className='bg-slate-300 rounded h-[30px] flex p-1.5'
                id='cupom_ativo'
                name='cupom_ativo'
                type='checkbox'
                checked={pagFormData.cupom_ativo}
                onChange={handlePagChange}
              />
            </div>
            <div className='flex flex-col justify-between'>
              <label>Integração Pagamento:</label>
              <select
                className='px-4 h-[40px] py-2 rounded border border-gray-300 focus:outline-none'
                aria-label='integracao_pagamento'
                onChange={handlePagChange}
                id='integracao_pagamento'
                name='integracao_pagamento'
                value={pagFormData.integracao_pagamento}
              >
                <option value='mercadopago'>Mercado Pago</option>
              </select>
            </div>
            <div className='flex gap-2 pt-4'>
              <button
                className='bg-blue-500 hover:bg-blue-400 admin-button'
                onClick={handlePagPost}
              >
                Atualizar
              </button>
            </div>
          </div>

      </div>
      <hr/>
      <h3 className='input-classes text-[12pt]'>Configurações de frete</h3>
      <div className='w-full p-2'>
          <div className='flex flex-row text-left justify-between gap-2'>
            <div className='flex flex-row justify-start gap-2 mr-4'>
              <label className='font-semibold'>Frete Isento:</label>
              <input
                className='bg-slate-300 rounded h-[30px] flex p-1.5'
                id='frete_isento'
                name='frete_isento'
                type='checkbox'
                checked={freteFormData.frete_isento}
                onChange={handleChange}
              />
            </div>
            <div className='flex flex-col justify-between'>
              <label>Tipo Isenção:</label>
              <select
                className='px-4 h-[40px] py-2 rounded border border-gray-300 focus:outline-none'
                aria-label='frete_isento_tipo'
                onChange={handleChange}
                id='frete_isento_tipo'
                name='frete_isento_tipo'
                value={freteFormData.frete_isento_tipo}
              >
                <option value='regiao'>Região</option>
                <option value='regiao_retirada'>Região + (retirada)</option>
                <option value='valor'>Acima de um valor</option>
                <option value='valor_retirada'>Acima de um valor + (retirada)</option>
                <option value='regiao_e_valor'>Região e Valor</option>
                <option value='regiao_ou_valor'>Região ou Valor</option>
                <option value='regiao_e_valor_retirada'>Região e Valor + (retirada)</option>
                <option value='regiao_ou_valor_retirada'>Região ou Valor + (retirada)</option>
              </select>
            </div>
            <div className='flex flex-col justify-between'>
              <label>Integração:</label>
              <select
                className='px-4 h-[40px] py-2 rounded border border-gray-300 focus:outline-none'
                aria-label='integracao_frete'
                onChange={handleChange}
                id='integracao_frete'
                name='integracao_frete'
                value={freteFormData.integracao_frete}
              >
                <option value='melhorenvio'>Melhor Envio</option>
              </select>
            </div>
            <div className='flex gap-2 pt-4'>
              <button
                className='bg-blue-500 hover:bg-blue-400 admin-button'
                onClick={handlePost}
              >
                Atualizar
              </button>
            </div>
          </div>        
      </div>
      <hr/>
      <h3 className='input-classes text-[12pt]'>Configurações da loja</h3>
      <div className='w-full p-2'>
          <div className='flex flex-row text-left justify-between gap-2'>
            <div className='flex flex-row justify-start gap-2 mr-4'>
              <label className='font-semibold'>Exibir estoque no produto:</label>
              <input
                className='bg-slate-300 rounded h-[30px] flex p-1.5'
                id='exibir_estoque'
                name='exibir_estoque'
                type='checkbox'
                checked={paramsFormData.exibir_estoque}
                onChange={handleParamsChange}
              />
            </div>
          </div>          
      </div>
      <h3 className='input-classes text-[12pt]'>Redes sociais</h3>
          <div className='flex flex-row text-left justify-between gap-2'>
            <div className='flex flex-col justify-start gap-2 mr-4'>
              <label className='font-semibold'>Facebook</label>
              <input
                className='px-4 py-2 rounded border border-gray-300 focus:outline-none'
                id='facebook'
                name='facebook'
                value={paramsFormData.facebook}
                onChange={handleParamsChange}
              />
            </div>
            <div className='flex flex-col justify-start gap-2 mr-4'>
              <label className='font-semibold'>Instagram</label>
              <input
                className='px-4 py-2 rounded border border-gray-300 focus:outline-none'
                id='instagram'
                name='instagram'
                value={paramsFormData.instagram}
                onChange={handleParamsChange}
              />
            </div>
            <div className='flex flex-col justify-start gap-2 mr-4'>
              <label className='font-semibold'>WhatsApp</label>
              <input
                className='px-4 py-2 rounded border border-gray-300 focus:outline-none'
                id='whatsapp'
                name='whatsapp'
                value={paramsFormData.whatsapp}
                onChange={handleParamsChange}
              />
            </div>
          
          </div>
          <div className='flex w-full flex-row-reverse pt-4'>
              <button
                className='bg-blue-500 hover:bg-blue-400 admin-button'
                onClick={handleParamPost}
              >
                Atualizar
              </button>
            </div>
          {msg && (
            <div className='flex justify-end w-full text-right text-gray-800 text-[10pt] p-2'>
              <p className='text-right'>{msg}</p>
            </div>
          )}
      <hr/>
      <ParametrosEmail />
    </div>
  )
}

export default ParametrosGerais