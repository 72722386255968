import React, { useEffect, useState } from 'react';
import { GenGet } from 'Admin/state/api';
import GenericDataGrid from 'Admin/components/DataGrids/GenericDataGrid';
import EmailTemplate from './EmailTemplate';
import { CircularProgress, Dialog, DialogContent } from '@mui/material';
import EmailsEnviado from './EmailsEnviado';

function EmailsEnviados() {
  const [pendentes, setPendentes] = useState([]);
  const [enviados, setEnviados] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [pointer, setPointer] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const fetchData = async () => {
    try {
      const response = await GenGet('/email/envios');
      console.log(response)
      setEnviados(response.sent_emails);
      setPendentes(response.pending_emails);
      setLoading(false);
      setRefresh(false);
    } catch (err) {
      console.error('Error fetching fretes:', err);
    }
  };

  const handleEmailEnviado = (id) =>{  
    const template = enviados.find(item => item.id === id);
    if (template) {
      setPointer(template.id);
      setOpen(true);
    }
  }
  const handleEmailPendente = (id) =>{  
    const template = pendentes.find(item => item.id === id);
    if (template) {
      setPointer(template.id);
      setOpen(true);
    }
  }

  const handleCloseLogin = () => {
    setOpen(false);
    setPointer(null);
  };

  useEffect(() => {
    fetchData();
  }, []);
  
  useEffect(() => {
    if (refresh) {
      fetchData();      
    }
  }, [refresh]);

  const EnviadoActions = [{ action: 'Visualizar', function: handleEmailEnviado }];
  const PendnetesActions = [{ action: 'Visualizar', function: handleEmailPendente }];
  const columns = ['ID','Status','Titulo', 'Destinatário','Data'];
  return (
    <div className='flex flex-col w-full p-2 text-[10pt]'>
    <h3 className='text-[14pt]'>Emails enviados</h3>
    <p className='text-[9pt] -mt-2'>Seus emails enviados. </p>
    <hr/>

    <Dialog open={open} onClose={handleCloseLogin} style={{minWidth:'70vw'}}>
        <DialogContent>
          <EmailsEnviado id={pointer} />
        </DialogContent>
      </Dialog>
    {!loading ? (
    <div className='flex flex-col w-full gap-4'>
      <GenericDataGrid rows={enviados} columns={columns} per_page={5} actions={EnviadoActions} title="enviados"/>
      <GenericDataGrid rows={pendentes} columns={columns} per_page={5} actions={PendnetesActions} title="em fila"/>
    </div>
    ):(
      <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
        <CircularProgress color="success"/>
      </div>
    )}
    </div>
  )
}

export default EmailsEnviados