import {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'Admin/state/axios';
import { 
  Box, 
  p, 
  Grid, 
  CircularProgress,
  } from "@mui/material";


const token = localStorage.getItem('token');
function ClienteItem( {cliente, usuario, enderecos} ) {


    return (
        <Grid container display={'flex'} flexDirection={'row'} flexWrap={'wrap'} minWidth={'70vw'}>
            <div className='flex flex-col gap-0 m-0 p-2 text-[10pt]'
                xs={12}
                item
                display="flex"
                flexDirection="column" 
                padding='5px'>
                    <h3 className='text-[14pt]'>Detalhes do cliente</h3>
                    <p className='text-[9pt] -mt-2'>visualize os detalhes do determinado cliente</p>
                    <hr/>
                <h3 className='text-[12pt]'>{cliente.nome} {cliente.sobrenome} | id: {cliente.id}</h3>  
                <p className='p-0 m-0'>Telefone : {cliente.telefone}</p>
                <p className='p-0 m-0'>CPF : {cliente.cpf}</p>
                <p className='p-0 m-0'>Status de Perfil : {cliente.status}</p>
                <p className='p-0 m-0'>ID de Usuario : {cliente.user_id}</p>
                <hr />

                <p variant={'h6'}>Dados de Usuário</p>
                <p className='p-0 m-0'>Nome de usuário : {usuario.name}</p>
                <p className='p-0 m-0'>email : {usuario.email}</p>
                <p className='p-0 m-0'>role : {usuario.role}</p>              
                
                <hr />
                {enderecos.length > 0 ? 
                <Box>
                    {enderecos.map((end)=>{
                        return <Box key={end.id}>
                            <p variant={'h6'}>Dados de Endereço</p>    
                            <p className='p-0 m-0'>Tipo : {end.tipo}</p>
                            <p className='p-0 m-0'>Endereço : {end.endereco}</p>
                            <p className='p-0 m-0'>Número : {end.numero}</p>
                            <p className='p-0 m-0'>Complemento : {end.complemento}</p>
                            <p className='p-0 m-0'>Cidade : {end.cidade}</p>
                            <p className='p-0 m-0'>Estadoo : {end.estado}</p>
                            <p className='p-0 m-0'>CEP : {end.cep}</p>
                            <p className='p-0 m-0'>Código País : {end.codigo_pais}</p>
                            <hr />
                            </Box>
                        })                 
                    }

                </Box> :
                <p>Cliente não possui endereço cadastrado.</p>}

            </div>
        </Grid>
    );
  }

function ClienteDetail() {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [cliente, setCliente] = useState({})
    const [usuario, setUsuario] = useState({})
    const [notFound, setNotFound] = useState(false)
    const [enderecos, setEnderecos] = useState({})
    useEffect(() => {
        async function getClientesInfos() {
        try {
            const response = await axios({
            url: `clienteinfos/${id}`,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
            });

            setCliente(response.data?.cliente);
            setUsuario(response.data?.usuario);
            setEnderecos(response.data?.endereços);
            setIsLoading(false);
        } catch (err) {
            setNotFound(true);
            
        }
        }
        getClientesInfos();
    }, [id]);

if(notFound){
    return (<p>Cliente inexistente</p>)
}else{
      return (
    <Box>
    {!isLoading ? 
    <Box display={'flex'}>
        <ClienteItem cliente={cliente} usuario={usuario} enderecos={enderecos}/>
    </Box> 
    :  <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}><CircularProgress color="success"/></Box>}

    </Box>
  )
}

}

export default ClienteDetail