import React, { useContext, useEffect } from 'react';
import { Popover } from '@mui/material';
import axios from 'Loja/state/axios';
import { CartContext } from "Ecommerce";
import { useNavigate, useLocation } from 'react-router-dom';
const token = localStorage.getItem('token');

const CartMenu = ({ anchorCartEl, handleCloseCartMenu, cartRef }) => {
    const { cart, rmvCart, newCart, setcartEvent, appUrl } = useContext(CartContext);
    const navigate = useNavigate();
    const total = calcularTotal(cart);
    const location = useLocation();

    function calcularTotal(cart) {
      let total = 0;
      if (token) {
        for (const item of cart) {
          if (!isNaN(item.quantidade) && !isNaN(item.valor)) {
            total += (parseFloat(item.valor)) ;
          }
        }
      } else {
        for (const item of cart) {
          if (!isNaN(item.quantidade) && !isNaN(item.valor)) {
            total += (parseFloat(item.valor));
          }
        }
      }
      return total;
    }

    useEffect(() => {
        handleCloseCartMenu();
    }, [location]);

    const removeItem = async (id, produto_id) => {
      try {
        await axios.delete(`api/ucartitens/${id}`);
        const novoCart = cart.filter((item) => item.id !== id);
        newCart(novoCart);
        setcartEvent(true);
      } catch (error) {
        console.error(error);
      }
      rmvCart(produto_id);
    };
  
    return (
      <Popover
        open={Boolean(anchorCartEl)}
        anchorEl={anchorCartEl}
        ref={cartRef}
        onClose={handleCloseCartMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className='min-w-[250px] h-[400px] flex flex-col justify-between'>
          <h3 className='font-semibold text-[12pt]'>Seu carrinho</h3>
          <div className='p-1 flex flex-col gap-1 flex-grow overflow-y-scroll'>
            {!token ? (
              <div className='p-2 flex-col gap-2'>
                {cart && cart.length > 0 ? (
                  cart.map(item => (
                    <div className='p-1 flex flex-col my-1 bg-slate-200 rounded-md relative' key={item.id}>
                      <div className='flex flex-row gap-1 w-full'>
                        <img src={`${appUrl}/${item.imagem}`} className='w-[60px] h-[60px] rounded-md' alt={item.nome} />
                        <div className='flex-grow flex flex-col'>
                          <p className='font-semibold text-[10pt]'>{item.nome}</p>
                          <p className='-mt-4 text-[10pt]'>R${item.valor}</p>
                        </div>
                      </div>
                      <div className='w-full p-1 flex flex-row justify-end gap-1'>
                        <p className='font-semibold text-[10pt] pt-[10px] m-0'>Quantidade: {item.quantidade}</p>
                      </div>
                      <span className='absolute -top-2 -right-0 bg-red-400 hover:bg-red-300 text-slate-800 shadow-md font-semibold text-[10pt] rounded-full flex justify-center align-middle items-center h-[18px] cursor-pointer' onClick={() => removeItem(item.id, item.produto_id)} aria-label="delete">
                        remover
                      </span>
                    </div>
                  ))
                ) : (
                  <p className='font-semibold text-[10pt]'>Carrinho vazio</p>
                )}
              </div>
            ) : (
              <div className='p-2 flex-col gap-2'>
                {cart && cart.length > 0 ? (
                  cart.map(item => (
                    <div className='p-1 flex flex-col my-1 bg-slate-200 rounded-md relative' key={item.id}>
                      <div className='flex flex-row gap-1 w-full'>
                        <img src={`${appUrl}/${item.imagem}`} className='w-[60px] h-[60px] rounded-md' alt={item.nome} />
                        <div className='flex-grow flex flex-col'>
                          <p className='font-semibold text-[10pt]'>{item.nome}</p>
                          <p className='-mt-4 text-[10pt]'>R${item.valor}</p>
                        </div>
                      </div>
                      <div className='w-full p-1 flex flex-row justify-end gap-1'>
                        <p className='font-semibold text-[10pt] pt-[10px] m-0'>Quantidade: {item.quantidade}</p>
                      </div>
                      <span className='absolute -top-2 -right-0 bg-red-400 hover:bg-red-300 text-slate-800 shadow-md font-semibold text-[10pt] rounded-full flex justify-center align-middle items-center h-[18px] cursor-pointer' onClick={() => removeItem(item.id, item.produto_id)} aria-label="delete">
                        remover
                      </span>
                    </div>
                  ))
                ) : (
                  <p className='font-semibold text-[10pt]'>Carrinho vazio</p>
                )}
              </div>
            )}
          </div>
          <div className='flex flex-col bg-slate-700 p-1 justify-center items-center align-bottom'>
            <div className='flex flex-row gap-1 align-middle justify-between w-full h-[50px]'>
              <p className='text-slate-100 text-[10pt]'>
                Subtotal: <br />
                <span className='text-[12pt] font-semibold'>
                  R${total.toFixed(2)}
                </span>
              </p>
              {cart && cart.length > 0 && (
              <button
                className='bg-slate-400 hover:bg-yellow-300 p-1 h-[30px] rounded-md'
                onClick={() => navigate('/novaordem')}
              >
                Comprar
              </button>
              )}

            </div>
          </div>
        </div>
      </Popover>
    );
  };

  export default CartMenu;