import React , { useState, useContext } from "react";
import { Box,
    Typography,
    TextField,
    Button,
    Select, 
    MenuItem, 
    FormControl, 
    Alert,
    InputLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GenPost } from "Loja/state/api";
import InputMask from 'react-input-mask';
import { CartContext } from "Ecommerce";
const token = localStorage.getItem('token');



const PrimeiroEnd = () => {
  const navigate = useNavigate()
  const { track, appUrl } = useContext(CartContext);
  const [msg, setMsg] = useState(false);

  const estados = {
    "AC":"Acre",
    "AL":"Alagoas",
    "AP":"Amapá",
    "AM":"Amazonas",
    "BA":"Bahia",
    "CE":"Ceara",
    "DF":"Distrito Federal",
    "ES":"Espírito Santo",
    "GO":"Goiás",
    "MA":"Maranhão",
    "MT":"Mato Grosso",
    "MS":"Mato Grosso do Sul",
    "MG":"Minas Gerais",
    "PA":"Pará",
    "PB":"Paraíba",
    "PR":"Paraná",
    "PE":"Pernambuco",
    "PI":"Piauí",
    "RJ":"Rio de Janeiro",
    "RN":"Rio Grande do Norte",
    "RS":"Rio Grande do Sul",
    "RO":"Rondônia",
    "RR":"Roraima",
    "SC":"Santa Catarina",
    "SP":"São Paulo",
    "SE":"Sergipe",
    "TO":"Tocantins",
  }
  const [validationErrors, setValidationErrors] = useState({
    endereco: "",
    numero: "",
    complemento: "",
    cep: "",
    cidade: "",
    estado: "",
  });
  const [formData, setFormData] = useState({
    tipo:"principal",
    endereco:"",
    complemento:"",
    cep:"",
    cidade:"",
    estado:"",
    codigo_pais:"BRL",
    numero:""
  });
  const setEnd = (e) => {
    setFormData({
      ...formData,
      endereco: e.target.value,
    });
    setValidationErrors({
      ...validationErrors,
      endereco: e.target.value ? "" : "Campo obrigatório.",
    });
  };

  const setNumero = (e) => {
    setFormData({
      ...formData,
      numero: e.target.value,
    });
    setValidationErrors({
      ...validationErrors,
      numero: e.target.value ? "" : "Campo obrigatório.",
    });
  };

  const setComp = (e) => {
    setFormData({
      ...formData,
      complemento: e.target.value,
    });
  };

  const setCidade = (e) => {
    setFormData({
      ...formData,
      cidade: e.target.value,
    });
    setValidationErrors({
      ...validationErrors,
      cidade: e.target.value ? "" : "Campo obrigatório.",
    });
  };

  const setEstado = (e) => {
    setFormData({
      ...formData,
      estado: e.target.value,
    });
    setValidationErrors({
      ...validationErrors,
      estado: e.target.value ? "" : "Campo obrigatório.",
    });
  };
  const setCEP = (e) => {
    const cepValue = e.target.value;
    setFormData({
      ...formData,
      cep: e.target.value,
    });
    setValidationErrors({
      ...validationErrors,
      cep: formData.cep ? "" : "Campo obrigatório.",
    });

  };
  const handleRes = (res) =>{
    setTimeout(() => {
      setMsg(res.msg);
    }, 3000);
    
  }
  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {
      endereco: formData.endereco ? "" : "Campo obrigatório.",
      numero: formData.numero ? "" : "Campo obrigatório.",
      complemento: formData.complemento ? "" : "Campo obrigatório.",
      cep: formData.cep ? "" : "Campo obrigatório.",
      cidade: formData.cidade ? "" : "Campo obrigatório.",
      estado: formData.estado ? "" : "Campo obrigatório.",
    };
  
    // Update validation errors state
    setValidationErrors(validationErrors);
  
    // Check if any validation errors exist
    if (Object.values(validationErrors).some((error) => !!error)) {
      return;
    }
    GenPost('api/uendcliente', formData)
      .then((response) => {
        handleRes(response);
        window.location.href = track;
      })
      .catch((error) => {
      });
  };

  if(token !== null){  

    return (
      <Box sx={{ p: 1, width: {md:"100%"}}} display="flex"
      justifyContent="center">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        borderRadius="0.55rem"
        sx={{ p: 1, m: 2, width:{lg:'40vw', md:'60vw', sm:'70vw', xs:'90vw'}}}
      >
        <Box sx={{p:0}}>
          <Typography variant="h5">
              Dados de endereço:     
          </Typography><hr/>
  
          <Box sx={{marginTop:2,width:'100%'}}>
              <TextField  fullWidth 
                  variant="standard"
                  id="endereco"
                  label="Rua :"
                  name="endereco" 
                  onChange={setEnd}
                  error={!!validationErrors.endereco}
                  helperText={validationErrors.endereco} />       
            </Box>
            <Box sx={{marginTop:2,width:'100%'}}>
              <TextField  fullWidth 
                  variant="standard"
                  id="numero"
                  label="Número :"
                  name="numero" 
                  onChange={setNumero} 
                  error={!!validationErrors.numero}
                  helperText={validationErrors.numero}/>       
            </Box>
            <Box sx={{marginTop:2,width:'100%'}}>
              <TextField  fullWidth 
                  variant="standard"
                  id="complemento"
                  label="Complemento :" 
                  name="complemento" 
                  onChange={setComp} />       
            </Box>
            <Box sx={{marginTop:2,width:'100%'}}>
            <InputMask
              mask={"99.999-999"}
              value={formData.cep}
              onChange={setCEP}
              disabled={false}
              maskChar=" "

            >
            {() => (
              <TextField  fullWidth 
                  variant="standard"
                  id="cep"
                  label="CEP :" 
                  name="cep" 
                  error={!!validationErrors.cep}
                  helperText={validationErrors.cep}
                   /> 
              )}
                 
            </InputMask>   
            </Box>
            <Box sx={{marginTop:2,width:'100%'}}>
              <TextField  fullWidth 
                  variant="standard"

                  id="cidade" 
                  label="Cidade :"
                  name="cidade" 
                  onChange={setCidade} 
                  error={!!validationErrors.cidade}
                  helperText={validationErrors.cidade}/>       
            </Box>
            <Box sx={{marginTop:2,width:'100%'}}>
            <FormControl sx={{marginTop:2 ,width:'100%'}}>
            <InputLabel>Estado</InputLabel>
            <Select
              variant="standard"
              value={formData.estado}
              onChange={setEstado}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
              error={!!validationErrors.estado}
              helperText={validationErrors.estado}
            >
              {Object.keys(estados).map((sigla) => (
                <MenuItem key={sigla} value={sigla} >
                  {estados[sigla]}
                </MenuItem>
              ))}
            </Select>
            </FormControl>       
            </Box>
            {msg ? 
              <Box display={'flex'} flexDirection={'row-reverse'} padding={1} >
                <Alert severity="success" sx={{width:320}} > {msg.message} </Alert>
              </Box>
            : null}
            <Box sx={{m:1, marginTop:2, width:'100%'}} display='flex' flexDirection={'row-reverse'}>
            <Button variant="contained" onClick={handleSubmit} sx={{p:1,m:1}}>Cadastrar</Button>      
            </Box>
            
          </Box>
      </Box>      
    </Box>

  
    );
  } else{
    return(<> Cadastre seus dados.</>)
  }
  
};
export default PrimeiroEnd;