import React , { useState, useEffect } from "react";
import { GenGet, GenPost } from "Admin/state/api";
import { Alert } from "@mui/material";

function TiposEstoque({handleRefresh}) {
    const [scssMsg , setScssMsg] = useState(false);
    const [scssMsgTipo , setScssMsgTipo] = useState(false);
    const [scssMsgEspec , setScssMsgEspec] = useState(false);
    const [tiposEstoque, setTiposEstoques] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [tipoItemId, setTipoItemId] = useState(null);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
        async function getData() {
          try {
            const response = await GenGet("/retornar_tipo_estoque");
            setTiposEstoques(response);
            setLoading(false)
            setRefresh(false);
          } catch (err) {
          }
        }
        getData();
      }, [refresh]);

      const newTipoEstoqueForm = {
        nome : "",
        descricao : "",
        filtro:false
      };
        
      const [validationErrors, setValidationErrors] = useState({
        nome: "",
        descricao: "",
        filtro:false
      });
    
      const [formData, setFormData] = useState({
        nome: "",
        descricao: "",
        filtro:false
      });

      const [tipoItemIdFormData, setTipoItemFormData] = useState({
        estoque_tipos_id:null,
        valor : ""
        });

      const newTipoValorForm = {
        estoque_tipos_id:null,    
        valor : "",
      };      

      const handleChange = (e) => {
            setFormData({
              ...formData,
              [e.target.name]: e.target.value,
            });
            setValidationErrors({
              ...validationErrors,
              [e.target.name]: e.target.value ? "" : "Campo obrigatório.",
            });
      };  

      const handleBolean = (e) => {
          setFormData({
            ...formData,
            filtro : e.target.checked === true ? true : false,
          })

        } 

      const handlePost = () =>{
        async function postData() {
          try {
            const response = await GenPost("/cadastrar_tipo_estoque", formData)
            console.log(response)
            setScssMsgEspec(response.message);
            setTimeout(() => {
              setScssMsgEspec(null);
            }, 3000);
            setRefresh(true);
            handleRefresh(true);
            setFormData(newTipoEstoqueForm);
          } catch (err) {
            setScssMsgEspec(err.message);
            setTimeout(() => {
              setScssMsgEspec(null);
            }, 3000);
          }
        }
        postData();
      }

      const handlePostTipoItem = () =>{
        async function postData() {
          try {
            const response = await GenPost(`/cadastrar_tipo_estoque/item`, tipoItemIdFormData)
            setScssMsg(response.message);
            setTimeout(() => {
              setScssMsg(null);
            }, 3000);
            setRefresh(true);
            setTipoItemFormData(newTipoValorForm)
            handleRefresh(true);
            setTipoItemId(null);
          } catch (err) {
            setScssMsg(err.message);
            setTimeout(() => {
              setScssMsg(null);
            }, 3000);
          }
        }
        postData();
      }

      const handleDeleteTipoItem = (id) =>{
        async function deleteItem() {
          try {
            const response = await GenPost(`/excluir_tipo_estoque_item/${id}`)
            console.log(response)
            setScssMsgTipo(response.message);
            setTimeout(() => {
              setScssMsgTipo(null);
            }, 3000);
            setRefresh(true);
            setTipoItemFormData(newTipoValorForm)
            handleRefresh(true);
            setTipoItemId(null);
          } catch (err) {
            setScssMsgTipo(err.message);
            setTimeout(() => {
              setScssMsgTipo(null);
            }, 3000);
          }
        }
        deleteItem();
      }
      const handleDeleteTipo = (id) =>{
        async function deleteItem() {
          try {
            const response = await GenPost(`/excluir_tipo_estoque/${id}`)
            console.log(response)
            setScssMsg(response.message);
            setTimeout(() => {
              setScssMsg(null);
            }, 3000);
            setRefresh(true);
            setTipoItemFormData(newTipoValorForm)
            handleRefresh(true);
            setTipoItemId(null);
          } catch (err) {
            setScssMsg(err.message);
            setTimeout(() => {
              setScssMsg(null);
            }, 3000);
          }
        }
        deleteItem();
      }
      const handleTipoItemId = (value) => {
        
        setTipoItemFormData({
            ...tipoItemIdFormData,
            estoque_tipos_id: value,
            valor:''})
        setTipoItemId(value);
        
      }

      const handleTipoItemValue = (e) => {
        setTipoItemFormData({
            ...tipoItemIdFormData,
            valor: e.target.value})
      }

  return (
    <div className="flex flex-col w-full text-[10pt]">
              <h3 className='text-[14pt]'>Especificações para estoque</h3>
        <p className='text-[9pt] -mt-2'>especificações destinadas para a gestão do seu produto.</p>
        <br/>
        <div>
            <p className="font-medium text-[12pt]">Itens cadastrados:</p>
            <div className=" flex flex-col gap-2">
            {tiposEstoque && tiposEstoque.map((item) => {
                return(
                <div className="bg-slate-200 p-2 rounded-md">
                  <div className="flex justify-between">
                  <div>
                    <p className="pl-4 font-semibold text-[11pt]">
                      {item.nome}
                    </p>
                    <p className="pl-8 -mt-4 text-[9pt] rounded-md">
                      {item.descricao}
                    </p>
                  </div>
                  {!tipoItemId && (<button className="bg-blue-400 admin-button" onClick={()=>handleTipoItemId(item.id)} >Novo Item</button>)}
                  </div>

                  <div className="p-4 bg-slate-100 rounded-sm flex flex-row flex-wrap gap-2 w-full overflow-scroll no-scrollbar">
                        {item.itens && item.itens.map((item)=>{
                            return(
                              <div className="bg-blue-300 p-2 flex justify-center items-center rounded-md relative min-w-[100px] h-[40px] pt-4">
                              <p className="font-medium text-[9pt]">{item.valor}</p>
                              <span className="p-1 rounded-full bg-red-500 text-slate-100 absolute -top-2 -right-1 h-[20px] w-[20px] items-center flex justify-center cursor-pointer" onClick={()=>handleDeleteTipoItem(item.id)}>x</span>
                              </div>
                              )
                        })
                        }
                  </div>
                    {tipoItemId === item.id && (
                      <div className="flex justify-between">
                        <div className='flex flex-col flex-grow px-2'>
                            <label className='font-medium'>Valor: </label>
                            <div className='flex flex-row gap-2 items-center'>
                            <input 
                                className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
                                id="valor"
                                name="valor"
                                value={tipoItemIdFormData.valor} 
                                onChange={handleTipoItemValue}/>
                                <button className="bg-red-400 hover:bg-red-500 admin-button" onClick={()=>setTipoItemId(null)}>Cancelar</button>
                            <button className="bg-blue-400 hover:bg-blue-500 admin-button" onClick={handlePostTipoItem}>Cadastrar</button>
                            </div>
                            {scssMsg && ( 
                              <div className="w-full flex justify-end p-2">
                                <div className="flex justify-end p-1" >
                                  <Alert severity="info" sx={{width:320}} > {scssMsg} </Alert>
                                </div>  
                              </div>
                            )}
                        </div>                    
                      </div>
                      
                    )}

                      <div className="w-full flex justify-end p-2">
                          <button className="bg-red-400 hover:bg-red-500 admin-button" onClick={()=>handleDeleteTipo(item.id)}>Excluir tipo</button>
                      </div>

                    </div>
                )
                
            })}
              {scssMsgTipo ? 
                <div className="w-full flex justify-end p-2">
                  <div className="flex justify-end p-1" >
                    <Alert severity="info" sx={{width:320}} > {scssMsg} </Alert>
                  </div>  
                </div>
              : null}
          </div>  
        </div>

      <hr/>
      <h3 className='text-[14pt]'>Novo Item de especificação</h3>
      <p className='text-[9pt] -mt-2'>item de especificação destinadas para a gestão do seu produto.</p>
        <div className="flex flex-row gap-4 p-4">
            <div className='flex flex-col justify-between'>
                <label className=''>Nome: </label>
                <input className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
                    id="nome"
                    name="nome"
                    value={formData.nome} 
                    onChange={handleChange}/>
            </div>

            <div className='flex flex-col justify-between flex-grow'>
                <label className=''>Descrição: </label>
                <input className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
                    id="descricao"
                    name="descricao"
                    value={formData.descricao} 
                    onChange={handleChange}/>
            </div>

            <div className='flex flex-col justify-between flex-grow'>
                <label className=''>Apresentar como filtro? </label>
                <input className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
                    id="filtro"
                    type="checkbox"
                    name="filtro"
                    value={formData.filtro} 
                    onChange={handleBolean}/>
            </div>
        </div>
        <div className="w-full flex justify-end p-2">
        {scssMsgEspec ? 
              <div className="flex justify-end p-1" >
                <Alert severity="info" sx={{width:320}} > {scssMsgEspec} </Alert>
              </div>
        : null}
            <button className="bg-blue-400 hover:bg-blue-500 admin-button" onClick={handlePost}>Cadastrar</button>
        </div>
        


    </div>
  )
}

export default TiposEstoque