import React, { useEffect, useState } from 'react';
import { GenGet, GenPost } from 'Admin/state/api';

function NovoEmailTemplate() {
    const [msg, setMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [templates, setTemplates] = useState([]);
    const [formData, setFormData] = useState({
      titulo:'', 
      nome:'',
      conteudo:'',
      link:'',
      tipo:'personalizado'
    })

    const handleTipo = (e) => {
      const selectedTipo = e.target.value;
      
      if (selectedTipo !== 'personalizado') {
        const formValue = templates.find(item => item.tipo === selectedTipo);
        
        if (formValue) {
          setFormData({
            titulo: formValue.titulo, 
            nome: formValue.nome,
            conteudo: formValue.conteudo,
            link: formValue.link,
            tipo: formValue.tipo
          });
        } else {
          setFormData({
            titulo:'', 
            nome:'',
            conteudo:'',
            link:'',
            tipo:selectedTipo
          });
        }

      } else {
        setFormData({
          titulo:'', 
          nome:'',
          conteudo:'',
          link:'',
          tipo:selectedTipo
        });
      }
    };
      

    useEffect(() => {
      const fetchData = async () => {
          try {
              const response = await GenGet(`/email/formdata`);
              console.log(response)
              setTemplates(response.templates_data);
              setIsLoading(false);
          } catch (err) {
              setIsLoading(false)
          }
      };
    
      fetchData();
    }, []);

    const handleField = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    };
  
    const handlePost = async () => {
        if (formData.titulo === '' || formData.nome === '' || formData.conteudo === '' ) {
            setMsg("Os campos 'Email para teste' e 'Email para avisos' são obrigatórios ");
            setTimeout(() => {
                setMsg(null);
            }, 3000);
            return;
        }

      try {
    
        const response = await GenPost('/email/template/create', formData);
        setMsg(response.message);
      } catch (err) {
        console.error('Error posting data:', err);
        setMsg(err.message);
      } finally {
        setTimeout(() => {
          setMsg(null);
        }, 3000);
      }
    };

  return (
    <div className='flex flex-col w-full p-2 text-[10pt]'>
        <h3 className='text-[14pt]'>Novo Template</h3>
        <p className='text-[9pt] -mt-2'>Cadestre um template</p>
        <div className='flex flex-col justify-between'>
                <label>Tipo:</label>
                <select
                  className='px-4 h-[40px] py-2 rounded border border-gray-300 focus:outline-none'
                  aria-label='tipo'
                  onChange={handleTipo}
                  id='tipo'
                  name='tipo'
                  value={formData.tipo}
                >
                  <option value='personalizado'>Personalizado</option>
                </select>
        </div>
        <div className='flex flex-row gap-2 my-2'>
          <div className='flex flex-col gap-1'>
                <p className='p-0 m-0 font-semibold'>Nome</p>
                  <input
                    name="nome"
                    onChange={handleField}
                    id="nome"
                    value={formData.nome}
                    placeholder='título'
                    className="px-4 py-2 rounded border border-gray-300 focus:outline-none"
                  />
          </div>
          <div className='flex flex-col gap-1'>
                <p className='p-0 m-0 font-semibold'>Título</p>
                  <input
                    name="titulo"
                    onChange={handleField}
                    id="titulo"
                    value={formData.titulo}
                    placeholder='título'
                    className="px-4 py-2 rounded border border-gray-300 focus:outline-none"
                  />
          </div>
          <div className='flex flex-col gap-1'>
                  <p className='p-0 m-0 font-semibold'>Link externo</p>
                  <input
                    name="link"
                    onChange={handleField}
                    id="link"
                    value={formData.link}
                    placeholder='link'
                    className="px-4 py-2 rounded border border-gray-300 focus:outline-none"
                  />
          </div>
        </div>
        <div className='flex flex-row gap-2 my-2 w-full'>
          <div className='flex flex-col gap-1 w-full'>
                <p className='p-0 m-0 font-semibold'>Conteúdo</p>
                  <textarea
                    name="conteudo"
                    onChange={handleField}
                    id="conteudo"
                    rows="4"
                    value={formData.conteudo}
                    placeholder='conteúdo'
                    className="px-4 w-full py-2 rounded border border-gray-300 focus:outline-none"
                  />
          </div>
        </div>

            <div className='w-full flex flex-row-reverse'>
            <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handlePost}>Cadastrar</button>
            {msg && (
                <div className='w-full flex flex-row-reverse p-2 justify-center align-middle items-center'>
                    <p className='m-0 p-0 text-gray-800'>{msg}</p>
                </div>
            )}
            </div>
  
      </div>
  )
}

export default NovoEmailTemplate