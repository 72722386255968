import React, {useEffect, useState} from 'react';

import axios from "Admin/state/axios";
import { Box,
   Typography,
   TextField,
   CircularProgress,
   Grid,
   Alert,
   Radio,
   FormControlLabel,
   RadioGroup,
   FormControl} from "@mui/material";
import PhotoWidget from 'Admin/components/PhotoWidget';
   const token = localStorage.getItem('token');


  const NovoProduto = () => {
  const [cat, setCat] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [scssMsg , setScssMsg] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const novoProduto = {
    nome: "",
    slug: "",
    imagens: [],
    descricao: "",
    peso: "",
    altura: "",
    largura: "",
    comprimento: "",
    valor: "",
    categoria_id: 1,
    ativo: true,
    estoque_composto:false
  };
  const [formData, setFormData] = useState(novoProduto);

  const [data, setData] = useState({
    imagens: []
  });

  useEffect(() => {
    setFormData({
        ...formData,
        imagens: data.imagens,
    });
  }, [data]);

  const handleId = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChange = (e) => {
    if (e.target.name === "categoria_id") {
      setFormData({
        ...formData,
        categoria_id: e.target.value,
      });}

     else if (e.target.name === "nome") {
      const inputValue = e.target.value;
      if (inputValue.length <= 100) {
        setFormData({
          ...formData,
          [e.target.name]: inputValue,
        });
        setValidationErrors({
          ...validationErrors,
          [e.target.name]: e.target.value ? "" : "Campo obrigatório.",
        });
      }
      console.log(formData);
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
      setValidationErrors({
        ...validationErrors,
        [e.target.name]: e.target.value ? "" : "Campo obrigatório.",
      });
    }
  };
  
  const handleBolean = (e) =>{
    console.log(e.target.name)
    setFormData({
      ...formData,
      [e.target.name]: e.target.value === "true" ? true : false,
    })
  }

  useEffect(() => {
    async function getCategorias() {
      try {
        const response = await axios({
          url: "catlist",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });
        setCat(response.data);
        setIsLoading(false);
      } catch (err) {

      }
    }
    getCategorias();
  }, []);


  
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    
    const errors = {};
    
    if (formData.imagens.length === 0) {
      errors.imagens = "Selecione pelo menos uma imagem.";
    }
    for (let key in formData) {
      if (!formData[key] && key !== 'estoque_composto') {
        errors[key] = "Campo obrigatório.";
      }
    }

    // Se houver erros, atualiza o estado com os erros de validação
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      console.log(errors)
      return;
    }

    if (hasErrors) {
      console.log(hasErrors)
      return; // Impede o envio do formulário se houver erros
    }

    const newFormData = new FormData();
    newFormData.append('nome', formData.nome);
    newFormData.append('slug', formData.slug);
    newFormData.append('descricao', formData.descricao);
    newFormData.append('peso', parseFloat(formData.peso));
    newFormData.append('altura', parseFloat(formData.altura));
    newFormData.append('largura', parseFloat(formData.largura));
    newFormData.append('comprimento', parseFloat(formData.comprimento));
    newFormData.append('valor', parseFloat(formData.valor));
    newFormData.append('categoria_id', parseInt(formData.categoria_id, 10));
    newFormData.append('ativo', formData.ativo);
    newFormData.append('estoque_composto', formData.estoque_composto);


    // Adicione as imagens ao FormData
    data.imagens.forEach((imagem, index) => {
      newFormData.append(`imagens[${index}]`, imagem);
    });

      try {
        const response = await axios.post('produto', newFormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        });
        setScssMsg(response.data.message);
        setTimeout(() => {
          setScssMsg(null);
        }, 3000);
        setFormData(novoProduto);
        setData({ imagens: [] });
        setScssMsg("Produto cadastrado com sucesso");
        console.log(response)
      } catch (error) {
        console.log(error)
        setScssMsg("Erro ao cadastrar produto");
        setTimeout(() => {
          setScssMsg(null);
        }, 3000);
      }
    };

  return <>
    {!isLoading ? <>

      <Box className='container text-[10pt]' sx={{padding:2}}> 
      <h3 className='text-[14pt]'>Novo Produto</h3>
        <p className='text-[9pt] -mt-2'>Cadastre um produto</p>
        <hr/>
      <Grid container sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}  padding={1}>
        <Grid className='shadow rounded' boxSizing={"border-box"} item xl={8} lg={12} xs={12} sx={{backgroundColor:"white", flexGrow: 1}}>
          <Box display='flex' flexDirection="column" sx={{p:0,m:1 }}>   
              <TextField  
              fullWidth 
                variant="outlined"
                size="small"
                label='Nome :'
                id="nome"
                value={formData.nome} 
                name="nome"
                onChange={handleChange}  
                error={!!validationErrors.nome}
                helperText={validationErrors.nome}
                />
          </Box>
          <Box display='flex' flexDirection="column" sx={{p:0,m:1}}>
        
            <TextField  fullWidth 
              variant="outlined"
              size="small"
              label='Slug :'
              id="slug" 
              name="slug"
              value={formData.slug} 
              onChange={handleChange}
              error={!!validationErrors.slug}
              helperText={validationErrors.slug}  
              />
          </Box>
          <Box display='flex' flexDirection="column" sx={{p:0,m:1}}>

            <TextField  fullWidth 
              multiline
              variant="outlined"
              size="small"
              label='Descrição :'
              id="descricao" 
              name="descricao" 
              value={formData.descricao}
              onChange={handleChange}  
              error={!!validationErrors.descricao}
              helperText={validationErrors.descricao}
              />
          </Box>
          <Box display='flex' flexDirection="column" sx={{p:0,m:1}}>

            <TextField  fullWidth 
              variant="outlined"
              size="small"
              label='Peso(kg) :'
              id="peso" 
              name="peso"
              type="number" 
              value={formData.peso}
              onChange={handleChange} 
              error={!!validationErrors.peso}
              helperText={validationErrors.peso}
              />

          </Box>
          <Box display='flex' flexDirection="column" sx={{p:0,m:1}}>

            <TextField  fullWidth 
              variant="outlined"
              size="small"
              label='Valor :'
              id="valor" 
              name="valor"
              type="number"  
              value={formData.valor}
              onChange={handleChange}
              error={!!validationErrors.valor}
              helperText={validationErrors.valor}  
              />
          </Box>
          <Box display='flex' flexDirection="column" sx={{p:0,m:1}}>

          <TextField  fullWidth 
            variant="outlined"
            size="small"
            label='Altura :'
            id="altura" 
            type="number" 
            name="altura" 
            value={formData.altura}
            onChange={handleChange}
            error={!!validationErrors.altura}
            helperText={validationErrors.altura}  
            />
          </Box>  
          <Box display='flex' flexDirection="column" sx={{p:0,m:1}}>

          <TextField  fullWidth 
            variant="outlined"
            size="small"
            label='Largura :'
            id="largura" 
            type="number" 
            name="largura" 
            value={formData.largura}
            onChange={handleChange}
            error={!!validationErrors.largura}
            helperText={validationErrors.largura}  
            />
          </Box>  
          <Box display='flex' flexDirection="column" sx={{p:0,m:1}}>

          <TextField  fullWidth 
            variant="outlined"
            size="small"
            label='Comprimento :'
            id="comprimento" 
            type="number" 
            name="comprimento" 
            value={formData.comprimento}
            onChange={handleChange}
            error={!!validationErrors.comprimento}
            helperText={validationErrors.comprimento}  
            />
          </Box>  
          <Box display='flex' flexDirection="row" gap={4} justifyContent={'space-around'} sx={{p:0,m:1}} >
          
          <FormControl fullWidth               
            variant="outlined"
            size="small">
          <p className='font-semibold'>Categoría:</p>
            <select
              className="form-select w-[300px]"
              aria-label="categoria"
              onChange={handleId}
              id="categoria_id"
              name="categoria_id"
              value={formData.categoria_id}
              error={!!validationErrors.categoria_id}
              helperText={validationErrors.categoria_id}
            >
              {cat ? cat.map(({ id, nome }) => (
                <option value={id} key={id}>{nome}</option>
              )) : <></>}
            </select>
          </FormControl>
          <RadioGroup
          className='p-2'
              aria-label="ativo"
              name="ativo"
              value={formData.ativo}
              onChange={handleBolean}
              row
            >
              <FormControlLabel
                value={"true"}
                control={<Radio />}
                label="Ativo"
              />
              <FormControlLabel
                value={"false"}
                control={<Radio />}
                label="Inativo"
              />
            </RadioGroup>
          </Box>
          <div className='flex flex-row gap-1'>
              <RadioGroup
                className='p-2'
                aria-label="estoque_composto"
                name="estoque_composto"
                value={formData.estoque_composto}
                onChange={handleBolean}
                row
            >
              <FormControlLabel
                value={"false"}
                control={<Radio />}
                label="Estoque simples"
              />
              <FormControlLabel
                value={"true"}
                control={<Radio />}
                label="Estoque composto"
              />

            </RadioGroup>
          </div>
          <Box
              gridColumn="span 1"
              gridRow="span 1"
              display="flex"
              flexDirection="row-reverse"
              justifyContent="space-between"
              p="0.25rem 1.25rem"
              flex="1 1 100%"
              borderRadius="0.55rem"
              sx={{
                pt:2,
                mb:4,
                width: '100%',}}
          >
          </Box>
          </Grid>
          <Grid className='shadow rounded' boxSizing={"border-box"} item xl={3.8} lg={12} xs={12} sx={{backgroundColor:"white", flexGrow: 1}}>
          <Box display='flex' flexDirection="column" sx={{p:0}}>         
          <h3 className='text-[12pt]'> Imagens do Produto :</h3>
          <hr/>
          {data? (<PhotoWidget setData={setData} data={data} />) :null}
          {validationErrors.imagens ? 
              <Box display={'flex'} flexDirection={'row-reverse'} padding={1} >
                <Alert severity="error" sx={{width:320}} > {validationErrors.imagens} </Alert>
              </Box>
            : null}
            </Box>
            <div className='flex justify-end'>
              <button className={'bg-blue-400 hover:bg-blue-500 admin-button'} onClick={handleSubmit}>Cadastrar</button>
            </div>
            {scssMsg ? 
              <Box display={'flex'} flexDirection={'row-reverse'} padding={1} >
                <Alert severity="info" sx={{width:320}} > {scssMsg} </Alert>
              </Box>
            : null}
          </Grid>
      </Grid>
      </Box> </>

     : <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}><CircularProgress color="success"/></Box>}



   </>
}

export default NovoProduto;