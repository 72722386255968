import React from "react";
import { 
    Dialog, 
    DialogContent,
     } from "@mui/material";

function GenericModal({ Component, pointer, active, setActive, setRefresh }) {
    const handleCloseMenu = () => {
        setActive(false);
        setRefresh(true);
    };
      
    return (
        <>
        {active ? (
        <Dialog open={active} onClose={handleCloseMenu} maxWidth="xl">
            <DialogContent>
                <Component pointer={pointer} handleCloseMenu={handleCloseMenu}/>
            </DialogContent>
        </Dialog>
        ) : null}
        </>
    );
}

export default GenericModal