import React, {useState, useEffect} from 'react'
import{
  Button,
  Grid,
  Box,
  Typography,
  Dialog, 
  DialogContent,
  CircularProgress
  } from "@mui/material";
import { GetAdmin } from 'Admin/state/api';
import VerPedido from 'Admin/components/VerPedido';
import VerPedidoAberto from 'Admin/components/VerPedidoAberto';
import PagamentoPendente from 'Admin/components/PagamentoPendente';

  
const Pedidos = () => {
  const [activeVisualizar, setActiveVisualizar] = useState(false);
  const [activeAberto, setActiveAberto] = useState(false);
  const [activePendente, setActivePendente] = useState(false);
  const [data, setData] = useState([]);
  const [pedidoPointer, setPedidoPointer] = useState(null);
  const [vendas, setVendas] = useState([]);
  const [enviadas, setEnviadas] = useState([]);
  const [pandding, setPandding] = useState([]);
  const [entregues, setEntregues] = useState([]);
  const [recentes, setRecentes] = useState([]);
  
  const [isLoading, setIsLoading] = useState(true);

  const handleCloseMenu = () => {
    setActiveVisualizar(false);
    setActiveAberto(false);
    setActivePendente(false);
  };

  const handleModalVisualizar = (id) => {
    setPedidoPointer(id)
    setActiveVisualizar(true);
  };

  const handleModalAberto = (id) => {
    setPedidoPointer(id)
    setActiveAberto(true);
  };
  const handleModalPendente = (id) => {
    setPedidoPointer(id)
    setActivePendente(true);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'valor_total', headerName: 'Valor Total', width: 130 },
    { field: 'status', headerName: 'Status', width: 130 },
    { field: 'created_at', headerName: 'Criado em', width: 200 },
    { field: 'updated_at', headerName: 'Atualizado em', width: 200 },
    { field: 'atualizado_por', headerName: 'Atualizado por', width: 200 },
    { field: 'user_id', headerName: 'ID do usuário', width: 130 },
  ];

  function PedidoItem({ pedido }) {
    return (
    <Grid container key={pedido.id} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
    <Grid item xs={9}>
      <p variant='body1'>
      <b>ID:</b> {pedido.id} - <b>Data:</b> {pedido.updated_at.slice(0, 10)} - <b>Valor:</b> {pedido.valor_total} - <b>Status:</b> {pedido.status} 
      </p>
    </Grid>
    <Grid item xs={3}><Button onClick={() => handleModalVisualizar(pedido.id)}>Ver Pedido</Button></Grid>    
    <hr/>    
    <br/>
    </Grid>
    );
  }
  
  function PedidoItemAberto({ pedido }) {
    return (
    <Grid container key={pedido.id} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
    <Grid item xs={9}>
      <p >
      <b>ID:</b> {pedido.id} - <b>Data:</b> {pedido.updated_at.slice(0, 10)} - <b>Valor:</b> {pedido.valor_total} - <b>Status:</b> {pedido.status} 
      </p>
    </Grid>
    <Grid item xs={3}><Button onClick={() => handleModalAberto(pedido.id)}>Ver Pedido</Button></Grid>                    
    <hr/>    
    <br />
    </Grid>
    );
  }

  function PagamentoItemAberto({ pedido }) {
    return (
    <Grid container key={pedido.id} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
    <Grid item xs={9}>
      <p variant='body1'>
      <b>ID:</b> {pedido.id} - <b>Data:</b> {pedido.updated_at.slice(0, 10)} - <b>Valor:</b> {pedido.valor_total} <br/> <b>Status:</b> {pedido.status} 
      </p>
    </Grid>
    <Grid item xs={3}><Button onClick={() => handleModalPendente(pedido.id)}>Ver Pedido</Button></Grid>                    
    <hr/>    
    <br />
    </Grid>
    );
  }

  useEffect(() => {
    async function getAdmin() {
      try {
        const response = await GetAdmin();
        setData(response);
        setVendas(response.vendas.todas);
        setEntregues(response.entregues);
        setEnviadas(response.enviadas);
        setRecentes(response.recentes);
        setPandding(response.pendentes);
        setIsLoading(false);
        console.log(response)
      } catch (err) {

      }
    }
    getAdmin();
  }, []);

  return(
      <div className={'text-[10pt] p-2'}>
        <h3 className='text-[14pt]'>Painel de pedidos</h3>
        <p className='text-[9pt] -mt-2'>visualize seus pedidos.</p>  
        <hr/>
        <Dialog open={activeVisualizar} onClose={() => handleCloseMenu()}>
          <DialogContent>
            <VerPedido id={pedidoPointer} />
          </DialogContent>
          </Dialog>  

        <Dialog open={activeAberto} onClose={() => handleCloseMenu()}>
          <DialogContent>
            <VerPedidoAberto id={pedidoPointer} />
          </DialogContent>
          </Dialog>  

          <Dialog open={activePendente} onClose={() => handleCloseMenu()}>
          <DialogContent>
            <PagamentoPendente id={pedidoPointer} />
          </DialogContent>
          </Dialog>  

          {!isLoading ? ( 
          <div className='flex flex-col gap-1 flex-wrap justify-between'>

          <div className='shadow-sm rounded-sm bg-white m-[4px] flex-grow'>
          <div className='p-1'>
                <h3 className='text-[12pt]'>Pedidos Abertos</h3>
                <hr/>
                <div className='flex w-full flex-col overflow-scroll no-scrollbar'>
                {vendas && vendas.length > 0 ? <>
                  {vendas.map((item) => (
                    <PedidoItemAberto pedido={item} key={item.id} />
                  ))}
                </> 
              : 
                <p variant="body1">
                  Não há dados de vendas para exibir.
                </p>}
              </div>        
          </div>
          </div>
          
          <div className='shadow-sm rounded-sm bg-white m-[4px] flex-grow'>
          <div className='p-1'>
                <h3 className='text-[12pt]'>Pagamentos em Aberto</h3>
                <hr/>
                {pandding && pandding.length > 0 ? <>
                  {pandding.map((item) => (
                    <PagamentoItemAberto pedido={item} key={item.id} />
                  ))}
                </> 
                : 
                <p variant="body1">
                  Não há pagamentos em aberto.
                </p>}
              </div>
          </div>

          <div className='shadow-sm rounded-sm bg-white m-[4px] flex-grow'>
          <div className='p-1'>
                <h3 className='text-[12pt]'>Pedidos Enviados</h3>
                <hr/>
                {enviadas && enviadas.length > 0 ? <>
                  {enviadas.map((item) => (
                    <PedidoItem pedido={item} key={item.id} />
                  ))}
                </> 
              : 
                <p variant="body1">
                  Não há dados de vendas para exibir.
                </p>}
              </div>
          </div>


          <div className='shadow-sm rounded-sm bg-white m-[4px] flex-grow'>
          <div className='p-1'>
                <h3 className='text-[12pt]'>Pedidos entregues mais recentes</h3>
                <hr/>
                {recentes && recentes.length > 0 ? <>
                  {recentes.map((item) => (
                    <PedidoItem pedido={item} key={item.id} />
                  ))}
                </> 
              : 
                <p variant="body1">
                  Não há dados de vendas para exibir.
                </p>}
              </div>
          </div>

          <div className='shadow-sm rounded-sm bg-white m-[4px] flex-grow'>
          <div className='p-1'>
                <h3 className='text-[12pt]'>Pedidos Entregues</h3>
                <hr/>
                {entregues && entregues.length > 0 ? <>
                  {entregues.map((item) => (
                    <PedidoItem pedido={item} key={item.id} />
                  ))}
                </> 
              : 
                <p variant="body1">
                  Não há dados de vendas para exibir.
                </p>}
              </div>

          </div>

          <div className='shadow-sm rounded-sm bg-white m-[4px] flex-grow'>
          <div className='p-1'>
                <h3 className='text-[12pt]'>Total de pedidos pagos em aberto</h3>
                <hr/>
                <Box display={'flex'} flexDirection='column' sx={{marginLeft:2}} >
                  <Typography variant={'h6'} sx={{marginLeft:2}}>{data.vendas.total}</Typography>
                </Box>
              </div>

          </div>
          </div>) : 
              <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
                <CircularProgress color="success"/>
              </div>
          }
          
        </div>
  );
};

export default Pedidos;