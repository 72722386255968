import React, {useEffect} from 'react'
import { CssBaseline} from "@mui/material";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import EcommerceRoot from "./Ecommerce";
import AdminRoot from "./Admin";
import SellerRoot from './Seller';
import styled, { createGlobalStyle } from 'styled-components';
import RequiredAuth, { RequiredSellerAuth } from 'Loja/components/RequireAuth';


function App() {


  return (
    <div className="App">

        <BrowserRouter>
            <CssBaseline />
            <Routes>
              <Route path="/*" element={<EcommerceRoot />} /> 
              <Route element={<RequiredAuth/>}>       
                <Route path="/admin/*" element={<AdminRoot />} />
              </Route> 
              <Route element={<RequiredSellerAuth/>}>       
                <Route path="/sales/*" element={<SellerRoot />} />
              </Route> 
            </Routes>
           
        </BrowserRouter>

    </div>
  );
}

export default App;