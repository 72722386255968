import React, { useState, useEffect } from 'react'
import axios from 'Admin/state/axios';
import CustomSelect from 'Admin/components/CustomSelect';
import { PostMov } from 'Admin/state/api';
import { Box,
    CircularProgress,
    Alert,
} from "@mui/material";
const token = localStorage.getItem('token');

function NovaMovEstoque(props) {
    const setRefresh = props.setRefresh
    const [isLoading, setIsLoading] = useState(false);
    const [ produtos, setProdutos] = useState({});
    const [msg, setMsg] = useState('');
    const mock = {
        11:'Produto 1',
        12:'Maça 2',
    }
    const [formData, setFormData] = useState({});
    
    const handleFields = (e) =>{
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
          });
      }

      const handleProduto = (id, value) =>{
        setFormData({
            ...formData,
            produto_id: id,
          });
      }

      const handleTipo = (id, value) =>{
        setFormData({
            ...formData,
            tipo: value,
          });
      }

      const handleSubmit = () => {
        async function PostForm() {
            try {
              const response = await PostMov(formData);
              setMsg(response.message);
              setTimeout(() => {
                setMsg(null);
              }, 3000);
              setRefresh(true);

            } catch (err) {
            setMsg(err.message);
            setTimeout(() => {
                setMsg(null);
              }, 3000);
            }
          }
          PostForm();
      }

      useEffect(() => {
        async function getProdutos() {
          try {
            const response = await axios({
              url: "produtos-list",
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${token}`,
              },
              withCredentials: true,
            });
            setProdutos(response.data);
            setIsLoading(false);
          } catch (err) {
          }
        }
        getProdutos();
        
      }, []);


  return (
    <div className="p-4 min-h-[40vh]"> 
        <h3 className="p-2 text-[16pt]">Nova movimentação</h3>
        <div className='flex flex-col md:flex-row justify-between gap-4 p-2'>
            <CustomSelect data={produtos} label={'Produtos'} onChange={handleProduto} search={true}/>
            <CustomSelect data={{1:'entrada', 2:'saida'}} label={'Tipo'} onChange={handleTipo}/>

            <div  className='flex flex-col gap-1'>
                <input 
                    type="number"
                    name="quantidade"
                    onChange={handleFields}
                    id="quantidade"
                    placeholder='Quantidade'
                    className="px-4 w-[200px] py-2 rounded border border-gray-300 focus:outline-none"/>
            </div>
            <button className='w-[100px] p-1 bg-blue-500 hover:bg-blue-400 shadow-md text-slate-100 font-semibold py-2 rounded border border-gray-300 focus:outline-none' onClick={handleSubmit}>Confirmar</button>

        </div>
        {msg ? 
              <div className='flex justify-end p-1' >
                <Alert severity="info" sx={{width:320}} > {msg} </Alert>
              </div>
            : null}

    </div>
  )
}

export default NovaMovEstoque