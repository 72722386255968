import {useEffect, useState} from 'react';
import axios from "Admin/state/axios";
import { 
  Box, 
  Grid,
  Typography,
  TextField,
  Button

  } from "@mui/material";
const token = localStorage.getItem('token');

function PainelMelhorEnvio() {
    const [formData, setFormData] = useState({
      application: "melhorenvio",
      token:"",
      expires:"2592000"
      });

    const handleChange = (e) => {
          setFormData({
            ...formData,
            [e.target.name]: e.target.value,
          });
        }
    const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('tokens/create', formData);

    } catch (error) {

    }
  };     
  return (
    <div className='flex flex-col gap-1 p-2 w-full text-[10pt]'>
      <h3 className='text-[14pt]'>Painel melhor envio</h3>
      <p className='text-[9pt] -mt-2'>painel de integração melhor envio.</p>
      <hr/>
      <div className='flex flex-wrap justify-between gap-2'>
          <div >
          <p className='p-0 m-0'> Esse é o Painel do Melhor Envio, para obter seu token é necessário ir ate a plataforma do melhor envio.
            <br/>Faça seu login com suas credenciais e va até <b>APLICATIVOS - AUTORIZAÇOES - TOKEN</b> , <br/>
            crie um token e copie o conteudo gerado e cole aqui no campo <b>TOKEN</b>  </p>
          </div>
          <div className='flex flex-col justify-around gap-1 mt-6'>
             
          <p><b>Insira seu token do Melhor Envio</b></p>
          <Box display={'flex'} flexDirection={'column'} >
          <TextField
          sx={{minWidth:300}}
              fullWidth
              variant="outlined"
              size="small"
              label="token:"
              id="token"
              name="token"
              onChange={handleChange}
              />
              <Box display={'flex'} flexDirection={'row-reverse'}>
              <button className='bg-blue-400 hover:bg-blue-500 admin-button mt-2' onClick={handleSubmit}>Confirmar</button>
              </Box>
          </Box>
          </div>
        </div>
  </div>
  )
}

export default PainelMelhorEnvio