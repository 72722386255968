import React, {useEffect, useState} from 'react';
import NovoDestaque from '../forms/destaques/NovoDestaque';
import axios from "Admin/state/axios";
import { 
  Box,  
  Typography, 
  Button,
  Alert
} from "@mui/material";
const appUrl = process.env.REACT_APP_BASE_URL;
const token = localStorage.getItem('token');


function Destaques() {
  const [ destaques, setDestaques] = useState([]);
  const [ isLoading, setIsLoading] = useState(true);
  const [msg, setMsg] = useState(false);
  const handleMsg = (msg) =>{
    setMsg(msg)
    setTimeout(() => {
      setMsg(null);
  }, 5000);
  }
  const DestaqueItem = ({item}) =>{

    async function remover (id) {
      const response = await axios({
        url: `rmv-destaques/${id}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      });
      handleMsg(response.data.message);
      
    }
    
  return(
    <div className='flex flex-row justify-between items-center align-middle m-1 text-[10pt] p-2 rounded-md border-[1px]'> 
      <div className='flex justify-center items-center gap-2'>
      <img className='w-[80px] rounded-md m-1' src={`${appUrl}/${item.produto.imagens[0].imagem}`}/>
      <p className='font-semibold p-1'>{item.produto.nome}</p>
      </div>
    <button onClick={()=>remover(item.id)} className={` bg-red-400 hover:bg-red-500 admin-button`}> Remover Item</button>
    </div>
  )
  }
  useEffect(() => {
    async function getDestaques() {
      try {
        const response = await axios({
          url: "destaques",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });
        setDestaques(response.data);

        setIsLoading(false);
      } catch (err) {
      }
    }
    getDestaques();
  }, [msg]);
  

  return ( 
  <div className='p-2 flex flex-col gap-2 text-[10pt]' > 
  <div>
    <h3 className='text-[14pt]'>Painel de Destaques</h3>
    <p className='text-[9pt] -mt-2'>Destaques seus produtos em sua loja.</p>
    <hr/>
  <br/>
  {!isLoading ? (    
  <>
    <h3 className='text-[12pt]' variant={'h6'}>Produtos em Promoção:  </h3>
        {destaques.promocao && destaques.promocao.length > 0 ? destaques.promocao.map((item) => (        
          <div>          
          <DestaqueItem item={item} key={item.id}/>
          </div>  
          )) : <p>Nenhum produto cadastrado em promoção</p>}
    <br/>
    <h3 className='text-[12pt]' variant={'h6'}>Produtos em Novidades:  </h3>
        {destaques.novidade && destaques.novidade.length > 0 ? destaques.novidade.map((item) => (        
            <div>
            <DestaqueItem item={item} key={item.id}/>
            </div>  
          )) : <p>Nenhum produto cadastrado em novidades</p>}
    <br/>
    <h3 className='text-[12pt]' variant={'h6'}>Produtos em Destaque:  </h3>
        {destaques.destaque && destaques.destaque.length > 0 ? destaques.destaque.map((item) => (
          <div>
            
            <DestaqueItem item={item} key={item.id}/>
          </div>        
          )) : <></>}
    </>) : (<>carregando</>)}
    </div>
    {msg ? 
            <div className='flex flex-row-reverse p-1'>
                <Alert severity="info" sx={{width:320}} > {msg} </Alert>
            </div>
            : null}
    <br/>
    <div>
    <NovoDestaque />  
    </div>
</div>);
}

export default Destaques;