import React, {useState, useEffect, createContext} from 'react'
import {Navigate, Route, Routes } from "react-router-dom";

import Admin from"./Admin/scenes/admin/Index"
import NovoProduto from 'Admin/scenes/forms/produtos/NovoProduto';
import Produtos from 'Admin/scenes/produtos/Index';
import AtualizarProd from 'Admin/scenes/produtos/PutProd';
import NovaCategoria from 'Admin/scenes/forms/categorias/NovaCategoria';
import NovoUsuario from 'Admin/scenes/forms/usuarios/NovoUsuario';
import Clientes from 'Admin/scenes/clientes/Index';
import Usuarios from 'Admin/scenes/usuarios/Index';
import Categorias from 'Admin/scenes/categorias/Index';
import Mensagens from 'Admin/scenes/mensagens/Index';
import Avisos from 'Admin/scenes/avisos/Index';
import Vendas from 'Admin/scenes/vendas/Index';
import Pedidos from 'Admin/scenes/pedidos/Index';
import Artigos from 'Admin/scenes/artigos/Index';
import NovoArtigo from 'Admin/scenes/forms/artigos/NovoArtigo';
import Destaques from 'Admin/scenes/produtos/Destaques';
import NewPannel from 'Admin/scenes/newPannel/NewPannel';
import ClienteDetail from 'Admin/scenes/clientes/ClienteDetail';
import PainelMelhorEnvio from 'Admin/scenes/integracoes/integraMelhor_Envio/PainelMelhorEnvio';
import PainelMercadoPago from 'Admin/scenes/integracoes/integraMercado_Pago/PainelMercadoPago';
import PainelFotos from 'Admin/scenes/produtos/PainelFotos';
import EstoqueMov from 'Admin/scenes/estoque/Index';
import PainelEstoqueProduto from 'Admin/scenes/estoque/PainelEstoqueProduto';
import PainelProdutoEstoque from 'Admin/scenes/estoque/PainelProdutoEstoque';
import Cupoms from 'Admin/scenes/campanhas/Cupoms';
import CarrocelLoja from 'Admin/scenes/painel-loja/CarrosselLoja';
import PainelFrete from 'Admin/scenes/painel-loja/PainelFrete';
import MarketingGeral from 'Admin/scenes/usabilidade/MarketingGeral';
import MarketingUsuarios from 'Admin/scenes/usabilidade/MarketingUsuarios';
import ParametrosGerais from 'Admin/scenes/painel-loja/ParametrosGerais';
import EmailTemplates from 'Admin/scenes/painel-email/EmailTemplates';
import EmailsAvulso from 'Admin/scenes/painel-email/EmailsAvulso';
import EmailsEnviados from 'Admin/scenes/painel-email/EmailsEnviados';
import PainelLeads from 'Admin/scenes/campanhas/PainelLeads';
import CampanhasEmail from 'Admin/scenes/campanhas/CampanhasEmail';
import PublicoLeads from 'Admin/scenes/campanhas/PublicoLeads';
import NovoPublicoLeads from 'Admin/scenes/campanhas/NovoPublicoLeads';
import NovaCampanha from 'Admin/scenes/campanhas/NovaCampanha';
import CampanhaEmail from 'Admin/scenes/campanhas/CampanhaEmail';
import AdicionarLeadsPublico from 'Admin/scenes/campanhas/AdicionarLeadsPublico';

function AdminRoot() {

  return (
        <div>
          <Routes>
            <Route element={<NewPannel />}>
              <Route exact path="/" element={<Admin />} />              
              <Route path="/admin" element={<Navigate to="/admin" replace />} />              
              <Route path="/vendas" element={<Vendas />} />
              <Route path="/avisos" element={<Avisos />} />
              <Route path="/mensagens" element={<Mensagens />} />
              <Route path="/pedidos" element={<Pedidos/>} />
              
              <Route path="/usuarios" element={<Usuarios />} />
                <Route path="/usuario/novo" element={<NovoUsuario />} />
              
              <Route path="/painel-loja" element={<ParametrosGerais />} />

              <Route path="/carrossel" element={<CarrocelLoja />} />
              <Route path="/frete" element={<PainelFrete />} />

              <Route path="/produtos" element={<Produtos/>} />
                <Route path="/produtos/destaques" element={<Destaques />} />
                <Route path="/produto/novo" element={<NovoProduto />} />
                <Route path="/produto/:id" element={<AtualizarProd />} />
  
              <Route path="/categorias" element={<Categorias />} />
                <Route path="/categoria/nova" element={<NovaCategoria/>} />
                
              <Route path="/clientes" element={<Clientes />} />            

              <Route path="/artigos" element={<Artigos />} />
                <Route path="/artigos/novo" element={<NovoArtigo />} />
              
              <Route path="/integracoes/mercado_pago" element={<PainelMercadoPago />} />
              <Route path="/integracoes/melhor_envio" element={<PainelMelhorEnvio />} />

              <Route path="/campanhas/cupoms" element={<Cupoms />} />
              <Route path="/campanhas/leads" element={<PainelLeads />} />
              <Route path="/campanhas/publico/:id" element={<PublicoLeads />} />
              <Route path="/campanhas/publico/addlead/:id" element={<AdicionarLeadsPublico />} />
              <Route path="/campanhas/publico/novo" element={<NovoPublicoLeads />} />
              <Route path="/campanhas/email" element={<CampanhasEmail />} />
              <Route path="/campanhas/email/:id" element={<CampanhaEmail />} />
              <Route path="/campanha/email/nova" element={<NovaCampanha />} />
              

              <Route path="/email/templates" element={<EmailTemplates />} />
              <Route path="/email/avulso" element={<EmailsAvulso />} />
              <Route path="/email/enviados" element={<EmailsEnviados />} />
              
              <Route path="/*" element={<Navigate to="/admin" replace />} />
              <Route path="/clientedetalhes/:id" element={<ClienteDetail/>} />
              <Route path="/novoartigo" element={<NovoArtigo/>}/>
              <Route path="/especificacoes-estoque" element={<PainelEstoqueProduto/>}/>
              <Route path="/estoque-produto" element={<PainelProdutoEstoque/>}/>
              
              <Route path="/usabilidade/geral" element={<MarketingGeral/>}/>
              <Route path="/usabilidade/usuarios" element={<MarketingUsuarios/>}/>
              <Route path="/fotos" element={<PainelFotos/>}/>
            </Route>
          </Routes>
    </div>
  );
}

export default AdminRoot;