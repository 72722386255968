import React , { useState, useEffect } from "react";
import { Box,
    Typography,
    Button,
    CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GenPut, GenGet } from "Loja/state/api";
import { GetOrdem } from "Admin/state/api";

function VerPedidoAberto(props) {
    const id = props.id;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (id) {
      async function getPedido() {
        try {
          const response = await GetOrdem(id);
          setData(response);

        } catch (err) {

        } finally {
          setIsLoading(false);
        }
      }
      getPedido();
    }
  }, [id]);


  const handleSubmit = (id) => {
        GenPut(`api/ordem/finalizando/${id}`, {status:'enviando'})
      .catch((error) => {

      });
  };

  if(!isLoading && data){  

    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        borderRadius="0.55rem"
      >
        <Box sx={{p:0}} width={500}>
          <Typography variant="h5">
              Detalhes do Pedido
          </Typography><hr/>
            <Typography variant='body1'>
            <b>Nome do usuário:</b> {data.user.name}<br/>
            <b>E-mail:</b> {data.user.email}<br/>
            <b>CPF:</b> {data.cliente.cpf}<br/>
            <b>Telefone:</b> {data.cliente.telefone}<br/>
            <br/>
            <b>Data:</b> {data.detalhesOrdem.created_at.slice(0, 10)}<br/> 
            <b>ID:</b> {data.detalhesOrdem.id} <br/>
            <b>Valor:</b> {data.ordem.valor_total} <br/>
            <b>Status:</b> {data.ordem.status} 
            </Typography>
            <hr/>
            
            <Typography variant='h6'> Itens do Pedido</Typography>
            {data.itensOrdem.map((item) => {
                return (
                    <Box key={item.id}>
                    <Typography>Produto: {item.nome_produto}</Typography>
                    <Typography>Quantidade: {item.quantidade}</Typography>
                    <Typography>Preço Unitário: {item.preco_unitario}</Typography>
                    {item.estoque_unidade && <Typography>Tipo: {item.estoque_unidade.nome}</Typography>}
                    {item.estoque_unidade && <Typography>Produto: {item.estoque_unidade.produto_id}</Typography>}
                    <hr/>
                    <br/>
                    </Box>
                );
                })}
            
            <Typography variant='h6'> Endereço:</Typography>
            {data.ordem.retirada === 1 ? (<p>Pedido a retirar na loja.</p>) : (
              <>
              {data.detalhesOrdem.end ? <>            
                <Typography>{data.detalhesOrdem.end.endereco} - {data.detalhesOrdem.end.complemento}</Typography>
                <Typography>{data.detalhesOrdem.end.cidade} - {data.detalhesOrdem.end.estado}</Typography>
                <Typography>{data.detalhesOrdem.end.cep} - {data.detalhesOrdem.end.codigo_pais}</Typography>
                {data.ordem.melhor_envio && data.ordem.melhor_envio.id_etiqueta ? (
                  <Typography>
                    Etiqueta Melhor Envio: <br /><b>{data.ordem.melhor_envio.id_etiqueta}</b>
                  </Typography>
                ) : <>Nenhuma Etiqueta vinculada ao pedido, verificar o motivo <br/></>}
                </> : <> Nenhum endereço vinculado ao pedido. <br/></>}
              </>
            )} 
            <hr/>
            <Typography variant='h6' style={{marginTop:5}}> Informaçoes Mercado Pago</Typography>
            {data.pagamento ? (
            <>
            {!data.pagamento.error ? (
            <>
     
            <Typography>ID MERCADO PAGO - {data.pagamento.id}</Typography>
            <Typography>TIPO - {data.pagamento.payment_method_id}</Typography>
            <Typography>Valor - R${data.pagamento.transaction_amount}</Typography>
            <Typography>Status do Pagamento - <b>{data.pagamento.status}</b></Typography>
            <Box sx={{p:0,m:1, marginTop:2, width:'100%'}} display='flex' flexDirection={'row-reverse'}>
            </Box>
            </>) 
            : (
            <>
            <Typography padding={1}>{data.pagamento.error}</Typography>
            </>)}
            
            </>
            )             
          : (<> Nenhum pagamento vinculado ao pedido, verificar o motivo. </>)}  


          </Box>
          <Box display={'flex'} flexDirection={'row-reverse'} paddingTop={2}>
          <Button  variant="outlined" onClick={() => handleSubmit(data.ordem.id)} sx={{minWidth : 100}}>Fechar Pedido</Button>  
          </Box>
      </Box>      


  
    );
  } else {
    <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"30vh"}}><CircularProgress color="success" /></Box>;
  }
  
};
export default VerPedidoAberto;