import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Button,
  Dialog, 
  DialogContent,
  CircularProgress,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "Admin/state/axios";
import AtualizarCategoria from './PutCategoria';
import { GenDel, GenPost } from 'Admin/state/api';
import NovaCategoria from '../forms/categorias/NovaCategoria';
import GenericDataGrid from 'Admin/components/DataGrids/GenericDataGrid';

const token = localStorage.getItem('token');

function Categorias() {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [activeAberto, setActiveAberto] = useState(false);
  const [categoriaPointer, setCategoriaPointer] = useState(null);
  const [expandedPanel, setExpandedPanel] = useState(null);
  const [refresh, setRefresh] = useState(false)
  const nav = useNavigate();

  const handleAccordionChange = (panel) => {
    setExpandedPanel(panel === expandedPanel ? null : panel);
  };

  useEffect(() => {
    async function getCategorias() {
      try {
        const response = await axios({
          url: "admin/categorias",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });
        setData(response.data);
        setIsLoading(false);
        setRefresh(false)
      } catch (err) {
        setRefresh(false)
      }
    }
    getCategorias();
  }, [refresh]);

  const handleModalAberto = (id) => {
    setCategoriaPointer(id)
    setActiveAberto(true);
  };

  const handleCloseMenu = () => {
    setCategoriaPointer(null)
    setActiveAberto(false);
  };

  const handleDelete = (id) => {
    async function PostForm() {
      try {
        const res = await GenPost(`/delcategoria/${id}`);
        setRefresh(true);

      } catch (err) {

      }
    }
    PostForm()
    }

    const actions = [{ action: 'Visualizar', function: handleModalAberto }, { action: 'Excluir', function:handleDelete }];
    const columns = ['ID', 'Nome'];
  return (
    <div className={'flex flex-col gap-2 flex-wrap w-full text-[10pt]'}>
      <h3 className='text-[14pt]'>Painel de Categorias</h3>
      <p className='text-[9pt] -mt-2'>Visualize e gerencie suas categorías de produto.</p>
      <hr/>
      <Dialog open={activeAberto} onClose={() => handleCloseMenu()}>
        <DialogContent>
          <AtualizarCategoria id={categoriaPointer} />
        </DialogContent>
      </Dialog>  

    {!isLoading ? (
    <>
      {data.length > 0 ? 
        <>
          <div className='min-h-[400px] w-full p-4'>
          <h3 className='text-[12pt]' variant={'h6'}>Categorías cadastradas:</h3>
            <GenericDataGrid rows={data} columns={columns} per_page={15} actions={actions} title="Categorías"/>
            <br/>
            <h3 className='text-[12pt]' variant={'h6'}>Cadastre uma nova categoría:</h3>
            <NovaCategoria setRefresh={setRefresh}/>
          </div>
        </> : 
        (
          <div className='flex flex-col'>
            <p>Nenhuma Categoría Cadastrada.</p>
            <Typography margin={2} variant={'h6'}>Cadastre uma nova categoría:</Typography>
            <NovaCategoria setRefresh={setRefresh}/>
          </div>
        )}
        </>

    ) : <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}><CircularProgress color="success"/></Box>}
  </div>
  );
};
export default Categorias;