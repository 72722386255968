import { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  Drawer,
  ListItem,
  List,
  ListItemText 
} from "@mui/material";
import { useMediaQuery } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import Produto from 'Loja/components/compLoja/Produto';
import CloseIcon from '@mui/icons-material/Close';
import { GenGet } from 'Loja/state/api';


const Categorias = ({ handleCategoryChange, handleFilterChange, handleFilterTipos }) => {
  const [catdata, setCatData] = useState([]);
  const isSmScreen = useMediaQuery('(max-width:600px)');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [filtroDinamico, setFiltroDinamico] = useState(false);
  useEffect(() => {
    async function getCategorias() {
      try {
        const response = await GenGet("api/ucategorias");
        const searchOptions = await GenGet("api/ucategorias/search");
        if(searchOptions.length > 0){
          setFiltroDinamico(searchOptions);
        }
        
        console.log(searchOptions)
        setCatData(response);
      } catch (err) {
      }
    }
    getCategorias();
  }, []);

  const handleButtonClick = (categoryId) => {
    handleCategoryChange({ target: { value: categoryId } });
  };

  return (
    <Box p={2}>
      {isSmScreen && (
            <IconButton onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
              {isDrawerOpen ? <CloseIcon /> : <SearchIcon />}
            </IconButton>
          
        )}
      <Drawer anchor="left" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <Box sx={{ width: 250 }}>
        <div className='flex'>
          <input
            className='w-full p-2 bg-slate-300 rounded-md'
            placeholder="Pesquisar"
            defaultValue=""
            variant="outlined"
            size="small"
            onChange={handleFilterChange}
          />
          <IconButton aria-label="delete" onClick={() => handleFilterChange({ target: { value: "" } })}>
            <SearchIcon />
          </IconButton>
        </div>

          <List>
            <ListItem style={{ cursor: 'pointer' }} onClick={() => {handleButtonClick(0); setIsDrawerOpen(false)}}>
              <ListItemText primary="TODAS" />
            </ListItem>
            {Array.isArray(catdata) && catdata.length > 0 &&
              catdata.map(item => (
                <ListItem style={{ cursor: 'pointer' }} onClick={() => {handleButtonClick(item.id); setIsDrawerOpen(false)}}>
                  <ListItemText primary={item.nome} />
                </ListItem>
              ))
            }
          </List>
        </Box>
      </Drawer>

      <br/>
      {!isSmScreen? (<>
        <div className='flex'>
          <input
            className='w-full p-2 bg-slate-300 rounded-md'
            placeholder="Pesquisar"
            defaultValue=""
            variant="outlined"
            size="small"
            onChange={handleFilterChange}
            />
          <IconButton aria-label="delete" onClick={() => handleFilterChange({ target: { value: "" } })}>
            <SearchIcon />
          </IconButton>
        </div>
        <Box mb={2} display="flex" flexDirection="column" gap={1}>
        <h3 className='text-base font-semibold'>Categorías</h3>
        <div
          className={`cursor-pointer p-2 hover:bg-slate-300 rounded-md`} 
          onClick={() => handleButtonClick(0)}
        >
          Todas
        </div>
        {Array.isArray(catdata) && catdata.length > 0 &&
          catdata.map(item => (
            <div
              className={`cursor-pointer p-2 hover:bg-slate-300 rounded-md`} 
              key={item.id}
              onClick={() => handleButtonClick(item.id)}
            >
              {item.nome}
            </div>
          ))
        }
        {filtroDinamico && filtroDinamico.map(item =>(
        <div>
        <hr/>
          <h3 className='text-base font-semibold' >{item.nome}</h3>
            <div className='pl-2 w-[100%]'>
            <p className='p-2 w-[100%] -mt-2 hover:bg-slate-300 rounded-md cursor-pointer' onClick={()=> handleFilterTipos(null)}>exibir tudo</p>
              <div className='w-full flex flex-row gap-1 flex-wrap -mt-2'>
              {item.itens && item.itens.map(item =>(
                <div className='h-[30px] min=w-[60px] p-2 border-1 border-slate-400 hover:bg-slate-300 rounded-sm flex justify-center text-center cursor-pointer' onClick={()=> handleFilterTipos(item.id)}>
                  <p className='p-0 m-0'>{item.valor}</p>
                </div>
              ))}
              </div>

            </div>
      </div>
        ))}

      </Box>
      </>):null}
    </Box>
  );
};

function ProdutosCategoria() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cat, setCat] = useState(0);
  const [filter, setFilter] = useState(null);
  const [tiposFilter, setTiposFilter] = useState(null);
  const navigate = useNavigate();
  const [notFound, setnotFound] = useState(false);
  const { categoria } = useParams();

  const handleCategoryChange = (event) => {
    const selectedCategory = parseInt(event.target.value);
    setCat(selectedCategory === 0 ? 0 : selectedCategory);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleFilterTipos = (id) => {
    setTiposFilter(id);
  };

  useEffect(() => {
    async function getProdutos() {
      try {
        const response = await GenGet(`api/uprodutos-cat/${categoria}`);
        setData(response);
        if (response.length === 0){
          setnotFound(true);
        }
        setIsLoading(false);
      } catch (err) {
        setnotFound(true);
      }
    }
    getProdutos();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [cat]);

  const filteredData = data.filter(produto => {
    const categoriaValida = cat === 0 || produto.categoria_id === cat;
    const tipoValido = tiposFilter === null || (
      produto.estoque && 
      produto.estoque.unidade_estoque && 
      produto.estoque.unidade_estoque.some(unidade => 
        unidade.quantidade > 0 && 
        unidade.itens && 
        unidade.itens.some(item => item.valor.id === tiposFilter)
      )
    );
    return categoriaValida && tipoValido;
  });

  const filteredDataWithSearch = filter
    ? filteredData.filter(item => item.nome.toLowerCase().includes(filter.toLowerCase()))
    : filteredData;

  if (notFound) {
    return navigate('/');
   } 
   else {
  return (
    <div className='flex flex-row'>
      <Categorias handleCategoryChange={handleCategoryChange} handleFilterChange={handleFilterChange} handleFilterTipos={handleFilterTipos} cat={cat} />
      <div className='p-2 w-full'>
        {!isLoading ? (
          <div className='flex gap-2 w-full flex-wrap justify-start'>
            {filteredDataWithSearch.map(({ id, nome, slug, descricao, valor, imagens, categoria_id, peso, altura, largura, comprimento }) => (
              <div className={'w-[90%] md:w-[30%] md:min-w-[250px] justify-between'}key={id}>
                <Produto
                  id={id}
                  imagem={imagens.find(img => img.capa)?.imagem}
                  nome={nome}
                  slug={slug}
                  descricao={descricao}
                  valor={valor}
                  categoria={categoria_id}
                  peso={peso}
                  altura={altura}
                  largura={largura}
                  comprimento={comprimento}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className='p-1 w-full h-[70vh] flex justify-center items-center'>
            <CircularProgress color="success" />
          </div>
        )}
      </div>
    </div>
  );

}}

export default ProdutosCategoria;


