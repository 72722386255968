import React, { useEffect, useState } from 'react';
import { GenGet, GenPost } from 'Admin/state/api';
import CustomSelect from 'Admin/components/CustomSelect';

function EmailsAvulso() {
    const [msg, setMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [templates, setTemplates] = useState([]);
    const [templatesList, setTemplateList] = useState([]);
    const [templateSelected, setTemplateSelected] = useState(false);
    const [userList, setUserList] = useState([]);
    const [userData, setUserData] = useState(null);
    const [userSelected, setUserSelected] = useState(null);

    const [formData, setFormData] = useState({
        titulo : '',
        destinatario : '',
        conteudo : '',
        link : '',
        user_id : null,
        email_templates_id : null,
    })
  
    const handleTemplate = (e) => {
      const selectedTipo = e.target.value;

      if (selectedTipo !== 0) {
        const formValue = templates.find(item => item.id === Number(selectedTipo));

        if (formValue) {
          setFormData({
            ...formData,
            titulo: formValue.titulo, 
            nome: formValue.nome,
            conteudo: formValue.conteudo,
            link: formValue.link,
            email_templates_id : Number(selectedTipo)
            
          });
          setTemplateSelected(true);
        
        } else {
          setFormData({
            ...formData,
            titulo:'', 
            nome:'',
            conteudo:'',
            link:'',
            email_templates_id : 0,
          });
          setTemplateSelected(false);
        }

      } else {
        setFormData({
          ...formData,
          titulo:'', 
          nome:'',
          conteudo:'',
          link:'',
          email_templates_id : 0,
        });
      }
    };
      

    useEffect(() => {
      const fetchData = async () => {
          try {
              const response = await GenGet(`/email/formdata`);
              setTemplates(response.templates_data);
              setTemplateList(response.templates);
              setUserData(response.users)
              
              
              const formattedUserList = response.users.reduce((acc, user) => {
                acc[user.id] = user.nome;
                return acc;
              }, {});
              setUserList(
                {...formattedUserList,
                0:'Cliente Avulso'});

              setIsLoading(false);
          } catch (err) {
              setIsLoading(false)
          }
      };
    
      fetchData();
    }, []);

    const handleField = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    };

    const handleUser = (id) => {
      const user = userData.find(item => item.id === Number(id));
  
      if (user) {
        setUserSelected(true);
        setFormData({
          ...formData,
          user_id: user.id,
          destinatario: user.email
        });
      } else {
        setUserSelected(false);
        setFormData({
          ...formData,
          user_id: null,
          destinatario: ''
        });
      }
    };

    const handlePost = async () => {
        if (formData.titulo === '' || formData.conteudo === '' || formData.destinatario === '' ) {
            setMsg("Os campos 'Titulo', 'Conteúdo' e 'Destinatário' são obrigatórios ");
            setTimeout(() => {
                setMsg(null);
            }, 3000);
            return;
        }

      try {
    
        const response = await GenPost('/email/envio/avulso', formData);
        setMsg(response.message);
      } catch (err) {
        console.error('Error posting data:', err);
        setMsg(err.message);
      } finally {
        setTimeout(() => {
          setMsg(null);
        }, 3000);
      }
    };

    const handleTest = async () => {
      
        try {
          const response = await GenPost('/email/test', formData);
          setMsg(response.message);
        } catch (err) {
          console.error('Error posting data:', err);
          setMsg(err.message);
        } finally {
          setTimeout(() => {
            setMsg(null);
          }, 3000);
        }
      };
      
  return (
    <div className='flex flex-col w-full p-2 text-[10pt]'>
    <h3 className='text-[14pt]'>Novo Email</h3>
    <p className='text-[9pt] -mt-2'>Envie um email avulso.</p>
    <hr/>
        <div className='flex flex-row gap-4 my-2'>
            <div className='flex flex-col gap-1 min-w-[220px]'>
            <p className='p-0 m-0 font-semibold'>Título</p>
            {templateSelected ? ( 
                  <p>{formData.titulo}</p>
                ) : ( 
                <input
                name="titulo"
                onChange={handleField}
                id="titulo"
                value={formData.titulo}
                placeholder='título'
                className="px-4 py-2 rounded border border-gray-300 focus:outline-none w-full"
                />)}
            </div>

            <div className='flex flex-col gap-1 min-w-[220px]'>
                <p className='p-0 m-0 font-semibold'>Link externo</p>
                {templateSelected ? ( 
                  <p>{formData.link}</p>
                ) : ( 
                <input
                name="link"
                onChange={handleField}
                id="link"
                value={formData.link}
                placeholder='link'
                className="px-4 py-2 rounded border border-gray-300 focus:outline-none w-full"
                />)}
            </div>
            <div className='flex flex-col justify-between min-w-[220px]'>
                <label className='p-0 m-0 font-semibold'>Template:</label>
                <select
                className='px-4 h-[40px] py-2 rounded border border-gray-300 focus:outline-none w-full'
                aria-label='template_id'
                onChange={handleTemplate}
                id='template_id'
                name='template_id'
                value={formData.template_id}
                >
                <option value={0}>Nenhum Template</option>
                {templatesList && templatesList.map(item=>(
                  <option value={item.id}>{item.nome}</option>
                ))}                
                </select>
        </div>
        </div>
        
        <div className='flex flex-row gap-2 my-2 w-full'>
        <div className='flex flex-col gap-1 w-full'>
            <p className='p-0 m-0 font-semibold'>Conteúdo</p>
            {templateSelected ? ( 
                  <div className='w-full flex  min-h-[200px] flex-col p-3 border-[1px] border-gray-800 rounded-md' dangerouslySetInnerHTML={{ __html: formData.conteudo }}></div>
                ) : ( 
                <textarea
                name="conteudo"
                onChange={handleField}
                id="conteudo"
                rows="4"
                value={formData.conteudo}
                placeholder='conteúdo'
                className="px-4 w-full py-2 rounded border border-gray-300 focus:outline-none"
                />)}
            </div>
        </div>

        <div className='flex flex-row gap-2 my-2'>
            <div className='flex flex-col gap-1 min-w-[250px]'>
            <p className='p-0 m-0 font-semibold'>Usuário</p>
            {userList && (<CustomSelect data={userList} label={'Selecione'} onChange={handleUser} search={true}/>)}
            </div>
            <div className='flex flex-col gap-1'>
            <p className='p-0 m-0 font-semibold'>Destinatário</p>
            {userSelected ? ( 
              <p>{formData.destinatario}</p>
             ) : ( 
                <input
                  name="destinatario"
                  onChange={handleField}
                  id="destinatario"
                  value={formData.destinatario}
                  placeholder='destinatário'
                  className="px-4 py-2 rounded border border-gray-300 focus:outline-none"
                />
             )}

            </div>
        </div>
        <div className='w-full flex flex-row-reverse gap-2'>
        <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handlePost}>Enviar</button>
        <button className='bg-gray-700 hover:bg-gray-800 admin-button' onClick={handleTest}>Testar</button>
        {msg && (
            <div className='w-full flex flex-row-reverse p-2 justify-center align-middle items-center'>
                <p className='m-0 p-0 text-gray-800'>{msg}</p>
            </div>
        )}
        </div>

  </div>
  )
}

export default EmailsAvulso