import React , { useState, useEffect } from "react";
import { Box,
   Typography,
   TextField,
  Button,
  Checkbox } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GenPut, GenGet } from "Loja/state/api";
  const userId = localStorage.getItem('id');

function AtualizarCategoria(props) {
    const id = props.id;
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
        nome:"",
        slug:"",
        imagem:"",
        descricao:"",
        valor:"",
        peso:"",
        categoria_id:"",
        
  });

  useEffect(() => {
    if(id) { async function getEnd() {
        try {
          const response = await GenGet(`api/categoria/${id}`);
          setFormData({
              ...formData,
              nome:response.nome,
              descricao:response.descricao,
            });
          setIsLoading(false);
        } catch (err) {

        }
      }
      getEnd();}
    
  }, [id]);

  const setNome = (e) => {
    setFormData({
      ...formData,
      nome: e.target.value,
    });
  };

  const setDesc = (e) => {
    setFormData({
      ...formData,
      descricao: e.target.value,
    });
  };

  const handleSubmit = (e) => {
        GenPut(`api/categoria/${id}`, formData)
      .catch((error) => {

      });
  };

  if(!isLoading){  

    return (
      <Box sx={{ p: 1, m: 1, width: {md:"30vw"}}}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        p="0.25rem 1.25rem"
        borderRadius="0.55rem"
        sx={{ p: 1, m: 2, width:'100%'}}
      >
        <Box sx={{p:0}}>
          <Typography variant="h5">
              Dados de Categoría:     
          </Typography><hr/>
  
          <Box sx={{p:0,marginTop:2,width:'100%'}}>
              <TextField  fullWidth 
                  variant="outlined"
                  size="small"
                  defaultValue={formData.nome}
                  id="nome"
                  label="Nome :"
                  name="nome" 
                  onChange={setNome} />       
            </Box>
            <Box sx={{p:0,marginTop:2,width:'100%'}}>
              <TextField  fullWidth 
                  variant="outlined"
                  size="small"
                  defaultValue={formData.descricao}
                  id="descricao" 
                  label="Descrição :"
                  name="descricao" 
                  onChange={setDesc} />       
            </Box>
            
            <Box sx={{p:0,m:1, marginTop:2, width:'100%'}} display='flex' flexDirection={'row-reverse'}>
            <Button variant="contained" onClick={handleSubmit} sx={{p:1,m:1}}>Alterar</Button>      
            </Box>
            
          </Box>
      </Box>      
    </Box>

  
    );
  } else{
    <> Carregando ...</>
  }
  
};
export default AtualizarCategoria;