import React , { useEffect, useState } from "react";
import axios from "Admin/state/axios";
import { Box,
    Typography,
    CircularProgress,
    Alert,
    TextField, 
    FormControl } from "@mui/material";

const NovaOferta = (props) => {
    const [msg, setMsg] = useState(null);
    const [ofertas, setOfertas] = useState(null);
    const[isLoading, setIsLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const produtos = props.produtos;
    const GLOBAL_URL = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem('token');
    const handleMsg = (e) =>{
        setMsg(e)
        setTimeout(() => {
            setMsg(null);
        }, 5000);
    }

    const [formData, setFormData] = useState({
        produto_id: 1,
        descricao: "",
        valor_orig: null,
      });

      const [validationErrors, setValidationErrors] = useState({
        produto_id: null,
        descricao: null,
        valor_orig:null,
      });

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setValidationErrors({
          ...validationErrors,
          [e.target.name]: e.target.value ? "" : "Campo obrigatório.",
        });
      };

    const handleId = (e) => {
        setFormData((prevData) => ({
          ...prevData,
          produto_id: e.target.value,
      }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = {};
        for (let key in formData) {
          if (!formData[key]) {
            errors[key] = "Campo obrigatório.";
          }
        }
        // Se houver erros, atualiza o estado com os erros de validação
        if (Object.keys(errors).length > 0) {
          setValidationErrors(errors);
          return;
        }
        axios.post("oferta", formData)
          .then((response) => {
            handleMsg(response.data.message);
            setRefresh(true);
          })
          .catch((error) => {
            handleMsg(error.data.message);
          });
      };

    useEffect(() => {
        async function getOfertas() {
          try {
            const response = await axios({
              url: "ofertas",
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
              },
              withCredentials: true,
            });

            setOfertas(response.data);
            setIsLoading(false);
            setRefresh(false)
          } catch (err) {

          }
        }
        getOfertas();
      }, [refresh]);
      async function remover (id) {
        const response = await axios({
          url: `rmv-oferta/${id}`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });
        handleMsg(response.data.message);
        setRefresh(true);
        
      }
  return (
    <div className='p-2 text-[10pt]'>
        <h3 className='text-[12pt]'>
        Ofertas     
        </h3><hr/>
        {!isLoading ? (<>
          <Box> 
            <h3 className='text-[12pt]' variant={'h6'}>Produtos em Ofertas:  </h3>
            <div className="flex flex-col gap-1">
            {ofertas && ofertas.length > 0 ? ofertas.map((item) => (
              <div className="flex justify-between">
              <div key={item.id} className={'flex justify-center items-center gap-2'}>
                <img className={'max-w-[80px] rounded-md shadow-md '}src={`${GLOBAL_URL}/${item.produto.imagens[0].imagem}`}/>
                <p className='font-semibold p-1'>{item.produto.nome}</p>
              </div>
              <button onClick={()=>remover(item.id)} className={` bg-red-400 hover:bg-red-500 admin-button`}> Remover Item</button>
              </div>        
            )) : <p>Nenhum produto cadastrado em oferta</p>}</div>
          </Box>
        </>): (<Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}><CircularProgress color="success"/></Box>)}
        <br/>
        <h3 className='text-[12pt]' variant={'h6'}>
        Nova Oferta     
        </h3>
        <Box display='flex' flexDirection="row" gap={2} sx={{p:0,m:1 }}>   
              <TextField  fullWidth 
                variant="standard"
                label='Valor :'
                id="valor_orig" 
                name="valor_orig" 
                value={formData.valor}
                onChange={handleChange}
                error={!!validationErrors.valor_orig}
                helperText={validationErrors.valor_orig}
                sx={{maxWidth:'200px'}}  
                />
              <FormControl variant="standard">
              <Typography>Produto:</Typography>
              <select className="form-select" aria-label="tipo" 
              label="produto"
              id="produto"
              name="produto"
              value={formData.produto}
              onChange={handleId}
              >
              {produtos ? (
                  produtos.map(({ id, nome }) => (
                    <option value={id} key={id}>{nome}</option>
                  ))
              ) : null}
              </select>
              </FormControl>
        </Box>
        <Box display='flex' flexDirection="column" sx={{p:0,m:1}} >
        <TextField  fullWidth
                multiline 
                variant="outlined"
                id="nome"
                label={'Descrição : '} 
                name="descricao" 
                rows={3}
                value = {formData.descricao}
                onChange={handleChange}  
                error={!!validationErrors.descricao}
                helperText={validationErrors.descricao}
                />
            
            </Box>

            <Box sx={{marginTop:5}} display={'flex'} flexDirection={'row-reverse'}>
                <button onClick={handleSubmit} className={'bg-blue-400 hover:bg-blue-500 admin-button'}>Cadastrar</button>
            </Box>
            {msg ? 
            <Box display={'flex'} flexDirection={'row-reverse'} padding={1} >
                <Alert severity="info" sx={{width:320}} > {msg} </Alert>
            </Box>
            : null}

    </div>
  )
}

export default NovaOferta