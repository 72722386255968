import React, {useState} from 'react'
import { Typography, Box, Button,Accordion,AccordionDetails,AccordionSummary, Grid, IconButton, Alert  } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Form from 'react-bootstrap/Form';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {makeStyles} from '@material-ui/core/styles';
import Img from '../../assets/vitor_escuro.png';
import { GenPost } from 'Loja/state/api';

const useStyles = makeStyles({
    botaoFooter: {
      "&:hover": {
        bgcolor:"#08BEFBD",
        color: "white",
      }
    }
  });

function Footer() {
    const classes = useStyles();
    const navigate = useNavigate();
    const [leadmsg, setLeadMsg] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [email, setEmail] = useState('');
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    const handleRedirect = (url) => {
        var link = url;
        window.location.href = link;
      };


    const HandleLead = async (email) => {
        try {
            var res = await GenPost('api/leads', { email : email});
            const { message } = res;
            setLeadMsg(message);
            setTimeout(() => {
             setLeadMsg(null);
           }, 5000);
          } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                const errorMessage = error.response.data.message;
                setLeadMsg(errorMessage);
              } else {
                setLeadMsg('Erro ao cadastrar Email.');
              }
              setTimeout(() => {
                setLeadMsg(null);
              }, 5000);

          }
      };

  return (
    <Box        
        display="flex"
        flexDirection="column"
        boxSizing="border-box" 
        padding="40px"
        bgcolor="#474747"
        color='white'>
        <Box            
            display="flex"
            flexDirection={{md:"row", xs:"column"}} 
            justifyContent={{md:"space-between", xs:"center"}}
            boxSizing="border-box">
                <Box                    
                    display="flex"
                    flexDirection="row"
                    justifyContent={{md:"left", xs:"center"}}
                    padding='5px'
                    marginLeft='20px'>
                        <Box
                            display='flex'
                            flexDirection='column'>
                            <Typography variant="h5">Receba Atualizações ! </Typography>
                            <Typography sx={{display:{md:"block", xs:"none"}}}>Nunca perca as novidades de nossa loja!</Typography>
                        </Box>
                </Box>     
                <Box                    

                    display="flex"
                    flexDirection="row"
                    padding='5px'
                    gap="10px"
                    marginLeft='30px'
                    justifyContent={{md:"left", xs:"center"}}>
                        <Box>
                        <Box                     
                            display="flex"
                            flexDirection={{md:"row", xs:"column"}}
                            gap={1}
                            margin={1}
                            justifyContent={{md:"left", xs:"center"}}>
                        <Form>
                            <Form.Label>Seu Email:</Form.Label>
                            <Form.Control type="email" placeholder="@Email" style={{minWidth:'30vw'}} onChange={(e) => setEmail(e.target.value)}/>
                        </Form>
                        <Button href="#outlined-buttons" 
                        sx={{ 
                            height:'40px', 
                            top:'30px', 
                            minWidth:'130px',  
                            "&:hover": {
                                bgcolor:"#eea10b",
                                color: "black"},
                            bgcolor:'#eec50b', 
                            color:"black"}} onClick={()=>{HandleLead(email)}}>
                            SE INSCREVA
                        </Button>
                        </Box>
                        {leadmsg ? 
                            <Box display={'flex'} flexDirection={'row-reverse'} padding={1}>
                            <Alert severity="success" sx={{width:320}} > {leadmsg} </Alert>
                            </Box>
                        : null}

                        
                        </Box>
                </Box>
        </Box>
        <br/>
        <hr/>
        <Grid container     
            display={{md:"flex", xs:'none'}}
            flexDirection="row" 
            boxSizing="border-box" >
                <Grid item xs={6} 
                    display="flex"
                    flexDirection="column"
                    justifyContent="left"
                    marginLeft='20px'
                    padding='5px'>
                        <Box>
                            <Typography variant='h4'>Vitor Multicoisas</Typography>
                            <br/>
                        </Box>
                        <Box>
                            Conte com nossa equipe para dar o melhor atendimento!
                            Ficamos muito felizes em receber você!
                            
                        </Box>
                        <br/>
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="left"
                            padding={1}
                            gap="25px"
                            >
                                    <IconButton aria-label="Instagram" onClick={() => handleRedirect('https://www.instagram.com/vitorencanamentosemulticoisas?igsh=MWpmbHMxdmN5ZHc5')}>
                                        <InstagramIcon sx={{color:'white'}}/>
                                    </IconButton>
                                    <IconButton aria-label="Facebook" onClick={() =>handleRedirect('https://www.facebook.com/vitormulticoisasvitor?mibextid=ZbWKwL')}>
                                        <FacebookIcon sx={{color:'white'}}/>
                                    </IconButton>
                                    <IconButton aria-label="WhatsApp" onClick={() => handleRedirect('https://api.whatsapp.com/send?phone=5544997586958')}>
                                        <WhatsAppIcon sx={{color:'white'}}/>
                                    </IconButton>
                        </Box>

                </Grid>
                <Grid item xs={5} 
                    display="flex"
                    flexDirection="column" 
                    padding='20px'>
                    <Typography variant='h6'>CONTATOS</Typography>
                    <br/>
                    <Typography padding={1}>Email : atendimento@vitormulticoisas.com.br</Typography>
                    <Typography padding={1}>Tel/WhatsApp : (44) 99758-6958</Typography>
                    <p className='pl-2 underline cursor-pointer' onClick={()=>navigate('/politica-devolucao')}>Politica de devoluções</p>
                </Grid>
            </Grid>
            <Box       
                display={{md:"none", xs:'block'}}
                padding="5px"
                bgcolor="#474747"
                border='0'>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{backgroundColor:"#eec50b" , color:'black'}}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header">
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    SOBRE
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Box 
                    width='100%'
                    display="flex"
                    flexDirection="column"
                    justifyContent="left"
                    marginLeft='20px'
                    padding='5px'>
                    <Box>
                    <img src={Img} width={120} height={60}/>
                    </Box>
                    <Box 
                            display="flex"
                            flexDirection="column"
                            justifyContent="left"
                            marginLeft='20px'
                            padding='5px'>
                                <Box>
                                    Conte com nossa equipe para dar o melhor atendimento!
                                    ficamos muito felizes em receber você!
                                    
                                </Box>

                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={{backgroundColor:"#eec50b" , color:'black'}}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
                >
                <Typography sx={{ width: '100%', flexShrink: 0 }}>CONTATOS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Box 
                    width='100%'
                    display="flex"
                    flexDirection="column" 
                    padding='10px'>
                    <Typography padding={1}>Email:<br/>atendimento@vitormulticoisas.com.br</Typography>
                    <Typography padding={1}>WhatsApp:</Typography>
                    <Typography ml={1}>(44) 99758-6958</Typography>
                    <p className='pl-2 mt-2 text-gray-800 underline cursor-pointer' onClick={()=>navigate('/politica-devolucao')}>Politica de devoluções</p>
                </Box>
                <br/>
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="left"
                                    padding={1}
                                    gap="25px"
                                    >
                                    <IconButton aria-label="Instagram" onClick={() => handleRedirect('https://www.instagram.com/vitorencanamentosemulticoisas?igsh=MWpmbHMxdmN5ZHc5')}>
                                        <InstagramIcon/>
                                    </IconButton>
                                    <IconButton aria-label="Facebook" onClick={() =>handleRedirect('https://www.facebook.com/vitormulticoisasvitor?mibextid=ZbWKwL')}>
                                        <FacebookIcon/>
                                    </IconButton>
                                    <IconButton aria-label="WhatsApp" onClick={() => handleRedirect('https://api.whatsapp.com/send?phone=5544997586958')}>
                                        <WhatsAppIcon/>
                                    </IconButton>
                                </Box>
                                
                </AccordionDetails>
            </Accordion>


    </Box>
    </Box>
  )
}

export default Footer