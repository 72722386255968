import React, { useEffect, useState } from 'react';
import { GenGet, GenPost } from 'Admin/state/api';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import GenericDataGrid from 'Admin/components/DataGrids/GenericDataGrid';
import DisplayDate from 'Admin/components/DisplayDate'; 
import { useNavigate } from 'react-router-dom';

function CampanhaEmail() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [rows,setRows] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [msg, setMsg] = useState(null);
  const { id } = useParams();
  const nav = useNavigate();
  
  const filterGrid = (itens) => {
    return itens.map(item => {
        if (item.email.user_id) {
            return {
                id: item.email.id,
                nome: item.email.user.name,
                email: item.email.destinatario,
                status: item.email.status,
            };

        } else {
            return {
                id: item.email.id,
                nome: 'avulso',
                email: item.email.destinatario,
                status: item.email.status,                
            };
        }
    });
  };
  const handleDelete = () => {
    async function postData() {
      try {
        const response = await GenPost(`/campanha/delete/${id}`);
        setMsg(response.message);
        setTimeout(() => {
            setMsg(null);
            window.location.href = '/admin/campanhas/email';
        }, 3000);

      } catch (err) {
        setMsg(err.message);
        setTimeout(() => {
            setMsg(null);   
        }, 3000);
      }
    }
    postData();
  };

  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await GenGet(`/campanha/show/${id}`);
            setData(response);
            setRows(filterGrid(response.itens));
            setLoading(false)

        } catch (err) {
            setNotFound(true)
            setLoading(false)
        }
    };
    fetchData();
  }, [id]);

  const cols = ['ID', 'Usuário', 'Destinatário', 'Status'];

  return (
    <div className='flex flex-col w-full p-2'>
    <h3 className='text-[14pt]'>Sua campanha</h3>
    <p className='text-[9pt] -mt-2'>detalhes da campanha.</p>
    <hr/>
    {loading ? (
          <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
            <CircularProgress color="success"/>
          </div>
    ) : (
    <>
    {notFound ? (
        <p> Campanha não encontrada.</p>
      ) : (
      <div className='relative text-[10pt]'>
        <p className='font-semibold text-[12pt] my-0'>{data.nome}</p>
        <p className='font-semibold text-[8pt] my-0'><b>status:</b> {data.status === 0 ? 'agendada' : 'enviada' }</p>
        <p className='text-[8pt]'><b>Início: </b><DisplayDate datetime={data.inicio} /> </p>
        <p className='text-[10pt] m-0 p-0'><b>objetivo:</b><br/> {data.objetivo}</p> 
        <br/>
        <GenericDataGrid rows={rows} columns={cols} per_page={25} title="Emails"/>
        {msg && (
          <div className='w-full flex flex-row-reverse p-2 justify-center align-middle items-center'>
            <p className='m-0 p-0 text-gray-800'>{msg}</p>
          </div>
        )}
        {data.status === 0 && (<button className='absolute right-5 top-2 bg-red-400 hover:bg-red-500 admin-button' onClick={handleDelete}>Cancelar campanha</button>)}
        <div className='w-full flex flex-row-reverse gap-2'>
          <button className=' bg-gray-400 hover:bg-gray-500 admin-button' onClick={()=>nav('/admin/campanhas/email')}>Voltar</button>
        </div>
      </div>
      )}
    </>)}
  </div>
  )
}

export default CampanhaEmail

//