import React , { useState} from "react";
import { useParams } from 'react-router-dom';
import { GenPost } from 'Loja/state/api';
import { useNavigate } from 'react-router-dom';
import { 
    Box,
    Typography,
    TextField,
    Button,
    CircularProgress,
    Alert
  } from "@mui/material";


const NovaSenha = () => {
  const { token } = useParams();
  const [message, setMessage] = useState(null);
  const [isLoading, setisLoading ] = useState(false);
  const [Err, setErr] = useState(null);
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    password: "",
    confirm_password:""
  });

  const setPassword = (e) => {
    setFormData({
      ...formData,
      password: e.target.value,
    });
  };

  const setConfirm = (e) => {
    setFormData({
      ...formData,
      confirm_password: e.target.value,
    });
  };
  
  const validate = (confirmPass, formData) =>{
    if (confirmPass.confirm_password === formData.password){
      return true
    }
    else
    {
        return false}
  };

  async function handleSubmit() {
    if (validate(formData.confirm_password, formData.password)) {
        setisLoading(true);
            
        try {
          const response = await GenPost(`api/nova-senha/${token}`, formData);
            setMessage(response.message);
            setisLoading(false);
            setTimeout(() => {
              navigate('/login'); 
            }, 5000); 
        } catch (err) {
            setMessage('Ops, Algo deu errado!');
            setisLoading(false);
        }

    } else {
        setErr("senhas não conferem");
    }
  }

  return(
  <>
    <Box mx="auto" width="50%">
        <Box
          mt="10px"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          p="0.25rem 1.25rem"
          borderRadius="0.55rem"
        >
        {Err ? (<Alert severity="error">{Err}</Alert> ) : null}      
        <Box sx={{p:0,m:1,width:'100%'}}>
        <Typography variant="h5">Nova Senha</Typography><hr/>
          <Typography variant="h6">
            Senha: 
          </Typography>
          <TextField  
            fullWidth 
            variant="outlined"
            sx={{ m: 2, }} 
            id="password" 
            name="password" 
            onChange={setPassword} 
          />
          </Box>

          <Box  sx={{p:0,m:1,width:'100%'}}>
          <Typography variant="h6">
            Confimar Senha 
          </Typography>
          <TextField  
            fullWidth 
            variant="outlined"
            sx={{ m: 2, }} 
            id="confirm_password" 
            name="confirm_password" 
            onChange={setConfirm} 
          />
          </Box>

          <Box  sx={{p:0,m:1,width:'50%'}} display="flex" flexDirection={'row'} justifyContent="space-between">         
            <Button variant="contained" onClick={handleSubmit}>Trocar Senha</Button>
          </Box>
          </Box>
          {message ? 
              <Box display={'flex'} flexDirection={'row-reverse'} padding={1} >
                <Alert severity="success" sx={{width:320}} > {message} </Alert>
              </Box>
          : message}
          {isLoading ? (
              <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}>
                  <CircularProgress color="success"/>
              </Box>
            ) : (null) }
          <Box
            display="flex"
            flexDirection="row-reverse"
            justifyContent="space-between"
            p="0.25rem 1.25rem"
            flex="1 1 100%"
            borderRadius="0.55rem"
            sx={{
              pt:2,
              mb:4,
              width: '100%',
            }}
          >  
          </Box> 
        </Box>

  </>)

};
export default NovaSenha;