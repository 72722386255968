import React, { useState, useEffect } from 'react';
import { GenGet } from 'Loja/state/api';
import { Box, CircularProgress } from '@mui/material';
import Carousel from 'react-bootstrap/Carousel';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, ThemeProvider, createTheme } from '@mui/material';

const GLOBAL_URL = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles({
  carrocelContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '300px',
    overflow: 'hidden',
    position: 'relative',
  },
  carrocel: {
    height: '100%',
    width: '100%',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  }
});

function Carrocel() {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  async function getCarrocel() {
    try {
      const response = await GenGet('api/carrocel/index');
      console.log(response);
      setImages(response);
      setIsLoading(false);
    } catch (err) {
      // Handle error
    }
  }

  useEffect(() => {
    getCarrocel();
  }, []);

  const classes = useStyles();

  return (
    <>
      {isLoading ? (
        <div className='p-1 w-full h-[30vh] flex justify-center items-center'>
          <CircularProgress color="success" />
        </div>
      ) : (
        <Box className={classes.carrocelContainer}>
          <Carousel variant="light" className={classes.carrocel}>
            {images && images.map((item, index) => (
              <Carousel.Item key={index} className={classes.carrocel}>
                <img className={classes.img} src={`${GLOBAL_URL}/${item.path}`} alt={`Slide ${index + 1}`} />
              </Carousel.Item>
            ))}
          </Carousel>
        </Box>
      )}
    </>
  );
}

export default Carrocel;