import React , { useState, useEffect, useContext } from "react";
import { Box, CircularProgress } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import ArticleIcon from '@mui/icons-material/Article';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { CartContext } from "Ecommerce";
import NovoUsuario from "./Index";
import NovoCliente from "../novoCliente/Index";


import { GenGetTemp } from "Loja/state/api";
import PrimeiroEnd from "../novoEnd/PrimeiroEnd";

const MultiFormBase = ( props ) => {
  let Form;
  if (props.componente === 1){
    Form = NovoCliente;
  }else if (props.componente === 2){
    Form = PrimeiroEnd;
  } else { 
    Form = NovoUsuario;
  }
  
  return (
  <Box width={'100%'} display={'flex'} justifyContent={'center'}>
    <Form onSuccess={props.onSuccess} token={props.token}/>
  </Box>
  );
};

const MultiStep = () => {
const [personCheck, setpersonCheck] = useState('Gray');
const [articleCheck, setarticleCheck] = useState('Silver');
const [shippingCheck, setshippingCheck] = useState('Silver');
const [etapa, setEtapa] = useState(0);
const [stepper, setStepper] = useState(true);
const [tempToken, setTempToken] = useState('');
const [loadStep, setLoadStep] = useState(true);
const { track, appUrl } = useContext(CartContext);

const handlePostSuccess = (result) => {
  if (etapa === 0){
    localStorage.setItem('token' , result.token);
    setTempToken(result.token);
    window.location.href = appUrl; 
  }
 
  setEtapa(etapa + 1);
};


useEffect(() => {
  async function handleStep() {
    try {
      const token = localStorage.getItem('token');
      if (token){
        let res = await GenGetTemp("/api/step", token);

        if (res === 1){
          setEtapa(1);
          setLoadStep(false);
        } else if (res === 2) {
          setEtapa(2);
          setLoadStep(false);
        }
        else if (res === 3) {
          setEtapa(3);
          window.location.href = track;
        }
      } else { setEtapa(0); setLoadStep(false);} } 
    catch (err) {

    }
  }

  if ( stepper ){
    handleStep();
    setStepper(false);
  }

}, [stepper]);




useEffect(() => {
  const handleState = () => {
    if ( etapa === 1 ){
      setpersonCheck('Grey');
      setarticleCheck('Grey');
      setshippingCheck('Silver');
  
    } else if ( etapa === 2){
      setpersonCheck('Grey');
      setarticleCheck('Gray');
      setshippingCheck('Gray');
    }
    else {
      setpersonCheck('Grey');
      setarticleCheck('Silver');
      setshippingCheck('Silver');
    }
  }

  handleState();
  
}, [etapa]);


  return (
    
    <Box sx={{ p: 1, m: 1, width: {md:"100%"}, minHeight:'64vh'}} display="flex"
    justifyContent="center" flexDirection={'column'}>
      {!loadStep ? (
        <>
              <Box width={'100%'} display={'flex'} justifyContent={'center'}>
              <Box width={350} display={'flex'} justifyContent={'space-around'} gap={4}>  
                <PersonIcon fontSize="large" sx={{color:`${personCheck}`}}/>
                <DoubleArrowIcon fontSize="large" sx={{color:'DarkGray'}}/>
                <ArticleIcon fontSize="large" sx={{color:`${articleCheck}`}}/>
                <DoubleArrowIcon fontSize="large" sx={{color:'DarkGray'}}/>
                <LocalShippingIcon fontSize="large" sx={{color:`${shippingCheck}`}}/>
              </Box>
              </Box>
              <Box marginTop={1}>
              <MultiFormBase componente={etapa} onSuccess={handlePostSuccess} token={tempToken}/>
              </Box>
        </>
      ) : ( <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}><CircularProgress color="success"/></Box>) }

      
    </Box>
  );
};
export default MultiStep;