import React , { useState } from "react";
import { GenPost } from 'Loja/state/api';
import { 
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress } from "@mui/material";


const EsqueceuSenha = () => {
  const [message, setMessage] = useState('');
  const [isLoading, setisLoading ] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });

  const setEmail = (e) => {
    setFormData({
      ...formData,
      email: e.target.value,
    });
  };
  async function handleSubmit() { 
    try {
      setisLoading(true);
      const response = await GenPost(`api/recuperar-senha`, formData);
        setMessage(response.message);
        setisLoading(false);
    } catch (err) {
        setMessage('Ops, Algo deu errado! Verifique o email que foi submetido.');
        setisLoading(false);
    }

  }

  return(
    <>
      <Box m="1.5rem auto" width="50%">
          <Box
            height="75vh"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            p="0.25rem 1.25rem"
            borderRadius="0.55rem"
            mx="auto"
          >
            <Box sx={{ p: 0, m: 1, width: "100%" }}>
            <Typography variant="h5">Recuperar Senha</Typography><hr/>
              <Typography variant="h6">Email:</Typography>
              <TextField
                fullWidth
                variant="outlined"
                sx={{ m: 1 }}
                id="email"
                name="email"
                onChange={setEmail}
              />
              <Box display={'flex'} flexDirection={'row-reverse'} padding={1}>
                <Button variant="contained" onClick={handleSubmit}>
                  Recuperar
                </Button>
              </Box>

              {message ? 
              <Box display={'flex'} flexDirection={'row-reverse'} padding={1} >
                <Alert severity="success" sx={{width:320}} > {message} </Alert>
              </Box>
              : message}

            {isLoading ? (
              <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}>
                  <CircularProgress color="success"/>
              </Box>
            ) : (null) }
            </Box>
          </Box>
        </Box>
    
     </>
  )

};
export default EsqueceuSenha;