import React, { useState, useContext, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import LoginForm from './LoginForm';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useLogout from 'hooks/useLogout';
import {
  Box,
  IconButton,
  Badge,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Popover // Import Popover
} from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import axios from "Loja/state/axios";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { CartContext } from "Ecommerce";
import CartMenu from './CartMenu';


import Img from '../../assets/vitor_escuro.png';
const token = localStorage.getItem('token');


const useStyles = makeStyles({
  icon: {
    "&:hover": {
      color: "#feb236",
      fontSize: '100px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
});

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));


function NavBar() {
  const isSmScreen = useMediaQuery('(max-width:780px)');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const token = localStorage.getItem('token');
  const { cart } = useContext(CartContext);
  const logout = useLogout();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorCartEl, setAnchorCartEl] = useState(null);
  const [openLogin, setOpenLogin] = useState(false);
  
  // References for detecting clicks outside
  const menuRef = useRef(null);
  const cartRef = useRef(null);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickCart = (event) => {
    setAnchorCartEl(event.currentTarget);
  };

  const handleCloseCartMenu = () => {
    setAnchorCartEl(null);
  };

  const handleClickLogin = () => {
    setOpenLogin(true);
  };

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  const handleCloseNav = (link) => {
    setOpenLogin(false);
    navigate(link);
  };

  const handleLink = (link) => {
    setAnchorEl(null);
    navigate(link);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && anchorEl && !anchorEl.contains(event.target)) {
        handleCloseMenu();
      }
      if (cartRef.current && !cartRef.current.contains(event.target) && anchorCartEl && !anchorCartEl.contains(event.target)) {
        handleCloseCartMenu();
      }
    };
  
    document.addEventListener('click', handleClickOutside); // Change to 'click'
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [anchorEl, anchorCartEl]);

  return (
    <Box className='sticky-top' sx={{ width: "100%", height: "50px", boxSizing: "border-box" }}>
      <Dialog open={openLogin} onClose={handleCloseLogin}>
        <DialogContent>
          <LoginForm onClose={handleCloseNav} />
        </DialogContent>
      </Dialog>

      <Navbar style={{backgroundColor:'grey'}} expand="md">
        <Drawer anchor="left" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
          <Box sx={{ width: 250 }}>
            <List>
              <ListItem style={{ cursor: 'pointer' }} onClick={() => { navigate('/'); setIsDrawerOpen(false) }}>
                <ListItemText primary="INICIO" />
              </ListItem>
              <ListItem style={{ cursor: 'pointer' }} onClick={() => { navigate('/produtos'); setIsDrawerOpen(false) }}>
                <ListItemText primary="PRODUTOS" />
              </ListItem>
              <ListItem style={{ cursor: 'pointer' }} onClick={() => { navigate('/destaques'); setIsDrawerOpen(false) }}>
                <ListItemText primary="DESTAQUES" />
              </ListItem>
            </List>
          </Box>
        </Drawer>
        <div className='w-full p-1 px-4 flex-row justify-between'>
          <div className='flex flex-row justify-between'>
            <div className='flex flex-row'>
            {isSmScreen && (
              <IconButton onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
                {isDrawerOpen ? <CloseIcon /> : <MenuIcon style={{color:'white'}} />}
              </IconButton>
            )}
            <img src={Img} width={120} onClick={() => { navigate('/') }} className='cursor-pointer' />
            </div>

            <div className='flex flex-grow flex-row justify-start ml-4 mt-2 -mb-4'>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav style={{ width: "100%" }}>
                  <Nav.Link onClick={() => { navigate('/') }}><p className='text-[12pt] text-slate-100 hover:text-slate-200 font-medium hover:underline'>INICIO</p></Nav.Link>
                  <Nav.Link onClick={() => { navigate('/produtos') }}><p className='text-[12pt] text-slate-100 hover:text-slate-200 font-medium hover:underline'>PRODUTOS</p></Nav.Link>
                  <Nav.Link onClick={() => { navigate('/destaques') }}><p className='text-[12pt] text-slate-100 hover:text-slate-200 font-medium hover:underline'>DESTAQUES</p></Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </div>
            <div className='flex flex-row gap-1'>
              <div className='text-slate-300 relative hover:text-yellow-400 rounded-md flex flex-row gap-1 align-middle h-[40px] p-2 cursor-pointer' id="cart-button" onClick={handleClickCart} ref={cartRef}>
                <ShoppingCartIcon />
                {!isSmScreen && (<p className='text-[10pt] font-semibold '>Carrinho</p>)}
                
                {cart && cart.length > 0 && (
                  <span className='absolute -top-2 -right-3 bg-yellow-300 text-slate-900 font-semibold text-[10pt] p-1 rounded-full flex justify-center align-middle w-[24px] h-[24px]'>
                    {cart.length}
                  </span>
                )}
              </div>

              {token ?
                <div className='text-slate-300 hover:text-yellow-400 rounded-md flex flex-row gap-1 align-middle h-[40px] p-2 cursor-pointer' id="basic-button" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClickMenu} ref={menuRef}>
                  <PersonIcon />
                  {!isSmScreen && (<p className='text-[10pt] font-semibold '>Conta</p>)}
                  <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleCloseMenu} MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
                    <MenuItem onClick={() => { handleLink('/meupainel') }}><Typography>Meu Painel</Typography></MenuItem>
                    <MenuItem onClick={() => { logout() }}>Sair</MenuItem>
                  </Menu>
                </div> :
                <div className='text-slate-300 hover:text-yellow-400border border-1 rounded-md flex flex-row gap-1 align-middle h-[40px] p-2 cursor-pointer' id="basic-button" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClickLogin}>
                  <PersonIcon />
                  <p className='text-[10pt] font-semibold '> Entrar </p>
                </div>}
            </div>
          </div>
        </div>
      </Navbar>
      <CartMenu anchorCartEl={anchorCartEl} handleCloseCartMenu={handleCloseCartMenu} cart={cart} cartRef={cartRef}/>
    </Box>
  );
}

export default NavBar;