import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GenPut, GenGet } from "Loja/state/api";
import InputMask from "react-input-mask";

function AtualizarCliente(props) {
  const id = props.id;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [scssMsg, setScssMsg] = useState(false);
  const [formData, setFormData] = useState({
    nome: "",
    slug: "",
    imagem: "",
    descricao: "",
    valor: "",
    peso: "",
    cpf: "",
    categoria_id: "",
  });

  useEffect(() => {
    if (id) {
      async function getEnd() {
        try {
          const response = await GenGet(`api/cliente/${id}`);
          setFormData({
            ...formData,
            nome: response.cliente.nome,
            sobrenome: response.cliente.sobrenome,
            telefone: response.cliente.telefone,
            status: response.cliente.status,
            user_id: response.cliente.user_id,
            cpf: response.cliente.cpf,
          });
          setIsLoading(false);
        } catch (err) {}
      }
      getEnd();
    }
  }, [id]);

  const setNome = (e) => {
    setFormData({
      ...formData,
      nome: e.target.value,
    });
  };
  const setSobrenome = (e) => {
    setFormData({
      ...formData,
      sobrenome: e.target.value,
    });
  };
  const setTel = (e) => {
    setFormData({
      ...formData,
      telefone: e.target.value,
    });
  };
  const setStat = (e) => {
    setFormData({
      ...formData,
      status: e.target.value,
    });
  };
  const setUserId = (e) => {
    setFormData({
      ...formData,
      user_id: e.target.value,
    });
  };
  const setCpf = (e) => {
    setFormData({
      ...formData,
      cpf: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    GenPut(`api/cliente/${id}`, formData)
      .then((response) => {
        setScssMsg(response.message);
        setTimeout(() => {
          setScssMsg(null);
        }, 3000);
      })

      .catch((error) => {
        setScssMsg("Erro ao atualizar cliente");
        setTimeout(() => {
          setScssMsg(null);
        }, 3000);
      });
  };

  if (!isLoading) {
    return (
      <Box sx={{ p: 1, m: 1, width: "80vw" }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          p="0.25rem 1.25rem"
          borderRadius="0.55rem"
          sx={{ p: 1, m: 2, width: "100%" }}
        >
          {scssMsg ? (
            <Box display={"flex"} flexDirection={"row-reverse"} padding={1}>
              <Alert severity="info" sx={{ width: 320 }}>
                {" "}
                {scssMsg}{" "}
              </Alert>
            </Box>
          ) : null}
          <Box sx={{ p: 0 }}>
            <Typography variant="h5">Dados de Cliente:</Typography>
            <hr />

            <Box sx={{ p: 0, marginTop: 2, width: "100%" }}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                defaultValue={formData.nome}
                id="nome"
                label="Nome :"
                name="nome"
                onChange={setNome}
              />
            </Box>
            <Box sx={{ p: 0, marginTop: 2, width: "100%" }}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                defaultValue={formData.sobrenome}
                id="sobrenome"
                label="Sobrenome :"
                name="sobrenome"
                onChange={setSobrenome}
              />
            </Box>

            <Box sx={{ p: 0, marginTop: 2, width: "100%" }}>
              <InputMask
                mask={"(99) 9 9999-9999"}
                value={formData.telefone}
                onChange={setTel}
                disabled={false}
                maskChar=" "
              >
                {() => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    defaultValue={formData.telefone}
                    id="telefone"
                    label="Telefone :"
                    name="telefone"
                  />
                )}
              </InputMask>
            </Box>

            <Box sx={{ p: 0, marginTop: 2, width: "100%" }}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                defaultValue={formData.status}
                id="status"
                label="Status :"
                name="status"
                onChange={setStat}
              />
            </Box>
            <Box sx={{ p: 0, marginTop: 2, width: "100%" }}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                defaultValue={formData.user_id}
                id="user_id"
                label="Id Usuário :"
                name="user_id"
                onChange={setUserId}
              />
            </Box>

            <Box sx={{ p: 0, marginTop: 2, width: "100%" }}>
              <InputMask
                value={formData.cpf}
                mask="999.999.999-99"
                onChange={setCpf}
                disabled={false}
                maskChar=" "
              >
                {() => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    defaultValue={formData.cpf}
                    id="cpf"
                    label="Cpf :"
                    name="cpf"
                    onChange={setCpf}
                  />
                )}
              </InputMask>
            </Box>

            <Box
              sx={{ p: 0, m: 1, marginTop: 2, width: "100%" }}
              display="flex"
              flexDirection={"row-reverse"}
            >
              <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handleSubmit}>Atualizar</button>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  } else {
    <Box
      display={"flex"}
      justifyContent={"center"}
      sx={{ width: "100%", marginTop: "10vh", marginBottom: "10vh" }}
    >
      <CircularProgress color="success" />
    </Box>;
  }
}
export default AtualizarCliente;
