import React, {useState, useEffect, createContext} from 'react'
import { Route, Routes } from "react-router-dom";

import SalesPannel from 'Admin/scenes/newPannel/SalesPannel';
import AvisosVendedor from 'Admin/scenes/painel-vendas/Avisos';
import PedidosVendedor from 'Admin/scenes/painel-vendas/Pedidos';
import VendasVendedor from 'Admin/scenes/painel-vendas/Vendas';


function SellerRoot() {

  return (
        <div>
          <Routes>
            <Route element={<SalesPannel />}>
              <Route exact path="/" element={<PedidosVendedor />} />              
              <Route path="/pedidos" element={<PedidosVendedor/>} />
              <Route path="/avisos" element={<AvisosVendedor/>} />
              <Route path="/*" element={<VendasVendedor/>} />
            </Route>
          </Routes>
    </div>
  );
}

export default SellerRoot;