import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { GetVendas, GenGet } from "Admin/state/api";
import GenericDataGrid from "Admin/components/DataGrids/GenericDataGrid";

function Vendas() {
  const [data, setData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    async function getVendas() {
      try {
        const response = await GetVendas();
        setData(response);
      } catch (err) {
        console.error(err);
      }
    }

    async function getVendasGrid() {
      try {
        const dataGrid = await GenGet('/grid/pagamento');
        console.log(dataGrid)
        setGridData(dataGrid);
      } catch (err) {
        console.error(err);
      }
    }

    Promise.all([getVendas(), getVendasGrid()]).then(() => {
      setIsLoading(false);
    });
  }, [setData, setGridData]);

  if (isLoading) {
    return (
      <div className="flex justify-center mt-10 mb-10">
        <CircularProgress color="success" />
      </div>
    );
  }

  const cols = ['ID', 'Pedido', 'Status', 'Tipo', 'Valor' ];

  return (
    <div className="container p-2 text-[10pt]">
      <h3 className='text-[14pt]'>Resumo de Vendas</h3>
      <p className='text-[9pt] -mt-2'>resumo das suas vendas.</p>
      <hr/>
      <h3 className='input-classes text-[12pt]'>Resumo:</h3>
      <div className="flex flex-row flex-wrap gap-1">
        <div className="shadow flex-grow rounded border-[1px] bg-white p-2">
          <h3 className='text-[12pt]'>Hoje:</h3>
          <h3 className='text-[18pt]'>{data.vendas.hoje}</h3>
        </div>
        <div className="shadow flex-grow rounded border-[1px] bg-white p-2">
          <h3 className='text-[12pt]'>Este Mês:</h3>
          <p className='text-[18pt]'>{data.vendas.mes}</p>
        </div>
        <div className="shadow flex-grow rounded border-[1px] bg-white p-2">
          <h3 className='text-[12pt]'>Este Ano:</h3>
          <p className='text-[18pt]'>{data.vendas.ano}</p>
        </div>
        <div className="shadow flex-grow rounded border-[1px] bg-white p-2">
          <h3 className='text-[12pt]'>Total:</h3>
          <p className='text-[18pt]'>{data.vendas.todas.length}</p>
        </div>
      </div>
      <div className="shadow rounded bg-white p-4 col-span-2">
        <GenericDataGrid rows={gridData} columns={cols} per_page={25} title="Histórico de vendas"/>
        </div>
    </div>
  );
}

export default Vendas;
