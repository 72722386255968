import React, { useState } from 'react';
import { Box, Button, Dialog, DialogContent } from '@mui/material';
import NovoCliente from 'Loja/scenes/novoCliente/Index.jsx';
import NovoEnd from 'Loja/scenes/novoEnd/Index.jsx';
import NovoUsuario from 'Loja/scenes/novoUsuario/Index.jsx';
import AtualizarEnd from 'Loja/scenes/novoEnd/PutEnd';
import VerPedido from 'Admin/components/VerPedido';
import VerPedidoAberto from 'Admin/components/VerPedidoAberto';


function FormsModal(props) {
  const FORM_OPTIONS = {
    'Novo Cliente': NovoCliente,
    'Novo Endereço': NovoEnd,
    'Novo Usuário': NovoUsuario,
    'Ver Endereço': AtualizarEnd,
    'Ver Pedido Enviado' : VerPedido,
    'Ver Pedido Aberto': VerPedidoAberto,
  };
  const [options, setOptions] = useState(props.options);
  const [activeForm, setActiveForm] = useState(null);

  const handleCloseMenu = () => {
    setActiveForm(null);
  };

  const handleFormSelect = (formKey) => {
    setActiveForm(formKey);
  };

  return (
    <Box sx={{ display: 'inline-flex', width: '100%', boxSizing: 'border-box', color: 'white' }}>
      {Object.keys(options).map((formKey) => (
        <Button key={formKey} onClick={() => handleFormSelect(formKey)}>
          {formKey}
        </Button>
      ))}
      {activeForm && (
        <Dialog open={true} onClose={() => handleCloseMenu()}>
          <DialogContent>
          {props.id ? React.createElement(FORM_OPTIONS[activeForm],{ id: JSON.stringify(props.id) }  ) : React.createElement(FORM_OPTIONS[activeForm])}
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
}

export default FormsModal;