import React from "react";
import Img from '../../assets/vitor_escuro.png';

function TermoDevolucoes() {
  return (
    <div
      className={
        "flex flex-col  p-4 sm:py-10 sm:px-20 justify-center align-middle items-center"
      }
    >
      <div className="flex max-w-[600px] min-h-[80vh] flex-col border-1 border-yellow-600 rounded-md p-4 shadow-md text-[11pt]">
        <img src={Img} width={100} height={100} className="m-4 self-center" />
        <h3 className='text-[12pt] mt-2 font-extrabold text-gray-800'>Termos de devolução</h3>
        <p className="px-4">
          Nossa politica de devoluções segue conforme e cumprem com o Direito do consumidor.
        </p>
        <p className="px-4">
          O consumidor tem 90 dias corridos para solicitar a troca de produtos que por ventura, passou por nossa minunciosa supervisão despercebido e apresentem alguma avaría.
        </p>
        <p className="px-4">
          O consumidor que desistir da compra sem motivo evidente, tem o prazo de 7 dias corridos para solicitar a devolução.
        </p>
        <p className="px-4">
          O cliente deve entrar em contato com nosso serviço de atendimento ao cliente através de um dos nossos canais listados no site, dentro do prazo citado acima, para solicitar a troca ou devolução.
        </p>
      </div>
    </div>
  );
}

export default TermoDevolucoes;
