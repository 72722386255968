import React, { useEffect, useState } from 'react';
import { GenGet, GenPost } from 'Admin/state/api';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

function PublicosLeads() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const nav = useNavigate();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await GenGet(`/leads/publicos`);
                setData(response);
                setLoading(false);
                console.log(response)
    
            } catch (err) {
                console.log(err)
                setLoading(false);
            }
        };
      
        fetchData();
      }, []);

      const handleClose = () => {
        setOpen(false);
      };
      const handleOpen = () => {
        setOpen(true);
      };

  return (
    <div className='flex flex-col w-full p-2 relative'>
    <h3 className='text-[14pt]'>Seus Públicos</h3>
    <p className='text-[9pt] -mt-2'>seus públicos e listas de leads.</p>
    <button className='absolute right-5 top-2 bg-blue-400 hover:bg-blue-500 admin-button' onClick={()=>nav(`/admin/campanhas/publico/novo`)}>Novo Público</button>
    {!loading && data ? (
        <div className='flex flex-row gap-2 flex-wrap'>
            {data.map(item=>(
                <div key={item.id} className='w-[230px] flex flex-col justify-center p-4 border-gray-400 border-[1px] rounded-md shadow-md cursor-pointer hover:bg-gray-300' onClick={()=>nav(`/admin/campanhas/publico/${item.id}`)}>
                    <p className='font-semibold text-[12pt]'>{item.nome}</p>
                    <p className='text-[10pt] m-0 p-0'>{item.descricao}</p>
                    <p className='text-[10pt]' >total de cadastros <b>{item.itens_count}</b></p>
                </div>
            ))}
        </div>
    ) : (
        <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
            <CircularProgress color="success"/>
        </div>
    )}
    </div>
  )
}

export default PublicosLeads