import React , { useState, useEffect } from "react";
import axios from "Admin/state/axios";
import { useParams } from 'react-router-dom';
import Artigos from 'Admin/scenes/artigos/Index'
import AdminImageSlider from "Admin/components/AdminImageSlider";
import GenericModal from "Admin/components/GenericModal";
import { 
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  FormControl,
  Alert,
  Radio,
  FormControlLabel,
  RadioGroup,
   } from "@mui/material";
import { GenGet } from "Loja/state/api";
import { Refresh } from "@mui/icons-material";
const token = localStorage.getItem('token');

function AtualizarProd() {
  const id = useParams().id;
  const [cat, setCat] = useState([]);
  const [refresh, setRefresh]= useState(false);
  const [scssMsg , setScssMsg] = useState(false);
  
  const [modalSate, setModalState]= useState({
    active:false,
    component: null,
    pointer:null
  });
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    nome: "",
    slug: "",
    imagens: [],
    descricao: "",
    peso:"",
    altura:"",
    largura:"",
    comprimento:"",
    valor: "",
    categoria_id:"",
    ativo: false,
    estoque_composto:false
  });

  const handleBolean = (e) =>{
    console.log(e.target.name)
    setFormData({
      ...formData,
      [e.target.name]: e.target.value === "true" ? true : false,
    })
  }

  const [data, setData] = useState({
    imagens: []
  });

  useEffect(() => {
    setFormData({
        ...formData,
        imagens: data.imagens,
    });
  }, [data]);

  const handleChange = (e) => {
    if (e.target.name === "imagem") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  async function getProduto() {
    try {
      const response = await GenGet(`api/produto/${id}`);

      setFormData({
        ...formData,
        nome: response.nome,
        slug: response.slug,
        descricao: response.descricao,
        valor: response.valor,
        peso: response.peso,
        largura: response.largura,
        altura: response.altura,
        comprimento: response.comprimento,
        categoria_id: response.categoria_id,
        imagens: response.imagens,
        ativo: Boolean(response.ativo),
        estoque_composto: Boolean(response.estoque_composto) 
      });
      let categorias = await GenGet(`api/catlist`);
      setCat(categorias);
      setRefresh(false)
      setIsLoading(false);
    } catch (err) {
    }
  }

  useEffect(() => {
    if (id) {
      getProduto();
    }
  }, [id]);

  useEffect(() => {
    if (id && refresh) {
      getProduto();
    }
  }, [id, refresh]);

  useEffect(() => {
    async function getCategorias() {
      try {
        const response = await GenGet(`api/catlist`);
        setCat(response.data);
        setIsLoading(false);
      } catch (err) {
      }
    }
    getCategorias();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const newFormData = new FormData();
    newFormData.append('nome', formData.nome);
    newFormData.append('slug', formData.slug);
    newFormData.append('descricao', formData.descricao);
    newFormData.append('peso', formData.peso);
    newFormData.append('altura', formData.altura);
    newFormData.append('largura', formData.largura);
    newFormData.append('comprimento', formData.comprimento);
    newFormData.append('valor', formData.valor);
    newFormData.append('categoria_id', formData.categoria_id);
    newFormData.append('criado_por', formData.criado_por);
    newFormData.append('imagem', formData.imagem);
    newFormData.append('ativo', formData.ativo);
    newFormData.append('estoque_composto', formData.estoque_composto);
  
    try {
      const response = await axios.post(`update_produto/${id}`, newFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });
      setScssMsg(response.data.message)
      setTimeout(() => {
        setScssMsg(null);
      }, 3000);
    } catch (error) {
      setScssMsg("erro ao cadastrar produto")
      
    }
  };
  const updateComponentWidth = () => {
    const width = window.innerWidth;
    const isMobile = width <= 600; // Limite para considerar dispositivos móveis
    setComponentWidth(isMobile ? '90%' : '70%');
  };
  const [componentWidth, setComponentWidth] = useState('70%');

  useEffect(() => {
    updateComponentWidth(); // Atualiza a largura inicialmente
    window.addEventListener('resize', updateComponentWidth); // Adiciona o evento de redimensionamento
    return () => {
      window.removeEventListener('resize', updateComponentWidth); // Remove o evento ao desmontar o componente
    };
  }, []);

  if(!isLoading){  
    return (
      <div className="flex flex-col gap-2 w-[100%] overflow-y-scroll overflow-x-hidden text-[10pt]"> 
        <GenericModal
          Component={modalSate.component}
          pointer={modalSate.pointer}
          active={modalSate.active}
          setActive={(active) => setModalState({ ...modalSate, active })}
        /> 
      <div className={'flex flex-col justify-between p-1 w-[100%]'}>
        <div  className={`m-2 flex  text-[10pt] flex-col p-2 justify-start w-[${componentWidth}]`}>            
          <h3 className='text-[14pt]'>Editar / Visualizar Produto</h3>
          <p className='text-[9pt] -mt-2'>edite ou visualize seu produto cadastrado.</p>
          <hr/>

          <h3 className="text-[12pt] self-start"> Infos do Produto :</h3>
          <div className={`m-2 flex justify-around items-center align-middle p-2 gap-1 w-[100%]`}>
          <TextField  
              fullWidth 
                variant="outlined"
                size="small"
                label='Nome :'
                id="nome"
                value={formData.nome} 
                name="nome"
                onChange={handleChange}  

                />
              <TextField  fullWidth 
              variant="outlined"
              size="small"
              label='Slug :'
              id="slug" 
              name="slug"
              value={formData.slug} 
              onChange={handleChange}

              />
          <div className={"flex flex-col p-0 m-0"}>   

          </div>
          <div className={"flex flex-col p-0 m-0"}>
        

          </div>
          </div>
          <div className={`m-2 flex justify-around items-center align-middle p-2 gap-2 w-[100%]`}>
            <TextField  fullWidth 
                variant="outlined"
                size="small"
                label='Descrição :'
                id="descricao" 
                name="descricao"
                multiline
                rows={4} 
                value={formData.descricao}
                onChange={handleChange}/>
          </div>
          <div className={`m-2 flex justify-around items-center align-middle p-2 gap-2 w-[100%]`}>
            <TextField  fullWidth 
                variant="outlined"
                size="small"
                label='Valor :'
                id="valor" 
                name="valor" 
                value={formData.valor}
                onChange={handleChange}/>
            <div className={"flex flex-col p-0 m-1 w-[100%]"}>
              <Typography>Categoría:</Typography>
              <FormControl 
                fullWidth 
                variant="outlined"
                size="small">
                <select className="form-select" aria-label="categoria" 
                onChange={handleChange}
                id="categoria_id" 
                name="categoria_id" 
                value={formData.categoria_id}>
                  {cat ? cat.map(({id,nome}) => (
                    <option value={id} key={id}>{nome}</option>

                  )) : <></>}
                </select>
              </FormControl>
            </div>
          </div>
          <br/>

          <h3 className="text-[12pt] self-start"> Dimensões do Produto :</h3>
          <div className={`m-1 flex justify-around items-center align-middle p-2 gap-4 w-[100%]`}>
            <TextField  fullWidth 
                variant="outlined"
                size="small"
                label='Peso(kg) :'
                id="peso" 
                name="peso" 
                value={formData.peso}
                onChange={handleChange}/>
            <TextField  fullWidth 
              variant="outlined"
              size="small"
              label='Altura :'
              id="altura" 
              name="altura" 
              value={formData.altura}
              onChange={handleChange}/>
            <TextField  fullWidth 
              variant="outlined"
              size="small"
              label='Largura :'
              id="largura" 
              name="largura" 
              value={formData.largura}
              onChange={handleChange}/>
            <TextField  fullWidth 
              variant="outlined"
              size="small"
              label='Comprimento :'
              id="comprimento" 
              name="comprimento" 
              value={formData.comprimento}
              onChange={handleChange}/>
          </div>
          <h3 className="text-[12pt] self-start mt-2"> Configurações :</h3>
          <div className="flex flex-row w-full justify-start text-[10pt] items-center align-middle">
          <p className="p-2 font-semibold">Ativo:</p>
          <RadioGroup
              className='p-2'
                  aria-label="ativo"
                  name="ativo"
                  value={formData.ativo}
                  onChange={handleBolean}
                  row
                >
                  <FormControlLabel
                    value={"true"}
                    control={<Radio />}
                    label="Ativo"
                  />
                  <FormControlLabel
                    value={"false"}
                    control={<Radio />}
                    label="Inativo"
                  />
                </RadioGroup>
                <p className="p-2 font-semibold">Estoque:</p>
                <div className='flex flex-row gap-1'>
                    <RadioGroup
                      className='p-2'
                      aria-label="estoque_composto"
                      name="estoque_composto"
                      value={formData.estoque_composto}
                      onChange={handleBolean}
                      row
                  >
                    <FormControlLabel
                      value={"false"}
                      control={<Radio />}
                      label="Estoque simples"
                    />
                    <FormControlLabel
                      value={"true"}
                      control={<Radio />}
                      label="Estoque composto"
                    />

                  </RadioGroup>
                </div>
          </div>
          <div className={`flex flex-row-reverse justify-between p-2 m-2 mb-4 w-[100%]`} >
          <button onClick={handleSubmit} className={'bg-blue-400 hover:bg-blue-500 admin-button'}>Atualizar</button>
          {scssMsg ? 
              <div className={"flex flex-row-reverse p-1"} >
                <Alert severity="info" sx={{width:320}} > {scssMsg} </Alert>
              </div>
            : null}
          </div>
        </div>
        <div className='flex justify-between shadow rounded w-full p-2 flex-wrap'>
          <div className={`flex flex-col justify-start p-0 m-1 w-[40%] min-w-[300px]`}>     
                <h3 className="text-[12pt]"> Imagens do Produto :</h3>
                {formData.imagens? (<AdminImageSlider images={formData.imagens} id={id} setRefresh={setRefresh}/>) :null}
          </div>
          <div className="flex flex-grow w-[55%] min-w-[300px] mx-2 p-2">
            <Artigos pointer={id}/>
          </div>
        </div>
      </div>

        
      </div>     
 
    );
  } else{
    <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}><CircularProgress color="success"/></Box>
  }
  
};
export default AtualizarProd;