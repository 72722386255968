import React , { useState, useEffect } from "react";
import axios from "Admin/state/axios";
import { Box,
   TextField,
   Alert,
   Button, Card } from "@mui/material";

const NovaCategoria = (props) => {
  const [scssMsg , setScssMsg] = useState(false);
  const [refresh, setRefresh] = useState(false)
  const callback = props.setRefresh 
  const novaCat = {
    nome : "",
    descricao : ""
  };

  const [validationErrors, setValidationErrors] = useState({
    nome: "",
    descricao: "",
  });

  const [formData, setFormData] = useState({
    nome: "",
    descricao: "",
  });

  const setNome = (e) => {
    setFormData({
      ...formData,
      nome: e.target.value,
    });
    setValidationErrors({
      ...validationErrors,
      [e.target.name]: e.target.value ? "" : "Campo obrigatório.",
    });
  };


  const setDesc = (e) => {
    setFormData({
      ...formData,
      descricao: e.target.value,
    });
    setValidationErrors({
      ...validationErrors,
      [e.target.name]: e.target.value ? "" : "Campo obrigatório.",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = {};
    for (let key in formData) {
      if (!formData[key]) {
        errors[key] = "Campo obrigatório.";
      }
    }
    // Se houver erros, atualiza o estado com os erros de validação
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    
    axios.post("categoria", formData)
      .then((response) => {
        setScssMsg(response.data.message)
        setRefresh(true)
        callback(true)
        setTimeout(() => {
          setScssMsg(null);
        }, 3000);
        
      })
      .catch((error) => {
        setScssMsg(error.data.message)
        setTimeout(() => {
          setScssMsg(null);
        }, 3000);
      });
  };
  useEffect(() => {
    if(refresh){
      setFormData(novaCat)
      setRefresh(false)
    }

  }, [refresh]);

  return (
    <Card sx={{backgroundColor:"white", margin:"4px", width:"90%"}}> 
    <Box        
      display="flex"
      flexDirection="column"
      p="0.25rem 1.25rem"
      flexGrow={1}
       >
      {scssMsg ? 
        <Box display={'flex'} flexDirection={'row-reverse'} padding={1} >
          <Alert severity="info" sx={{width:320}} > {scssMsg} </Alert>
        </Box>
      : null}
          <Box display='flex' flexDirection="column">   
              <TextField  fullWidth 
                variant="outlined"
                size="small"
                sx={{ mb: 1, }} 
                id="nome"
                label={'Nome da categoría : '} 
                name="nome" 
                value = {formData.nome}
                onChange={setNome}  
                error={!!validationErrors.nome}
                helperText={validationErrors.nome}
                />
          </Box>
          <Box display='flex' flexDirection="column">
          
            <TextField  fullWidth 
              variant="outlined"
              size="small"
              label={'Descricao : '}
              value = {formData.descricao}  
              id="descricao" 
              name="descricao" 
              onChange={setDesc}  
              error={!!validationErrors.descricao}
              helperText={validationErrors.descricao}
              />
          </Box> 
        </Box>      
    <Box
        gridColumn="span 1"
        gridRow="span 1"
        display="flex"
        flexDirection="row-reverse"
        justifyContent="space-between"
        flex="1 1 100%"
        borderRadius="0.55rem"
        sx={{
          mb:4,
          width: '100%',}}
    >
    <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handleSubmit}>Cadastrar</button>
    </Box>
    </Card>

  );
};

export default NovaCategoria;