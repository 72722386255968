import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from "@mui/material";
import { GenGet } from 'Loja/state/api';
import CircularProgress from '@mui/material/CircularProgress';
import Destaque from 'Loja/components/compLoja/Destaque';

function Featured() {
  const [data, setData] = useState(null); // Inicialize como null para diferenciação clara entre carregando e vazio
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getFeatured() {
      try {
        const response = await GenGet('api/destaques');
        setData(response);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    }
    getFeatured();
  }, []);

  return (
    <Grid container spacing={2} minHeight={'60vh'} padding={3}>
      {!isLoading && data ? (
        <>
          <Grid item xs={12}>
            {data.promocao && data.promocao.length > 0 && (
              <>
                <Typography variant={'h5'}>Promoções</Typography><hr />
                <div className='flex flex-col p-4 w-[100%]'>
                  <div className='flex flex-col md:flex-row flex-wrap justify-center items-center align-middle gap-2'>
                    {data.promocao.map((item) => (
                      <Destaque
                        key={item.produto.slug}
                        slug={item.produto.slug}
                        nome={item.produto.nome}
                        descricao={item.produto.descricao}
                        imagem={item.produto.imagens.find(img => img.capa)?.imagem}
                      />
                    ))}
                  </div>
                </div>
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            {data.novidade && data.novidade.length > 0 && (
              <>
                <Typography variant={'h5'}>Novidades!</Typography><hr />
                <div className='flex flex-col p-4 w-[100%]'>
                  <div className='flex flex-col md:flex-row flex-wrap justify-center items-center align-middle gap-2'>
                    {data.novidade.map((item) => (
                      <Destaque
                        key={item.produto.slug}
                        slug={item.produto.slug}
                        nome={item.produto.nome}
                        descricao={item.produto.descricao}
                        imagem={item.produto.imagens.find(img => img.capa)?.imagem}
                      />
                    ))}
                  </div>
                </div>
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            {data.destaque && data.destaque.length > 0 && (
              <>
                <Typography variant={'h5'}>Destaques!</Typography><hr />
                <div className='flex flex-col p-4 w-[100%]'>
                  <div className='flex flex-col md:flex-row flex-wrap justify-center items-center align-middle gap-2'>
                    {data.destaque.map((item) => (
                      <Destaque
                        key={item.produto.slug}
                        slug={item.produto.slug}
                        nome={item.produto.nome}
                        descricao={item.produto.descricao}
                        imagem={item.produto.imagens.find(img => img.capa)?.imagem}
                      />
                    ))}
                  </div>
                </div>
              </>
            )}
          </Grid>
        </>
      ) : (
        <Box sx={{ p: 1, m: 1, width: { md: "100%" }, minHeight: '64vh' }} display="flex" justifyContent="center" flexDirection={'column'}>
          <Box display={'flex'} justifyContent={'center'} sx={{ width: '100%', marginTop: "10vh", marginBottom: "10vh" }}>
            <CircularProgress color="success" />
          </Box>
        </Box>
      )}
    </Grid>
  );
}

export default Featured;