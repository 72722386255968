import React, {useState, useEffect} from 'react'
import{
  Button,
  Grid,
  Box,
  Typography,
  Dialog, 
  DialogContent,
  CircularProgress
  } from "@mui/material";
import { GetSalesDash } from 'Admin/state/api';
import VerPedido from 'Admin/components/VerPedido';
import VerPedidoAberto from 'Admin/components/VerPedidoAberto';
import PagamentoPendente from 'Admin/components/PagamentoPendente';

  
const PedidosVendedor = () => {
  const [activeVisualizar, setActiveVisualizar] = useState(false);
  const [activeAberto, setActiveAberto] = useState(false);
  const [activePendente, setActivePendente] = useState(false);
  const [data, setData] = useState([]);
  const [pedidoPointer, setPedidoPointer] = useState(null);
  const [vendas, setVendas] = useState([]);
  const [enviadas, setEnviadas] = useState([]);
  
  const [isLoading, setIsLoading] = useState(true);

  const handleCloseMenu = () => {
    setActiveVisualizar(false);
    setActiveAberto(false);
  };

  const handleModalVisualizar = (id) => {
    setPedidoPointer(id)
    setActiveVisualizar(true);
  };

  const handleModalAberto = (id) => {
    setPedidoPointer(id)
    setActiveAberto(true);
  };
  const handleModalPendente = (id) => {
    setPedidoPointer(id)
    setActivePendente(true);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'valor_total', headerName: 'Valor Total', width: 130 },
    { field: 'status', headerName: 'Status', width: 130 },
    { field: 'created_at', headerName: 'Criado em', width: 200 },
    { field: 'updated_at', headerName: 'Atualizado em', width: 200 },
    { field: 'atualizado_por', headerName: 'Atualizado por', width: 200 },
    { field: 'user_id', headerName: 'ID do usuário', width: 130 },
  ];

  function PedidoItem({ pedido }) {
    return (
    <Grid container key={pedido.id} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
    <Grid item xs={9}>
      <Typography variant='body1'>
      <b>ID:</b> {pedido.id} - <b>Data:</b> {pedido.updated_at.slice(0, 10)} - <b>Valor:</b> {pedido.valor_total} - <b>Status:</b> {pedido.status} 
      </Typography>
    </Grid>
    <Grid item xs={3}><Button onClick={() => handleModalVisualizar(pedido.id)}>Ver Pedido</Button></Grid>    
    <hr/>    
    <br/>
    </Grid>
    );
  }
  
  function PedidoItemAberto({ pedido }) {
    return (
    <Grid container key={pedido.id} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
    <Grid item xs={9}>
      <Typography variant='body1'>
      <b>ID:</b> {pedido.id} - <b>Data:</b> {pedido.updated_at.slice(0, 10)} - <b>Valor:</b> {pedido.valor_total} - <b>Status:</b> {pedido.status} 
      </Typography>
    </Grid>
    <Grid item xs={3}><Button onClick={() => handleModalAberto(pedido.id)}>Ver Pedido</Button></Grid>                    
    <hr/>    
    <br />
    </Grid>
    );
  }

  useEffect(() => {
    async function getSales() {
      try {
        const response = await GetSalesDash();
        setData(response);
        setVendas(response.vendas.todas);
        setEnviadas(response.enviadas);
        setIsLoading(false);
      } catch (err) {

      }
    }
    getSales();
  }, []);

  return(
      <Box className='container' sx={{padding:5}}>  
        <Dialog open={activeVisualizar} onClose={() => handleCloseMenu()}>
          <DialogContent>
            <VerPedido id={pedidoPointer} />
          </DialogContent>
          </Dialog>  

        <Dialog open={activeAberto} onClose={() => handleCloseMenu()}>
          <DialogContent>
            <VerPedidoAberto id={pedidoPointer} />
          </DialogContent>
          </Dialog>  

          <Dialog open={activePendente} onClose={() => handleCloseMenu()}>
          <DialogContent>
            <PagamentoPendente id={pedidoPointer} />
          </DialogContent>
          </Dialog>  

          {!isLoading ? ( 
          <Grid container spacing={1} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>

          <Grid className='shadow rounded' item xs={12} sx={{backgroundColor:"white", margin:"4px", flexGrow: 1}}>
          <Box sx={{padding:1}}>
                <Typography variant='h6'>Pedidos Abertos</Typography>
                <hr/>
                <Box>
                {vendas && vendas.length > 0 ? <>
                  {vendas.map((item) => (
                    <PedidoItemAberto pedido={item} key={item.id} />
                  ))}
                </> 
              : 
                <Typography variant="body1">
                  Não há pedidos abertos.
                </Typography>}
              </Box>        
              </Box>
          </Grid>

          <Grid item xs={12} className='shadow rounded' sx={{backgroundColor:"white", margin:"4px",flexGrow: 1}}>
          <Box sx={{padding:1}}>
                <Typography variant='h6'>Pedidos Enviados</Typography>
                <hr/>
                {enviadas && enviadas.length > 0 ? <>
                  {enviadas.map((item) => (
                    <PedidoItem pedido={item} key={item.id} />
                  ))}
                </> 
              : 
                <Typography variant="body1">
                  Não há pedidos enviados.
                </Typography>}
              </Box>
            </Grid>


          <Grid item xs={12} className='shadow rounded' height="40vh" sx={{backgroundColor:"white", margin:"4px", flexGrow: 1}}>
              <Box sx={{padding:1}}>
                <Typography variant='h6'>Total de pedidos pagos em aberto</Typography>
                <hr/>
                <Box display={'flex'} flexDirection='column' sx={{marginLeft:2}} >
                  <Typography variant={'h6'} sx={{marginLeft:2}}>{data.vendas.total}</Typography>
                </Box>
              </Box>

          </Grid>
      </Grid>) : <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}><CircularProgress color="success"/></Box>}
          
        </Box>
  );
};

export default PedidosVendedor;