import React, { useEffect, useState } from 'react';
import { GenGet, GenPost } from 'Admin/state/api';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DisplayDate from 'Admin/components/DisplayDate'; 

function CampanhasEmail() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const nav = useNavigate()
  
  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await GenGet(`/campanhas`);
            setData(response);
            setLoading(false)

        } catch (err) {
            setLoading(false)
          
        }
    };
  
    fetchData();
  }, []);

  return (
    <div className='flex flex-col w-full p-2 relative text-[10pt]'>
    <h3 className='text-[14pt]'>Campanhas de Email</h3>
    <p className='text-[9pt] -mt-2'>suas campanhas de email.</p>
    <hr/>
    <button className='absolute right-5 top-2 bg-blue-400 hover:bg-blue-500 admin-button' onClick={()=> nav('/admin/campanha/email/nova')}>Nova campanha</button>
    {loading ? (
          <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
            <CircularProgress color="success"/>
          </div>
    ) : (
      <div className='flex flex-row flex-wrap gap-2'>
          {data.map(item => (
            <div key={item.id} className='w-[230px] flex flex-col justify-center px-4 py-2 border-gray-400 border-[1px] rounded-md shadow-md cursor-pointer hover:bg-gray-300' onClick={()=>nav(`/admin/campanhas/email/${item.id}`)}>
              <p className='font-semibold text-[12pt] my-0'>{item.nome}</p>
              <p className='font-semibold text-[8pt]  my-0'><b>status:</b> {item.status === 0 ? 'agendada' : 'enviada' }</p>
              <p className='text-[8pt]'><b>Início: </b><DisplayDate datetime={item.inicio} /> </p>
              <p className='text-[10pt] '><b>objetivo:</b><br/> {item.objetivo}</p>
              <br/>
              <p className='text-[10pt]' >total de emails <b>{item.itens_count}</b></p>
            </div>
          ))}


      </div>
    )}
    
    </div>
  )
}

export default CampanhasEmail