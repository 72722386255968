import React from 'react'
import { useNavigate } from "react-router-dom";
const GLOBAL_URL = process.env.REACT_APP_BASE_URL;

function Oferta({item}) {
  const navigate = useNavigate();
  return (
    <div className='flex w-[100%] flex-col sm:flex-row align-middle gap-1 relative'>
      <h2 className='archivo-black-regular4 p-2 m-0 absolute top-0 bg-slate-600 rounded-full shadow-lg bg-opacity-70'>OFERTA!</h2>
        <div className='flex sm:w-[40vw] flex-col p-6 m-0 pt-6 sm:justify-center'>
          <img className={'max-w-[300px] rounded-md shadow-md '}src={`${GLOBAL_URL}/${item.produto.imagens[0].imagem}`}/></div>
        <div className='flex sm:w-[50vw] m-0 justify-center items-center h-[50vh]'>
        <div className={'flex w-[100%] bg-slate-500 rounded-md p-4 bg-opacity-60'}>
          <div className='w-[100%] flex flex-col p-4 items-center justify-center text-slate-200'>
            <h3 className='text-[18pt] flex font-semibold text-yellow-400'>{item.produto.nome}</h3>
            <p className='text-base text-end font-semibold m-0 p-0 w-full'>de <span className=' text-[12pt] text-red-700 line-through -ml-1 m-0 p-0'>${item.valor_orig} </span></p>
            <p className='text-base text-end font-semibold m-0 p-0 w-full'>por apenas<br/><span className='archivo-black-mainprice  text-[16pt]'> <span className='archivo-black-mainprice  text-[12pt]'>R$</span>{item.produto.valor}</span></p>
          </div>
          <div className={'flex w-[100%] items-center justify-center'}>
            <button className='bg-yellow-500 w-[120px] h-[60px] p-2 rounded-md shadow-md hover:bg-yellow-400' onClick={() => navigate(`produto/${item.produto.slug}`)}>Comprar!</button>
          </div>
        </div>
        </div>    
    </div>
  )
}

export default Oferta