import React, { useEffect, useState } from 'react';
import { GenGet, GenPost } from 'Admin/state/api';
import GenericDataGrid from 'Admin/components/DataGrids/GenericDataGrid';
import { CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

function AdicionarLeadsPublico() {
    const { id } = useParams();
  const [data, setData] = useState({});
  const [msg, setMsg] = useState(null);
  const [loading, setLoading] = useState(true);
  const nav = useNavigate();
  const [formData, setFormData] = useState({
    itens:{
      'avulso':[],
      'usuario':[]
    },
  })

  const handleItensAvulso = (data) => {
    setFormData({
      ...formData,
      itens: {
        ...formData.itens,
        'avulso': data,
      },
    });
  };
  
  const handleItensUsuario = (data) => {
    setFormData({
      ...formData,
      itens: {
        ...formData.itens,
        'usuario': data,
      },
    });
  };
  
  
  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await GenGet(`/addleads/list/${id}`);
            console.log(response)
            setData(response);
            setLoading(false);

        } catch (err) {
            setLoading(false);
        }
    };
  
    fetchData();
  }, [id]);

  const handlePost = async () => {
    // Verifica se pelo menos um dos arrays em itens não está vazio
    if (formData.itens.avulso.length === 0 && formData.itens.usuario.length === 0) {
      setMsg("Pelo menos um lead deve ser selecionado.");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
      return;
    }

  try {

    const response = await GenPost(`/leads/addtolist/${id}`, formData);
    console.log(response.message)
    //setMsg(response.message);
    setFormData({
      itens:{
        'avulso':[],
        'usuario':[]
      },
    })

  } catch (err) {
    console.log(err.message)
    //setMsg(err.message);

  } finally {
    setTimeout(() => {
      setMsg(null);
    }, 3000);
  }
  };
  const usuariosCols = ['ID', 'Nome','Email'];
  const avulsosCols = ['ID', 'Email'];

  return (
    <div className='flex flex-col w-full p-2 text-[10pt]'>
    <h3 className='text-[14pt]'>Adicionar Leads em um Público</h3>
    <p className='text-[9pt] -mt-2'>adicione leads em sua lista personalizada.</p>
    {!loading ? (
      <div className='flex w-full flex-col'>
        <GenericDataGrid rows={data.usuarios} columns={usuariosCols} per_page={25} title="usuários" handleSelect={handleItensUsuario} selectable={true}/>
        <GenericDataGrid rows={data.avulsos} columns={avulsosCols} per_page={25} title="avúlsos" handleSelect={handleItensAvulso} selectable={true}/>
      </div>) : (
        <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
          <CircularProgress color="success"/>
        </div>
      
      )}
      <div className='w-full flex flex-row-reverse gap-2'>
      <button className=' bg-blue-400 hover:bg-blue-500 admin-button' onClick={handlePost}>Enviar</button>
      <button className=' bg-gray-400 hover:bg-gray-500 admin-button' onClick={()=>nav(`/admin/campanhas/publico/${id}`)}>Voltar</button>
      {msg && (
        <div className='w-full flex flex-row-reverse p-2 justify-center align-middle items-center'>
          <p className='m-0 p-0 text-gray-800'>{msg}</p>
        </div>
      )}
      </div>
    

    </div>
  )
}

export default AdicionarLeadsPublico

/* 



*/