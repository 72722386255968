import React from 'react';
import {useContext, useState, useEffect} from 'react';
import { EnvContext } from 'Ecommerce';
import { 
  Typography,
  Button, 
  Grid,
  Box
} from "@mui/material";


  export default function Artigo({
    id,
    titulo,
    subtitulo,
    conteudo,
    data,
    imagem,
  }) {
    const { appUrl } = useContext(EnvContext);

    return (
<Grid container sx={{ display: 'flex', flexWrap: 'wrap', justifyContent:'space-around'}}>
  <Grid item xs={12} p={1}>
    <Typography variant={'h5'}>{titulo}</Typography>
  </Grid>

  <Grid item xs={12} md={6} mb={4} minWidth={'400px'} display={'flex'} paddingLeft={2}>
    <Box paddingBottom={2} >    
      <Typography variant={'h6'}  sx={{fontWeight:'2'}}>{subtitulo}</Typography>
      <Typography maxWidth={400} variant={'body2'}>{conteudo}</Typography>
    </Box>

  </Grid>
  <Grid item xs={6} minWidth={'400px'} display={'flex'} flexDirection={'column'}>
    <img src={`${appUrl}/${imagem}`} width={'400px'} height={'auto'} style={{borderRadius:'20px'}}/>
    <Typography variant={'body2'}>criado em : {data}</Typography>
  </Grid>
  <hr/>
</Grid>
    );
  };