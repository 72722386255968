import { CircularProgress } from '@mui/material';
import GenericDataGrid from 'Admin/components/DataGrids/GenericDataGrid';
import { GenGet } from 'Admin/state/api';
import React, {useEffect, useState} from 'react';

function MarketingUsuario({id}) {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        const response = await GenGet(`/marketing/usuario/${id}`);
        console.log(response)
        setData(response);
        setIsLoading(false);

      } catch (err) {
        console.log(err)

      } finally {
        setIsLoading(false);
      }
    }
    if (id) {
      getData();
    }
}, [id]);

  const columns = ['ID', 'Página', 'Usuário', 'Acessos'];

  return (
    <div className='p-2 text-[10pt]'>
      <h3 className='text-[14pt]'>Painel de metricas do Usuário</h3>
      <p className='text-[9pt] -mt-2'>visualize as metricas do usuário</p>

      {!isLoading ? (
          <div className='flex flex-col w-full gap-2'>
            <div className='flex flex-col p-2 text-[18pt] font-semibold text-gray-800'>
                <p className='text-[12pt] p-0 m-0'>Total de acessos</p>
                <p className='p-0 m-0'>{data.total_acessos}</p>
            </div>
          <GenericDataGrid rows={data.acessos} columns={columns} per_page={10} title="Histórico do usuário" />

          </div>
        ) : (
          <div className={'flex justify-center align-middle items-center h'} display={'flex w-full h-full'}>
            <CircularProgress color="success"/>
          </div>
        )}
    </div>
       
  )
}

export default MarketingUsuario