import React, {useState, useEffect} from 'react'
import{
  Box, 
  Typography, 
  TextField,
  Accordion,
  Grid, 
  AccordionSummary,
  CircularProgress, 
  AccordionDetails
  } from "@mui/material";
  import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
  import { GenGet } from 'Loja/state/api';

  const token = localStorage.getItem('token');  

  const filtrarPorString = (ordens, searchString) => {
    if (!searchString) {
      return ordens;
    }
  const lowerCaseSearch = searchString.toLowerCase();
    return ordens.filter((item) => item.created_at.includes(lowerCaseSearch));
  };

function PedidosUsr() {
  const [expandedPanel, setExpandedPanel] = useState(null);
  const handleAccordionChange = (panel) => {
    setExpandedPanel(panel === expandedPanel ? null : panel);
  };
  const [ordens_abertas, setOrdensAbertas] = useState([]);
  const [ordens_finalizadas, setOrdensFinalizadas] = useState([]);
  const [ carregando, setCarregando] = useState(true);
  const [searchString, setSearchString] = useState('');
  const [isLoadng, setIsLoading] = useState(true);

  const ordensFiltradas = filtrarPorString(ordens_abertas, searchString);
  const ordensFiltradasFinalizadas = filtrarPorString(ordens_finalizadas, searchString);

  function PedidoItem({pedido, retomar}) {
    
    const dateString = pedido.created_at;
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    return (
      <Grid item xs={12} margin={0.5} key={pedido.id}>
      <Accordion 
      sx={{backgroundColor:"white"}} 
      key={pedido.id}
      expanded={expandedPanel === pedido.id}
      onChange={() => handleAccordionChange(pedido.id)}
      >
      <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel2bh-content"
      id="panel2bh-header"
      >
      <Grid container display={'flex'} flexDirection={'row'}>
        <Grid item xs={4}> 
        <Typography variant={'body2'}><b>{pedido.id}</b></Typography>
        </Grid>
        <Grid item xs={4}>  
        <Typography variant={'body2'}> DATA: {formattedDate}</Typography>
        </Grid>
        <Grid item xs={4}>  
        <Typography variant={'body2'} textAlign={"end"}>{pedido.status}</Typography>
        </Grid>
      </Grid>  
      </AccordionSummary>

      <AccordionDetails>
        <Grid container display={'flex'} flexDirection={'row'} flexWrap={'wrap'} >
            <Grid 
                xs={12}
                md={6}
                item
                display="flex"
                flexDirection="column" 
                padding='5px'>
                  <Typography variant='h6'>Informações do pedido</Typography>
                  <Typography sx={{marginTop:1}}>Numero do pedido : {pedido.id}</Typography>
                  <Typography sx={{marginTop:1}}>Data do Pedido: {formattedDate}</Typography>
                  <Typography sx={{marginTop:1}}>Valor Total: R$ {pedido.valor_total}(c/frete)</Typography>
                  <Typography sx={{marginTop:1}}>Status: {pedido.status}</Typography>
                  <br/>
                  <Typography variant='h6'>Itens do pedido</Typography>
                    {pedido.itens ? pedido.itens.map((item) => (
                    <Box key={item.id}>
                      <Typography sx={{marginTop:1}}>Produto: {item.produto.nome}</Typography>
                      <Typography sx={{marginTop:1}}>Quantidade: {item.quantidade}</Typography>
                      <Typography sx={{marginTop:1}}>Valor: R$ {item.produto.valor}</Typography>
                      <Typography sx={{marginTop:1}}>Total: R$ {item.valor_total}</Typography>
                    </Box>
                    )) : <> algo deu errado... entre em contato com o suporte.</>}
                  {retomar?(                  
                  <div className='flex justify-end gap-2 w-full'>
                    <div className='bg-yellow-400 hover:bg-yellow-200 text-slate-800 font-semibold justify-center align-middle p-2 cursor-pointer rounded-md'>Retomar</div>
                    <div className='bg-red-400 hover:bg-red-200 text-slate-800 font-semibold justify-center align-middle p-2 cursor-pointer rounded-md'>Excluir</div>
                  </div>):(null)}

            </Grid>
        </Grid>

      </AccordionDetails>
  </Accordion>
  </Grid>
    );
  }

  async function getPedidos() {
    try {
      const response = await GenGet(`api/uordens`);
      setOrdensAbertas(response.ordens_abertas);
      setOrdensFinalizadas(response.finalizadas);
      setIsLoading(false);

    } catch (err) {
      
    }
  }

  useEffect(() => {
    async function fetchData() {
        getPedidos();
        setCarregando(false);
    }
    fetchData();
  }, [token]);

  return (
    <>
    {!isLoadng ? (
    <Box className='container' sx={{padding:5}}>
      {carregando ? (
      <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"30vh"}}>
        <CircularProgress color="success" sx={{marginTop:'150px'}} />
      </Box>) 
      : (
      <>
      <Grid container spacing={2}>
        <Box display={'flex'} width='100%' justifyContent={'space-between'} padding={2}>
        <TextField
          label="Data: YYYY-MM-DD"
          value={searchString}
          size={'small'}
          variant={'standard'}
          onChange={(e) => setSearchString(e.target.value)}
          />
        </Box>

        <Grid item  width={"100%"} overflow={'auto'}>
        <Typography variant='h6'>Pedidos Processados</Typography>

        {ordens_abertas.length > 0 ? <>
              <Grid container
                display="flex"
                flexDirection={'row'}
                justifyContent="space-between"
                flexGrow={1}
              >
                {ordensFiltradas.map((item) => (
                  <PedidoItem pedido={item} key={item.id}/>
                  ))}

              </Grid>
              </> : (
              <Typography>Nenhum pedido processado.</Typography>
            )}

        </Grid>

        <Grid item width='100%' overflow={'auto'}>

      <br/>
      {ordens_finalizadas.length > 0 ? <>
            <Grid container
              display="flex"
              flexDirection={'row'}
              justifyContent="space-between"
              flexGrow={1}
            >
            <p marginLeft={2}> busque por data:</p>
              <TextField
                label="Data: YYYY-MM-DD"
                value={searchString}
                size={'small'}
                variant={'standard'}
                onChange={(e) => setSearchString(e.target.value)}
              />
              {ordensFiltradasFinalizadas.map((item) => (
                <PedidoItem pedido={item} key={item.id}/>
                ))}

            </Grid>
            </> : (
            <Typography>Nenhum pedido enviado.</Typography>
          )}
      </Grid>

    </Grid>
      
      </> )}


    </Box>
    ) : (
      <Box sx={{ p: 1, m: 1, width: {md:"100%"}, minHeight:'64vh'}} display="flex" justifyContent="center" flexDirection={'column'}>
        <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}>
            <CircularProgress color="success"/>
        </Box>
    </Box> 
    )}
    </>
  )
}

export default PedidosUsr