import React,{ useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Box, Grid, Typography, Drawer, useMediaQuery } from "@mui/material";
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import SendIcon from '@mui/icons-material/Send';
import WebIcon from '@mui/icons-material/Web';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import useLogout from "hooks/useLogout";
import {
    ShowChart as ShowChartIcon,
    ShoppingCart as ShoppingCartIcon,
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
    Inbox as InboxIcon,
    Person as PersonIcon,
    Category as CategoryIcon,
    Store as StoreIcon,
    NotificationsNone as NotificationIcon,
    Email as EmailIcon
  } from "@mui/icons-material";

import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import {
  IconButton,
  Avatar,
  Menu,
  MenuItem 
} from "@material-ui/core";



const SalesPannel = () => {
    const navigate = useNavigate();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const isSmScreen = useMediaQuery('(max-width:900px)');
    const [anchorEl, setAnchorEl] = useState(null);
    const [title, setTitle] = useState('');
    const logout = useLogout()
    const open = Boolean(anchorEl);
    
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    useEffect(() => {

      }, [title]);

  return (
    <Grid container height={'100vh'} sx={{background:'#1f1f1f', color:'white'}}>
        <Drawer anchor="left" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <TreeView
            aria-label="multi-select"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            multiSelect
            sx={{flexGrow: 1, maxWidth: 500, overflowY: 'auto', background:'#1f1f1f',color:'white' }}
            >
            <Typography m={1} ml={2}> <b>Painel vendedor</b></Typography>  
            <TreeItem sx={{marginBottom:1}}
            nodeId="2"
            onClick={() => {
                navigate('/sales/pedidos');
                setTitle('Pedidos');
                }}
            label={
                <Box display={'flex'} gap={0.8}>
                    <LocalShippingIcon />
                    <Typography variant="body1" sx={{ fontSize: '13px', fontWeight:'bold' }}>
                    PEDIDOS
                    </Typography>
                </Box>
            }
            />

            <TreeItem  sx={{marginBottom:1}}
            onClick={() => {
            navigate('/sales/avisos');
            setTitle('Avisos');
            }}
            nodeId="3"
            label={
                <Box display={'flex'} gap={0.8}>
                <NotificationIcon />
                <Typography variant="body1" sx={{ fontSize: '13px', fontWeight:'bold' }}>
                AVISOS
                </Typography>
                </Box>
            }
            />
            
        </TreeView>
        </Drawer>
        <Grid item xs={2} md={0} height={'100%'} display={{xs:'none', md:'block'}}>
            <Typography m={2} sx={{fontSize:'12pt'}}> PAINEL VENDEDOR </Typography>
        <TreeView
            aria-label="multi-select"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            multiSelect
            sx={{flexGrow: 1, maxWidth: 500, overflowY: 'auto', background:'#1f1f1f' }}
            >
            <Typography m={1} ml={2}> <b>Painel vendedor</b></Typography>  
            <TreeItem sx={{marginBottom:1}}
            nodeId="2"
            onClick={() => {
                navigate('/sales/pedidos');
                setTitle('Pedidos');
                }}
            label={
                <Box display={'flex'} gap={0.8}>
                    <LocalShippingIcon />
                    <Typography variant="body1" sx={{ fontSize: '13px', fontWeight:'bold' }}>
                    PEDIDOS
                    </Typography>
                </Box>
            }
            />

            <TreeItem  sx={{marginBottom:1}}
            onClick={() => {
            navigate('/sales/avisos');
            setTitle('Avisos');
            }}
            nodeId="3"
            label={
                <Box display={'flex'} gap={0.8}>
                <NotificationIcon />
                <Typography variant="body1" sx={{ fontSize: '13px', fontWeight:'bold' }}>
                AVISOS
                </Typography>
                </Box>
            }
            />

        </TreeView>
        
        </Grid>

        <Grid item xs={12} md={10}  height={'100vh'}>
            <Grid container display={'flex'}>
                <Grid item xs={12} height={'8vh'}>
                    <Box display={'flex'} justifyContent={'space-between'}marginTop={.1}>
                        <Box display={'flex'}>
                        {isSmScreen && (
                            <IconButton onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
                            {isDrawerOpen ? <CloseIcon style={{color:'white'}}/> : <MenuIcon style={{color:'white'}}/>}
                            </IconButton>
                            )} 
                        <Typography sx={{fontSize:'20px', marginLeft:2, marginTop:2}}><b>{title}</b></Typography>
                        </Box>
                            
                            <React.Fragment>
                            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                            <Tooltip title="Account settings">
                                <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                >
                                <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
                                </IconButton>
                            </Tooltip>
                            </Box>
                            <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 1,
                                sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                                },
                            }}

                            >
                            <MenuItem onClick={handleClose}>
                            <Avatar /> Perfil
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={()=>{logout()}}>
                                <ListItemIcon>
                                <Logout fontSize="small" />
                                </ListItemIcon>
                                Sair
                            </MenuItem>
                            </Menu>
                        </React.Fragment>
                        {/* {renderAppBarUserMenu} */}

                    </Box>
                </Grid>
                <Grid item xs={12} height={'89vh'} display={'flex'} justifyContent={'center'} width={'100%'} overflow={'auto'} className={'text-slate-800'} 
                 sx={{background:'white', borderRadius:'15px', margin:'5px', padding:1, boxSizing:'border-box' }}> 
                    <Outlet/>
                </Grid>
            </Grid>
        </Grid>
    </Grid> 
  );
};

export default SalesPannel;