import React from 'react';

function formatDateTime(datetime) {
  const dateObj = new Date(datetime);
  const formattedDate = `${padZero(dateObj.getDate())}/${padZero(dateObj.getMonth() + 1)}/${dateObj.getFullYear()} ${padZero(dateObj.getHours())}:${padZero(dateObj.getMinutes())}`;
  return formattedDate;
}

function padZero(num) {
  return num < 10 ? `0${num}` : num;
}

function DisplayDate({ datetime }) {
  const formattedDate = formatDateTime(datetime);

  return <span>{formattedDate}</span>;
}

export default DisplayDate;