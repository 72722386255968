import React, { useState, useEffect } from 'react'
import CustomSelect from 'Admin/components/CustomSelect';
import { GenPost } from 'Admin/state/api';
import { Alert } from '@mui/material';

const appUrl = process.env.REACT_APP_BASE_URL;

function MovimentoEstoqueSimples({data, handleRefresh}) {
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [formData, setFormData] = useState({
    produto_id:data.id
  });
  
  const handleQuantidade = (e) =>{
    setFormData({
        ...formData,
        quantidade: e.target.value,
      });
  }

  const handleTipo = (id, value) =>{
    setFormData({
        ...formData,
        tipo: value,
      });
  }

  const handleSubmit = () => {
    async function PostForm() {
        try {
          const response = await GenPost('mov-estoque', formData);
          console.log(response)
          setMsg(response.message);
          setTimeout(() => {
            setMsg(null);
          }, 3000);
          handleRefresh(true);

        } catch (err) {
        setMsg(err.message);
        setTimeout(() => {
            setMsg(null);
          }, 3000);
        }
      }
      PostForm();
  }

  return (
    <div className='p-2 text-[10pt]'>
        <h3 className='text-[12pt]'>Estoque simples</h3>
        <hr/>
        <p className='font-semibold text-[11pt]'>{data.nome}</p>

        <div className='flex flex-row gap-2 items-center align-middle p-2 bg-slate-200 rounded-md'>
          <img src={`${appUrl}/${data.imagens.find(img => img.capa)?.imagem}`} className='w-[80px] h-[80px] rounded-md'/>
          <p className='text-[11pt] pl-4'>- Descrição: {data.descricao}</p>
          <p className='text-[11pt] pl-4 w-[200px] text-wrap'>- Status: {data.ativo === 1 ? 'Ativo' : "Inativo"}</p>
        </div>
        <div className='flex flex-col gap-2 p-2 bg-slate-200 rounded-md mt-2'>
          <p className='text-[11pt] pl-4'>Quantidade em estoque: {data.estoque.quantidade}</p>
          <p className='text-[11pt] font-medium pl-4'>  Movimentar estoque:</p>
          <div className='flex flex-row p-2 gap-2'>
          <CustomSelect data={{1:'entrada', 2:'saida'}} label={'Tipo'} onChange={handleTipo}/>
          <div  className='flex flex-col gap-1'>
              <input 
                  type="number"
                  name="quantidade"
                  onChange={handleQuantidade}
                  id="quantidade"
                  placeholder='Quantidade'
                  className="px-4 py-2 rounded border border-gray-300 focus:outline-none"/>
          </div>
          <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handleSubmit}>Confirmar</button>
          </div>
          {msg ? 
            <div className='flex justify-end p-1' >
              <Alert severity="info" sx={{width:320}} > {msg} </Alert>
            </div>
          : null}
        </div>

        
    </div>
  )
}

export default MovimentoEstoqueSimples