import React, {useEffect, useState} from 'react'
import { 
    Box, 
    Typography, 
    Grid,
    TextField, 
    Button,
    CircularProgress,
    } from "@mui/material";

import { GenPost } from 'Loja/state/api';

    

function ModalCalcFreteFinal( { handleFrete, data, cep, setFrete, frete, isento, setIsento } ) {
    const [fretes, setFretes] = useState(null);
    const [reload, setReload] = useState(false);
    const [selected, setSelected] = useState(false);
    const [cartData, setCartData] = useState({'produtos' : data, 'postal_code':cep});

    const handleSelect = (frete) =>{
      handleFrete(frete);
      setFrete(frete);
      setSelected(true);
    }

      useEffect(() => {
        if (cep && cep.length === 8){
          setCartData((prevCartData) => {
            return { ...prevCartData, 'postal_code': cep };
          });
          setReload(true)
        }   
      }, [cep]);

      useEffect(() => {
        if (reload) {
          getFrete(cartData)
          setReload(false);
        }

      }, [reload]);

      const getFrete = async (data) => {
        try {
          let response = await GenPost('api/getfrete', data);
          console.log(response)
          if (response.message ==="Frete isento"){
            setIsento(true);
          } else {
            setIsento(false);
          }

          setFretes(response.response)

        } catch (error) {
          console.error('Erro na requisição de frete:', error);
        }
      };

    function FreteItem({ frete }) {
        return (
            <Grid container display={'flex'} flexDirection={'row'} flexWrap={'wrap'}>
              <Grid
                xs={12}
                item
                display="flex"
                flexDirection="column"

              >

                {frete.error ? (
                  null
                ) : (
                  <Box 
                  borderRadius={2}
                  sx={{ borderColor: 'lightblue' }}                  
                  padding={2}
                  border={1}>
                  <Box display={'flex'} justifyContent={'space-between'}                 

>
                  <Typography variant={'h6'}>Empresa: {frete.company.name}</Typography>
                  </Box>
                  <Typography paddingLeft={1}>Tipo: {frete.name}</Typography>
                    <Typography paddingLeft={1}>Valor : R${frete.price}</Typography>
                    <Typography paddingLeft={1}>Tempo estimado : {frete.delivery_time} dias</Typography>
                    {!selected ? ( 
                    <Box display={'flex'} flexDirection={'row-reverse'}>
                      <Button onClick={() => handleSelect(frete)}>Escolher</Button>
                    </Box>) : null}

                  </Box >
                )}
              </Grid>
            </Grid>
        );
      }

      if (!selected){
      return (

      <Box padding={2}>
        {isento ? ( <p>Frete Gratis.</p> ) :
        (<>
          <h4 className='text-[14pt] font-semibold'>Escolha o seu frete</h4> 

            <div className='flex pt-4 gap-2 flex-col max-h-[600px] overflow-auto'>
            {fretes ? ( <>{fretes.map((item) => (
            <FreteItem frete={item} key={item.id}/>
            ))}</>)       
          : (<Box display={'flex'} gap={1} alignItems={'center'} flexDirection={'column'} justifyContent={'center'} > <Typography>escolha ou cadaste um endereço acima </Typography> </Box>)
          }
          </div>
          </>)}
      </Box> )
      } else {
      return (
        <Box padding={1}>
          <Box paddingTop={1} gap={2} display='flex' flexDirection={'column'} justifyContent={'center'}>
              <FreteItem frete={frete}/>
              <Box display={'flex'} flexDirection={'row-reverse'}><Button onClick={()=>{setSelected(false)}}>TROCAR ENVIO</Button></Box>
          </Box>   
      </Box>
      )
    }
}

export default ModalCalcFreteFinal