import axios from "axios";
const token = localStorage.getItem('token');
const GLOBAL_URL = process.env.REACT_APP_BASE_URL;

export default axios.create({
    baseURL: `${GLOBAL_URL}`,
    headers:{
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': `Bearer ${token}`},
        withCredentials: true,    
});

export const apiPixel = axios.create({
    baseURL: `${GLOBAL_URL}`,
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    withCredentials: true,
  });