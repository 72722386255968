import React, { useEffect, useState } from 'react';
import { GenGet, GenPost } from 'Admin/state/api';
import { CircularProgress } from '@mui/material';
import GenericDataGrid from 'Admin/components/DataGrids/GenericDataGrid';
import { useNavigate } from 'react-router-dom';

function NovaCampanha() {
  const [lead_list, setLeadList] = useState([]);
  const [template_list, setTemplateList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [msg, setMsg] = useState(null);
  const nav = useNavigate()
  const [formData, setFormData] = useState({
    nome: '',
    inicio: '',
    objetivo: '',
    lead_list_ids: [],
    email_template_id: null
  });

  const handleField = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GenGet(`/campanha/form`);
        setLeadList(response.lead_lists);
        setTemplateList(response.templates);
        setLoading(false);
        console.log(response);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleTemplate = (e) => {
    const selectedTemplate = Number(e.target.value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      email_template_id: selectedTemplate
    }));
  };

  const handleList = (data) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      lead_list_ids: data
    }));
    console.log({
      ...formData,
      lead_list_ids: data
    })
  };

  const handlePost = async () => {
    if (formData.nome === '' || formData.descricao === '' || formData.objetivo === '' || formData.email_template_id === null) {
      setMsg("Todos os campos são obrigatórios.");
      setTimeout(() => {
          setMsg(null);
      }, 3000);
      return;
  }

    // Verifica se pelo menos um dos arrays em itens não está vazio
    if (formData.lead_list_ids.length === 0) {
      setMsg("Pelo menos uma lista de lead deve ser selecionads.");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
      return;
    }

  try {
    const formattedInicio = new Date(formData.inicio).toISOString().slice(0, 19).replace('T', ' ');

    const response = await GenPost('/campanha/create', {...formData, inicio:formattedInicio});
    setMsg(response.message);
    setFormData({
      nome: '',
      inicio: '',
      objetivo: '',
      lead_list_ids: [],
      email_template_id: null
    })

  } catch (err) {
    setMsg(err.message);

  } finally {
    setTimeout(() => {
      setMsg(null);
    }, 3000);
  }
  };


  const cols = ['ID', 'Nome', 'Descrição'];  
  if (loading) {
    return (
      <div className='flex flex-col w-full p-2'>
      <h3 className='text-[14pt]'>Criar Campanha</h3>
      <p className='text-[9pt] -mt-2'>Crie uma campanha de email.</p>
        <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
          <CircularProgress color="success" />
        </div>
      </div>
    );
  }

  return (
    <div className='flex flex-col w-full p-2'>
      <h3 className='text-[14pt]'>Criar Campanha</h3>
      <p className='text-[9pt] -mt-2'>Crie uma campanha de email.</p>
      <div className='flex flex-row gap-2 my-2 w-full'>
            <div className='flex flex-col gap-1 w-full'>
                  <p className='p-0 m-0 font-semibold'>Nome</p>
                    <input
                      name="nome"
                      onChange={handleField}
                      id="nome"
                      value={formData.nome}
                      placeholder='Nome'
                      className="px-4 w-full py-2 rounded border border-gray-300 focus:outline-none"
                    />
            </div>
            <div className='flex flex-col gap-1 w-full'>
                  <p className='p-0 m-0 font-semibold'>Início</p>
                  <input
                      name="inicio"
                      onChange={handleField}
                      id="inicio"
                      type='datetime-local'
                      value={formData.inicio}
                      className="px-4 w-full py-2 rounded border border-gray-300 focus:outline-none"
                    />
            </div>
      </div>
      <div className='flex flex-row gap-2 my-2 w-full'>
            <div className='flex flex-col gap-1 w-full'>
                  <p className='p-0 m-0 font-semibold'>Objetivo</p>
                    <input
                      name="objetivo"
                      onChange={handleField}
                      id="nome"
                      value={formData.objetivo}
                      placeholder='Objetivo'
                      className="px-4 w-full py-2 rounded border border-gray-300 focus:outline-none"
                    />
            </div>
      </div>
      <div className='flex flex-col justify-between min-w-[220px]'>
        <label className='p-0 m-0 font-semibold'>Template:</label>
        <select
          className='px-4 h-[40px] py-2 rounded border border-gray-300 focus:outline-none w-full'
          aria-label='template_id'
          onChange={handleTemplate}
          id='template_id'
          name='template_id'
          value={formData.email_template_id || ''}
        >
          <option value="">Selecione um template</option>
          {template_list && template_list.map(item => (
            <option key={item.id} value={item.id}>{item.nome}</option>
          ))}
        </select>
      </div>
      <GenericDataGrid 
        rows={lead_list} 
        columns={cols} 
        per_page={25} 
        title="Lista de leads" 
        handleSelect={handleList} 
        selectable={true} 
      />
      <div className='w-full flex flex-row-reverse gap-2'>
      <button className=' bg-blue-400 hover:bg-blue-500 p-2 text-white rounded-md max-w-[150px]' onClick={handlePost}>Cadastrar</button>
      <button className=' bg-gray-400 hover:bg-gray-500 p-2 text-white rounded-md max-w-[150px]' onClick={()=>nav('/admin/campanhas/email')}>Voltar</button>
      </div>
    </div>
  );
}

export default NovaCampanha;
