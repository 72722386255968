import React, { useEffect, useState } from 'react';
import { GenGet, GenPost } from 'Admin/state/api';

function EmailsEnviado({id}) {
    const [msg, setMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({
      id:'',
      status:'',
      titulo:'',
      destinatario:'',
      dataenvio:'',
      conteudo:'',
      usuario:{},
      pedido:{},
    })
  
    useEffect(() => {
      const fetchData = async () => {
          try {
              const response = await GenGet(`/email/sent/${id}`);
              console.log(response)
              setData(response);             
              setIsLoading(false);

          } catch (err) {
              setIsLoading(false)
          }
      };
    
      fetchData();
    }, []);
  
/* 
{
    "id": 16,
    "status": "em fila",
    "titulo": "Teste Dinamico",
    "destinatario": "brunobuzzeto@gmail.com",
    "dataenvio": "07/07/2024",
    "conteudo": "Esse conteudo, de teste, \nPara testar se funciona e o espaço etc...\n<br/>\nCom quebras e tudo mais.s"
}
*/
  return (
    <div className='flex flex-col w-full p-2'>
    <h3 className='text-[14pt]'>Email Enviado</h3>
    <p className='text-[9pt] -mt-2'>informações do email enviado.</p>

        <div className='flex flex-col my-2 '>
          <p className='p-0 m-0'><b>id: </b>{data.id} - <b>data de envio: </b>{data.dataenvio}</p>
          <p className='p-0 m-0 mb-2'><b>destinatário:</b> {data.destinatario} - <b>status: </b>{data.status}</p>
          <p className='p-0 m-0 mb-2 font-semibold'>{data.titulo}</p>
          <div className='w-full flex  min-h-[200px] flex-col p-3 border-[1px] border-gray-800 rounded-md' dangerouslySetInnerHTML={{ __html: data.conteudo }}></div>
        </div>
        {data.usuario && (
          <div>
          <hr/> 
            <p>Dados de usuário:</p>
            <p>id: {data.usuario.id} - nome: {data.usuario.nome}</p>
            <p>id: {data.usuario.email}</p>
          </div>
         )}
        
        {data.pedido && ( 
          <div>
          <hr/> 
            <p>Dados de pedido:</p>
            <p>id: {data.pedido.id} - status: {data.pedido.status}</p>
            <p>valor: {data.pedido.valor_total}</p>
          </div>
          )}


  </div>
  )
}

export default EmailsEnviado