import React, {useEffect, useState} from 'react';
import { 
  Box, 
  Typography, 
  CircularProgress,
} from "@mui/material";
import axios from "Admin/state/axios";
import { useNavigate } from 'react-router-dom';
import GenericDataGrid from 'Admin/components/DataGrids/GenericDataGrid';
const token = localStorage.getItem('token');


function Produtos() {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const nav = useNavigate();

  useEffect(() => {
    async function getProdutos() {
      try {
        const response = await axios({
          url: "grid/produto",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });
        setData(response.data);
        console.log(response)
        setIsLoading(false);
      } catch (err) {
      }
    }
      getProdutos();
  }, []);

  const handleVisualizarAtivo = (id) =>{
    const produto = data.disponiveis.find(item => item.id === id);
    if (produto) {
      nav(`/admin/produto/${produto.id}`);
    } else console.log(id)
  }

  const handleVisualizarInativo = (id) =>{
    const produto = data.indisponiveis.find(item => item.id === id);
    if (produto) {
      nav(`/admin/produto/${produto.id}`);
    } else console.log(id)
  }

  const cols =['ID', 'Nome', 'Categoría'];
  const actionsAtivo = [{ action: 'Editar', function: handleVisualizarAtivo }];
  const actionsInativo = [{ action: 'Editar', function: handleVisualizarInativo }];
return (
  <>
    {!isLoading ? (
      <div className='mt-[20px] flex flex-col gap-1'>
        <h3 className='text-[14pt]'>Painel de Produtos</h3>
        <p className='text-[9pt] -mt-2'>visualize seus produtos cadastrados.</p>
        <hr/>
        {Object.keys(data).length > 0 ? ( // Verifica se data não é vazio
          <div className='h-[400px]'>
            <GenericDataGrid rows={data.disponiveis} columns={cols} per_page={25} actions={actionsAtivo} title="Produtos Ativos"/>
            <GenericDataGrid rows={data.indisponiveis} columns={cols} per_page={25} actions={actionsInativo} title="Produtos Inativos"/>
          </div>
        ) : (
          <>Nenhum Produto Cadastrado.</>
        )}
      </div>
    ) : (
      <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}>
        <CircularProgress color="success"/>
      </Box>
    )}
  </>
);
}

export default Produtos;