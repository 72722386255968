import {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Button,
  Dialog, 
  DialogContent,
  CircularProgress,
  TextField
  } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "Admin/state/axios";

import AtualizarCliente from './PutCliente';
import { GenDel } from 'Admin/state/api';

const token = localStorage.getItem('token');
const filtrarPorString = (data, searchString) => {
  if (!searchString) {
    return data;
  }

  const lowerCaseSearch = searchString.toLowerCase();
  return data.filter((item) => 
    item.nome.toLowerCase().includes(lowerCaseSearch) ||
    item.sobrenome.toLowerCase().includes(lowerCaseSearch)
  );
};

function Clientes() {
  const [data, setData] = useState({});
  const [activeAberto, setActiveAberto] = useState(false);

  const [clientePointer, setClientePointer] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const [aviso, setAviso] = useState(false);
  const [searchString, setSearchString] = useState('');
  const clientesFiltrados = filtrarPorString(data, searchString);
  const navigate = useNavigate()
  const colunas = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.2,
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 0.8,
    },
    {
      field: "sobrenome",
      headerName: "Sobrenome",
      flex: .8,
    },
    {
      field: "Actions",
      headerName: "Ações",
      flex: 2,
      renderCell: (params) => (
        <Box width={'100%'} display={'flex'} flexDirection={'row'} gap={2} padding={2}>
        <Button onClick={() => navigate(`/admin/clientedetalhes/${params.row.id}`)}>Ver |</Button>
        <Button onClick={() => handleModalAberto(params.row.id)} >Editar Dados |</Button> 
        <Button onClick={() => handleModalAviso(params.row.id)}>Excluir</Button> 
      </Box>
      ),
    }
  ];

  useEffect(() => {
    async function getClientes() {
      try {
        const response = await axios({
          url: "cliente",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });
        setData(response.data);
        setIsLoading(false);
      } catch (err) {

      }
    }
    getClientes();
  }, []);

  useEffect(()=>{},[clientePointer]);

  const handleModalAberto = (id) => {
    setClientePointer(id)
    setActiveAberto(true);
  };
  const handleModalAviso = (id) => {
    setClientePointer(id)
    setAviso(true);
  };


  const handleCloseMenu = () => {
    setClientePointer(null);
    setActiveAberto(false);
    setAviso(false);
  };

  
  const handleDelete = async () => {
    try {
      setAviso(true);
      if (confirm) {
        await GenDel(`cliente/${clientePointer}`);
        // Faça alguma coisa com a resposta, se necessário
      }
      setAviso(false);
      setConfirm(false);
      setClientePointer(null);
    } catch (error) {

    }
  };


  return (<>{!isLoading ?<>
    <Box padding={4} display='flex' gap={1}>
      <Dialog open={activeAberto} onClose={() => handleCloseMenu()} maxWidth="xl">
          <DialogContent>
            <AtualizarCliente id={clientePointer} />
          </DialogContent>
      </Dialog>
      <Dialog open={aviso} onClose={() => handleCloseMenu()} maxWidth="xl">
          <DialogContent >
            <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} textAlign={'center'}>
            <Typography><u><b>A T E N Ç Â O: </b></u></Typography><br/>
            <Typography>voce tem certeza que quer excluir por completo os dados desse cliente?</Typography><br/>
            <Typography>note que não podemos recuperar mais esses dados caso confirme</Typography><br/>
            <Button onClick={() => {handleDelete(true)}} color='warning'>CONFIRMAR?</Button>
            </Box>
          </DialogContent>
      </Dialog>
      

      {data.length > 0 ? <>
              <Box sx={{ height: 400, width: '70vw' }}>
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Typography margin={2} variant={'h6'}>Clientes cadastrados:</Typography>
                  <TextField
                  label="Nome"
                  value={searchString}
                  size={'small'}
                  variant={'standard'}
                  onChange={(e) => setSearchString(e.target.value)}
                  />
                </Box>
                <DataGrid 
                      autoHeight
                      rows={clientesFiltrados}
                      columns={colunas}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                      }}
                      pageSizeOptions={[5]}
                      disableRowSelectionOnClick
                    />
              </Box>
            </> : (
            <>Nenhum Cliente Cadastrado.</>
          )}
    </Box>
    </> : <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}><CircularProgress color="success"/></Box>}
    
  
  
  </>);
}

export default Clientes;


