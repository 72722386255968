import React , { useState } from "react";
import { Box,
   Typography,
   TextField,
  Button,
  Alert, 
  Checkbox } from "@mui/material";
import { GenPost } from "Loja/state/api";
import InputMask from 'react-input-mask';

const NovoCliente = ( props ) => {
  const [msg, setMsg] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    nome: "",
    sobrenome: "",
    cpf:"",   
    telefone: "",
  });
  const [clienteFormData, setcliFormData] = useState({
        nome: "",
        sobrenome: "",
        cpf:"",   
        telefone: "",
        status:"Incompleto"
    });
  const setNome = (e) => {
    setcliFormData({
      ...clienteFormData,
      nome: e.target.value,
    });
    setValidationErrors({
      ...validationErrors,
      nome: e.target.value ? "" : "Campo obrigatório.",
    });
  };

  const setSobrenome = (e) => {
    setcliFormData({
      ...clienteFormData,
      sobrenome: e.target.value,
    });
    setValidationErrors({
      ...validationErrors,
      sobrenome: e.target.value ? "" : "Campo obrigatório.",
    });
  };

  const setTel = (e) => {
    setcliFormData({
      ...clienteFormData,
      telefone: e.target.value,
    });
    setValidationErrors({
      ...validationErrors,
      telefone: e.target.value ? "" : "Campo obrigatório.",
    });
  };

  const setCpf = (e) => {
    setcliFormData({
      ...clienteFormData,
      cpf: e.target.value,
    });
    setValidationErrors({
      ...validationErrors,
      cpf: e.target.value ? "" : "Campo obrigatório.",
    });
  };
  const handleRes = (res) =>{
    props.onSuccess(res);
    setTimeout(() => {
      setMsg(res.msg);
    }, 5000);
    window.location.reload()
    
  }
  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {
      nome: clienteFormData.nome ? "" : "Campo obrigatório.",
      sobrenome: clienteFormData.sobrenome ? "" : "Campo obrigatório.",
      telefone: clienteFormData.telefone ? "" : "Campo obrigatório.",
      cpf: clienteFormData.cpf? "" : "Campo obrigatório.",
    };
  
    // Update validation errors state
    setValidationErrors(validationErrors);
  
    // Check if any validation errors exist
    if (Object.values(validationErrors).some((error) => !!error)) {
      return;
    }
    
    Promise.all([
      GenPost("api/ucliente", clienteFormData)      
      .then((response) => {
        handleRes(response);
      }),
    ])
    .catch((error) => {

    });
  }


  return (
    <Box sx={{ p: 1, m: 1, width: {md:"100%"}}} display="flex"
    justifyContent="center">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        borderRadius="0.55rem"
        sx={{ p:"0.25rem 1.25rem", m: 2, width:{lg:'40vw', md:'60vw', sm:'70vw', xs:'90vw'}}}
      >
      <Box sx={{p:0}}>
        <Typography variant="h5">
            Dados do Cliente:
            <hr/>
        </Typography>
          <Box sx={{ marginTop:2,width:'100%'}}>
          
              <TextField  fullWidth 
                variant="standard"
                label="Nome :"  
                id="nome" 
                name="nome"  
                onChange={setNome} 
                error={!!validationErrors.nome}
                helperText={validationErrors.nome}
                />
              </Box>

          <Box sx={{ marginTop:2, width:'100%'}}>
            <TextField  fullWidth 
                variant="standard"
                id="sobrenome"
                label="Sobrenome :" 
                name="sobrenome" 
                onChange={setSobrenome} />
          </Box>

          <Box sx={{marginTop:2, width:'100%'}}>
            <InputMask
              mask={"(99) 9 9999-9999"}
              value={clienteFormData.telefone}
              onChange={setTel}
              disabled={false}
              maskChar=" "
            >
              {() => (
                <TextField
                  fullWidth
                  variant="standard"
                  id="telefone"
                  label="Telefone :"
                  name="telefone"
                  error={!!validationErrors.telefone}
                  helperText={validationErrors.telefone}
                />
              )}
            </InputMask>

          </Box>
          <Box sx={{marginTop:2, width:'100%'}}>
          <InputMask
              mask="999.999.999-99"
              onChange={setCpf}
              disabled={false}
              maskChar=" "
            >
              {() =>
                <TextField
                  fullWidth
                  variant="standard"
                  id="cpf"
                  label="Cpf :"
                  name="cpf" 
                  error={!!validationErrors.cpf}
                  helperText={validationErrors.cpf}
                />
              }
            </InputMask>

          </Box>
          {msg ? 
              <Box display={'flex'} flexDirection={'row-reverse'} padding={1} >
                <Alert severity="success" sx={{width:320}} > {msg.message} </Alert>
              </Box>
            : null}
          <Box sx={{m:1, marginTop:2, width:'100%'}} display='flex' flexDirection={'row-reverse'}>
            <Button variant="contained" onClick={handleSubmit} sx={{p:1,m:1}}>Cadastrar</Button>      
            </Box>
        </Box>
    </Box>
  </Box>
  );
};
export default NovoCliente;