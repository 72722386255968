import React,{useEffect, useState} from 'react'
import { Typography, Box, Grid, Button } from '@mui/material';
import { GenGet } from 'Loja/state/api';
import { styled } from '@mui/material/styles';
import Destaque from './Destaque';
import CircularProgress from '@mui/material/CircularProgress';

function Destaques() {
const [toogle, setToogle] = useState([]);
const [destaque, setDestaque] = useState([]);
const [novidade, setNovidade] = useState([]);
const [promo, setPromo] = useState([]);
const [isLoading, setIsLoading] = useState(true);
  

useEffect(() => {
  async function getFeatured() {
    try {
      const response = await GenGet('api/destaques');

      setDestaque(response.destaque);
      setNovidade(response.novidade);
      setPromo(response.promocao);
      setToogle(response.destaque);
      setIsLoading(false);
    } catch (err) {
      
    }
  }
  getFeatured();
}, []);


const CustomButton = styled(Button)({
  backgroundColor: '#0B6434', // Cor de fundo do botão
  color: 'white',
  borderRadius: 0, // Cor do texto do botão
  '&:hover': {
    backgroundColor: '#48A07A', // Cor de fundo do botão quando o mouse passa sobre ele
    color: 'white', // Cor do texto do botão quando o mouse passa sobre ele
  },
});
if (isLoading){
  return (       

    <Box sx={{ display: 'flex', width:'100%', height:'100%', justifyContent:'center' }}>
      <CircularProgress color="success" />
    </Box>

)} else {
  return (
    <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    >
        <Box    
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          paddingBottom='10px'>

            <Button onClick={() => {
                if (toogle !== novidade) {
                    setToogle([]);
                    setToogle(novidade);
                }
            }}>
                <h4 variant='h6' className='text-slate-800 hover:text-slate-700 text-[12pt]'>NOVIDADE</h4>
            </Button>
            <Button onClick={() => {
                if (toogle !== destaque) {
                    setToogle([]);
                    setToogle(destaque);
                }
            }}>
                <h4 variant='h6' className='text-slate-800 hover:text-slate-700 text-[12pt]'>DESTAQUES</h4>
            </Button>
            <Button onClick={() => {
                if (toogle !== promo) {
                    setToogle([]);
                    setToogle(promo);
                }
            }}>
                <h4 variant='h6' className='text-slate-800 hover:text-slate-700 text-[12pt]'>OFERTAS</h4>
            </Button>
        </Box>
      <br/>

<div className='flex flex-col p-4 w-[100%]'>
  <div className='flex  flex-col md:flex-row flex-wrap justify-center items-center align-middle gap-2'>
              {toogle.map((item) => (      
              <Destaque
                id={item.produto.id}
                nome={item.produto.nome}
                descricao={item.produto.descricao}
                slug={item.produto.slug}
                imagem={item.produto.imagens.find(img => img.capa)?.imagem}
              />
          ))}
  </div>
         


</div>


    </Box>
  )
}
}

export default Destaques