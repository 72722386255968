import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { GenGet } from 'Loja/state/api';
import { 
    Box, 
    Typography,
    CircularProgress,
   } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Verify() {
    const navigate = useNavigate();
    const { token } = useParams();
    const [message, setMessage] = useState('');
    const [isLoading, setisLoading ] = useState(true);

    useEffect(() => {
        async function verifyToken() {
          try {
            const response = await GenGet(`api/confirmar-conta/${token}`)
            setMessage(response.message);
            setisLoading(false);
            setTimeout(() => {
                navigate('/');
              }, 3000); 
        } catch (err) {
            setMessage('Ops, Algo deu errado!');
            setisLoading(false);
          }
        }
        verifyToken();
      }, []);
    
  return (
    <Box padding={4}>
        <Typography variant='h5'>Verificação de E-mail</Typography>
        {!isLoading 
        ? (
            <Box sx={{ p: 1, m: 1, width: {md:"100%"}, minHeight:'64vh'}} display="flex"
            justifyContent="center" flexDirection={'column'}>
                <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}>
                <Typography variant={'h6'}>
                    {message}
                </Typography>
                </Box>
            </Box>
        ) 
        : (
        <Box sx={{ p: 1, m: 1, width: {md:"100%"}, minHeight:'64vh'}} display="flex"
            justifyContent="center" flexDirection={'column'}>
            <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}>
                <CircularProgress color="success"/>
            </Box>
        </Box>) }
        
        
    </Box>
  )
}

export default Verify