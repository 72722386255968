import React,  {useState, useEffect} from "react";

const ControladorCliente = ({handleCliente, data, cliente, cliente_ok, validate, validaCliente}) =>{
  const [clienteFormData, setcliFormData] = useState({
    nome: "",
    sobrenome: "",
    cpf:"",   
    telefone: "",
    status:"Incompleto"
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      setcliFormData({
        ...clienteFormData,
        [name]: value
      });

      // Verificar se todos os campos estão preenchidos
      const allFieldsFilled = Object.values(clienteFormData).every(field => field.trim() !== "");
      validaCliente({
        ...validate,
        cliente:allFieldsFilled
      });
      console.log(clienteFormData)
    }

    useEffect(()=>{
      handleCliente({
      ...data,
      cliente:clienteFormData
      })
    },[clienteFormData])   

    useEffect(()=>{
      if(cliente_ok){
        validaCliente({
          ...validate,
          cliente:true
        });
      }
    },[cliente_ok])

    const formatarTelefone = (valor) => {
      // Remove todos os caracteres não numéricos
      const cleaned = valor.replace(/\D/g, '');
      // Aplica a máscara
      const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
      if (match) {
        return `(${match[1]})${match[2]}-${match[3]}`;
      }
      return valor;
    };
  
    return(
      <div className="p-2">
        <h3 className='text-[13pt] text-slate-800 font-semibold'>
          Seus dados
        </h3>
        {cliente_ok ? (
          <div>
            <p>Nome: {cliente.nome} {cliente.sobrenome}</p>
            <p className="m-0 p-0  text-[10pt]">CPF: {cliente.cpf}</p>
            <p className="m-0 p-0  text-[10pt]">Telefone: {cliente.telefone}</p>
          </div>
        ):(
            <div className='flex flex-col gap-2 mt-1'>
            <div className='flex flex-row gap-1 w-[100%] flex-wrap'>
              <input className={`min-w[130px] flex-grow p-1 h-[35px] bg-slate-300 rounded flex`}                 
                id="nome"
                name="nome"
                placeholder="Nome"
                value={clienteFormData.nome} 
                onChange={handleChange}  
                />
              <input className={`min-w[130px] h-[35px] flex-grow bg-slate-300 rounded flex p-1`}                 
                id="sobrenome"
                name="sobrenome"
                placeholder="Sobrenome"
                value={clienteFormData.sobrenome} 
                onChange={handleChange}  
                />
    
              </div>
            <div className='flex flex-row gap-1 w-[100%] flex-wrap'>
            <input
              className={`min-w[130px] flex-grow p-1 h-[35px] bg-slate-300 rounded flex`}
              id="cpf"
              name="cpf"
              placeholder="CPF"
              value={clienteFormData.cpf} 
              onChange={(e) => {
                // Remove all non-numeric characters
                const value = e.target.value.replace(/\D/g, '');
                
                // Call handleChange once with the modified value
                handleChange({
                  target: {
                    name: 'cpf',
                    value,
                  },
                });
              }}
            />
            <input
                    className={`min-w[130px] h-[35px] flex-grow bg-slate-300 rounded flex p-1`}
                    id="telefone"
                    name="telefone"
                    maxLength={14}
                    placeholder="(xx)xxxxx-xxxx"
                    value={formatarTelefone(clienteFormData.telefone)}
                    onChange={handleChange}
                  />
    
            </div>
            </div> 
        )}
       
      </div>)
  }

  export default ControladorCliente;