import LoginForm from 'Loja/components/compLoja/LoginForm'
import React from 'react'

function Login() {
  return (
    <div className='flex justify-center items-center w-full h-[70vh]'>
        <LoginForm/>
    </div>
  )
}

export default Login