import React, { useState, useEffect } from 'react';
import { GenGet, GenPost } from 'Admin/state/api';
import axios from 'Admin/state/axios';
import PhotoWidgetSingle from 'Admin/components/PhotoWidgetSingle';

function PainelFotos(props) {
    const produto = props.pointer
    const [fotos, setFotos] = useState(null);
    const [pointer, setPointer] = useState(null);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(true);
    const GLOBAL_URL = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem('token');
    const [scssMsg , setScssMsg] = useState(false);

    const [data, setData] = useState({
        imagem: null,
        capa:true,
        produto_id:props.pointer
      });
      

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await GenGet(`/fotos/${produto}`);
                setFotos(response);
                setPointer(response[0]);
                setRefresh(false);
                setLoading(false);

            } catch (error) {
                console.error('Error fetching data:', error);
                setRefresh(false);
                setLoading(false);
            }
        };
        if (refresh){
          fetchData();      
        }        
    }, [refresh]);

    const handlePointer = (item) =>{
        setPointer(item)
    }

    const handleDelete = (id) =>{     
        const fetchData = async () => {
            try{
                const response = await GenPost(`/del_foto/${id}`);

                setLoading(true);
                setRefresh(true)
            } catch (error) {
                console.error('Error fetching data', error);
            }
        }
        fetchData();
    }

    const handleCapa = (id) =>{
        const fetchData = async () => {
            try{
                const response = await GenPost(`/fotos/capa/${id}`);

                setLoading(true);
                setRefresh(true)
            } catch (error) {
                console.error('Error fetching data', error);
            }
        }
        fetchData();
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if(data.imagem){
            try {
            const response = await axios.post('produto/imagem', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                },
            });
                setScssMsg(response.data.message)
                setTimeout(() => {
                    setScssMsg(null);
                }, 3000);
                setRefresh(true);
                setData({
                    imagem: null,
                    capa:true,
                    produto_id:produto
                })

            } catch (error) {
                setScssMsg("Erro ao cadastrar imagem")
                setTimeout(() => {
                    setScssMsg(null);
                }, 3000);

            }
            
        } else {
            setScssMsg("Imagem não inserida.")

        }
    };
    
    const updateComponentWidth = () => {
        const width = window.innerWidth;
        const isMobile = width <= 600; 
        setComponentWidth(isMobile ? '100%' : '70%');
        setComponentDirection(isMobile ? 'col' : 'row');
        setImgCompWidth(isMobile ? '100%' : '49%');
        setMobile(isMobile ? true : false);
      };
      const [componentWidth, setComponentWidth] = useState('70%');
      const [imgCompWidth, setImgCompWidth] = useState('49%');
      const [componentDirection, setComponentDirection] = useState('col');
      const [mobile, setMobile] = useState(false);
    useEffect(() => {
        updateComponentWidth(); 
        window.addEventListener('resize', updateComponentWidth); 
        return () => {
          window.removeEventListener('resize', updateComponentWidth);
        };
      }, []);
    return (
        <div className={`w-[${componentWidth}] min-w[80vw]`}>
            <h3 className="text-[14pt]">Painel de Fotos</h3>
            <div className={`flex justify-between flex-${componentDirection} shadow-md`}>
                <div className={`flex flex-col min-w-[380px] max-h-[70vh] overflow-scroll no-scrollbar gap-2`}>
                {fotos ? (
                    fotos.map((item, index) => (
                        <div className="min-w-[300px] flex justify-between p-2 shadow-md" key={index}>
                            <img
                                src={`${GLOBAL_URL}/${item.imagem}`}
                                className="w-[100px] h-auto shadow-md rounded-sm"
                                alt={`Foto ${index}`}
                            />
                            {item.capa == true && !mobile ? ( <p className='font-semibold text-[11pt]'>foto de capa</p>):null}
                            <div className='flex flex-col gap-2'>
                            <div className='flex gap-2'>
                            {item.capa == true && mobile ? ( <p className='font-semibold text-[11pt]'>foto de capa</p>):null}
                            </div>
                            <div className='flex gap-2'>
                                <button className=' w-[100%] flex items-center justify-center p-1 text-[10pt] bg-yellow-500 hover:bg-yellow-400 text-slate-800 font-semibold rounded-md shadow-md' onClick={() => handleCapa(item.id)}>definir como capa</button>
                            </div>
                            <div className='flex gap-2'>
                                <button className=' flex items-center justify-center p-1 text-[10pt] bg-yellow-500 hover:bg-yellow-400 text-slate-800 font-semibold rounded-md shadow-md' onClick={() => handlePointer(item)}>Visuaizar</button>
                                <button className=' flex items-center justify-center p-1 text-[10pt] bg-yellow-500 hover:bg-yellow-400 text-slate-800 font-semibold rounded-md shadow-md' onClick={() => handleDelete(item.id)}>Remover</button>
                            </div>

                            </div>
                            
                        </div>
                    ))
                ) : (
                    <p>Loading...</p>
                )}
                </div>
                <div className={`w-[${imgCompWidth}] m-1 p-2`}>
                    {pointer?(<>
                    <img src={`${GLOBAL_URL}/${pointer.imagem}`} 
                        className={'w-[400px] rounded-md shadow-md'}/>
                    </>):(
                    <div className='w-[100%] bg-slate-200 flex items-center justify-center'> selecione uma imagem em visualizar</div>)}
                </div>
 
            </div>
            <div>
            {data? <PhotoWidgetSingle setData={setData} data={data}/>  :null}
            </div>
            <div className='flex w-full p-2 justify-end gap-2'> 
                <button className='bg-gray-600 hover:bg-gray-500 text-slate-100 font-semibold p-2 flex justify-center align-middle rounded-md shadow-md' onClick={props.handleCloseMenu}>fechar</button>
                <button className='bg-blue-600 hover:bg-blue-500 text-slate-100 font-semibold p-2 flex justify-center align-middle rounded-md shadow-md' onClick={handleSubmit}>cadastrar imagem</button>

            </div>

        </div>
    );
}

export default PainelFotos;