
import axios from "./axios";
import { apiPixel } from "./axios";

async function GenPost(url, data) {
  try{
    const response = await axios.post(url, data);
    return response.data
  
  } catch (err) {
    throw err;
  }
}
async function GenDel(url) {
  try{
    const response = await axios.delete(url);
    return response.data
  
  } catch (err) {
    throw err;
  }
}

async function GenPostTemp(url, data, token) {
  try {
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': `Bearer ${token}`,
      },
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}

async function GenGetTemp(url, token) {
  try {
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': `Bearer ${token}`,
      },
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}

async function GenPostLogin(url, data, token) {
    try {
      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (err) {

    }
  }

async function GenPut(url, data) {
  try{
    const response = await axios.put(url, data);
    return response.data
  
  } catch (err) {

  }
}
async function GenGet(url) {
  try{
    const response = await axios.get(url);
    return response.data
  
  } catch (err) {

  }
}
async function PixPost(url) {
  try{
    const response = await apiPixel.post(url);
    return response.data
  
  } catch (err) {

  }
}

async function GetMessages() {
  try {
    const response = await GenGet(`api/umensagens`);
    return response;

  } catch (message) {

  }
}

export {
  GenGetTemp,
  GenPostLogin,
  GenPostTemp,
  GetMessages,
  PixPost,
  GenPut,
  GenPost,
  GenGet,
  GenDel
  }