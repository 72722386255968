import React from 'react'
import AtualizarArtigo from 'Admin/scenes/artigos/PutArtigo';

export const handleAdminArticle = ({dataset, setState,handleDelete }) => {
    function handleCopy(data) {
      return (
        <div className='bg-gray-200 rounded-sm p-1 w-[100%] relative'>
          <div className='flex flex-roe gap-1 justify-end absolute right-0 top-0'>
          <span className='bg-red-400 px-1 rounded-sm shadow-md cursor-pointer text-[10pt] hover:bg-red-300 font-medium' onClick={() => handleDelete(data.id)}>remover</span>
          <span className='bg-yellow-400 px-1 rounded-sm shadow-md cursor-pointer text-[10pt] hover:bg-yellow-300 font-medium' onClick={() => setState({ component: AtualizarArtigo, pointer: data.id })}>editar</span>
          </div>
        
          <p className='font-semibold text-[10pt] p-2'>{data.conteudo}</p>
        </div>
      );
    }
  
    function handleEspecs(data) {
      const formatItens = data.itens.replace(/\[|\]|'/g, '');
      const itensArray = formatItens.split(', ');
      return (
        <div className='bg-gray-200 rounded-sm p-1 w-[100%] relative'>
          <div className='flex flex-roe gap-1 justify-end absolute right-0 top-0'>
          <span className='bg-red-400 px-1 rounded-sm shadow-md cursor-pointer text-[10pt] hover:bg-red-300 font-medium' onClick={() => handleDelete(data.id)}>remover</span>
          <span className='bg-yellow-400 px-1 rounded-sm shadow-md cursor-pointer text-[10pt] hover:bg-yellow-300 font-medium' onClick={() => setState({ component: AtualizarArtigo, pointer: data.id })}>editar</span>
          </div>
          <h4 className='font-semibold text-[14pt]'>Detalhes do produto:</h4>
          <p className='font-semibold text-[10pt] p-2'>{data.conteudo}</p>
          <div className='p-2 font-semibold text-[10pt]'>
            {data.itens
              ? itensArray.map((item, index) => <p key={index}> - {item}</p>)
              : <></>}
          </div>
        </div>
      );
    }
  
    function handleText(data) {
      return (
        <div className='bg-gray-200 rounded-sm p-1 w-[100%] relative'>
          <div className='flex flex-roe gap-1 justify-end absolute right-0 top-0'>
          <span className='bg-red-400 px-1 rounded-sm shadow-md cursor-pointer text-[10pt] hover:bg-red-300 font-medium' onClick={() => handleDelete(data.id)}>remover</span>
          <span className='bg-yellow-400 px-1 rounded-sm shadow-md cursor-pointer text-[10pt] hover:bg-yellow-300 font-medium' onClick={() => setState({ component: AtualizarArtigo, pointer: data.id })}>editar</span>
          </div>
          <h4 className='font-semibold text-[14pt]'>{data.titulo}</h4>
          <p className='font-semibold text-[10pt] p-2'>{data.conteudo}</p>
        </div>
      );
    }

    const functionsMap = {
      'descrição para venda': handleCopy,
      'especificações': handleEspecs,
      'texto livre': handleText,
    };
  
    return functionsMap[dataset.tipo](dataset);
  };