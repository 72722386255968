import axios, { formMulti } from "./axios";

async function GenPost(url, data) {
  try{
    const response = await axios.post(url, data);
    return response.data
  
  } catch (err) {

  }
}

async function GenPostMultiform(url, data){
  try{
    const response = await formMulti.post(url, data);
    return response;
  } catch(err) {

  }
}
async function GenPut(url, data) {
  try{
    const response = await axios.put(url, data);
    return response.data
  
  } catch (err) {
  }
}

async function GenGet(url) {
  try{
    const response = await axios.get(url);
    return response.data
  
  } catch (err) {
    return err
  }
}

async function GenGetData(url, data) {
  try{
    const response = await axios.get(url, data);
    return response.data
  
  } catch (err) {
    return err
  }
}

async function GenDel(url) {
  try{
    const response = await axios.delete(url);
    return response.data
  
  } catch (err) {

  }
}

async function GetAdmin() {
  try {
    const response = await GenGet('/admindash');
    return response;

  } catch (err) {
  }
}

async function GetVendas() {
  try {
    const response = await GenGet('/ordem');
    return response;

  } catch (err) {
  }
}

async function GetEstoqueProd(id) {
  try {
    const response = await GenGet(`mov-produto/${id}`);
    return response;

  } catch (err) {
  }
}

async function PostMov(data) {
  try {
    const response = await GenPost(`mov-estoque`, data);
    return response;

  } catch (err) {
  }
}

async function GetAvisos() {
  try {
    const response = await GenGet(`uavisos`);
    return response;

  } catch (message) {
  }
}

async function GetMessages(user_id, remetente) {
  try {
    const response = await GenGet(`mensagens/${user_id}/${remetente}`);
    return response;

  } catch (message) {
  }
}

async function GetUsers() {
  try {
    const response = await GenGet('usuarios');
    return response;

  } catch (message) {
  }
}

async function GetOrdem(id) {
  try {
    const response = await GenGet(`ordem/${id}`);
    return response;

  } catch (message) {
  }
}

async function GetPagamento(id) {
  try {
    const response = await GenGet(`pagamento-aberto/${id}`);
    return response;

  } catch (message) {
  }
}

async function GetVendasGrid() {
  try {
    const response = await GenGet('/pagamento');
    return response;

  } catch (message) {
  }
}

async function GetVendasSalesGrid() {
  try {
    const response = await GenGet('/sales/vendasgrid');
    return response;

  } catch (message) {
  }
}

async function GetVendasSales() {
  try {
    const response = await GenGet('/sales/vendas');
    return response;

  } catch (message) {
  }
}



async function GetSalesDash() {
  try {
    const response = await GenGet('/salesdash');
    return response;

  } catch (err) {
  }
}

export {
  GetPagamento,
  GetVendasGrid,
  GetOrdem,
  GenPut,
  GenPost,
  GenGet,
  GenDel,
  GetVendas,
  GetAdmin,
  GetAvisos,
  GetMessages,
  GetUsers,
  GetEstoqueProd,
  PostMov,
  GenGetData,
  GetSalesDash,
  GetVendasSales,
  GetVendasSalesGrid,
  GenPostMultiform
  }

