import {useEffect, useState} from 'react';
import axios from "Admin/state/axios";
import { 
  Box, 
  Typography,
  TextField,
  Grid,
  Alert,
  Button

  } from "@mui/material";

function PainelMercadoPago() {
  const [scssMsg , setScssMsg] = useState(false);
  const [formData, setFormData] = useState({
    application: "mercadopago",
    token:"",
    expires:"2592000",
    public_token:""
  });

  const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      }

    const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('tokens/create', formData);
      setScssMsg(response.data.message)
      setTimeout(() => {
        setScssMsg(null);
      }, 3000);
    } catch (error) {
      setScssMsg(error.message)
      setTimeout(() => {
        setScssMsg(null);
      }, 3000);
    }
  };
  return (
    <div className='flex flex-col gap-1 p-2 w-full text-[10pt]'>
      <h3 className='text-[14pt]'>Painel melhor envio</h3>
      <p className='text-[9pt] -mt-2'>painel de integração melhor envio.</p>
      <hr/>
      <div className='flex flex-wrap justify-between gap-2'>
          <div item xs={12} sx={{backgroundColor:"white", margin:"4px", flexGrow: 1}}>
            <p className='p-0 m-0'> Esse é o Painel do Mercado Pago, para obter seu token é necessário ir ate a plataforma do mercado pago.
            <br/>Faça seu login com suas credenciais e vá até <b>APLICATIVOS - AUTORIZAÇOES - TOKEN</b> , 
            <br/>crie um token e copie o conteudo gerado e cole aqui no campo <b>TOKEN</b>  </p>
          </div>
          <div className='flex flex-col justify-around gap-1 mt-6'>   
          <p><b>Insira seu token do Melhor Envio</b></p>

        <div className='flex flex-col gap-1'>
        <TextField
        sx={{minWidth:300}}
            fullWidth
            variant="outlined"
            size="small"
            label="token:"
            id="token"
            name="token"
            onChange={handleChange}
            />
        <TextField
        sx={{minWidth:300}}
            fullWidth
            variant="outlined"
            size="small"
            label="token público:"
            id="public_token"
            name="public_token"
            onChange={handleChange}
            />
            <Box display={'flex'} flexDirection={'row-reverse'}>
            <button className='bg-blue-400 hover:bg-blue-500 admin-button mt-2' onClick={handleSubmit}>Confirmar</button>
            </Box>
            {scssMsg ? 
              <Box display={'flex'} flexDirection={'row-reverse'} padding={1} >
                <Alert severity="info" sx={{width:320}} > {scssMsg} </Alert>
              </Box>
            : null}     
        </div>

        </div>

        </div>
      
  </div>
  )
}

export default PainelMercadoPago