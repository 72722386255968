import { GenGet } from 'Admin/state/api';
import React, {useEffect, useState} from 'react';
import MarketingProdutos from './MarketingProdutos'
import { CircularProgress } from '@mui/material';
import GenericDataGrid from 'Admin/components/DataGrids/GenericDataGrid';

function MarketingGeral() {
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      async function getData() {
        try {
          const response = await GenGet(`/marketing/geral`);
          console.log(response)
          setData(response);
          setIsLoading(false);
        } catch (err) {
          console.log(err)
        }
      }
      getData();
    }, []);

    const columns = ['ID', 'Página', 'Acessos'];

  return (
    <div className='flex flex-col w-full text-[10pt] p-2'>
      <h3 className='text-[14pt]'>Painel de metricas Gerais</h3>
      <p className='text-[9pt] -mt-2'>visualize as metricas gerais de sua loja</p>
      <hr/>

        {!isLoading ? (
          <div className='flex flex-col w-full p-4 gap-1'>
            <div className='flex flex-col p-2 text-[18pt] font-semibold text-gray-800'>
                <p className='text-[12pt] p-0 m-0'>Total de acessos</p>
                <p className='p-0 m-0'>{data.total_acessos_global}</p>
            </div>
            <p className='text-[11pt] p-0 m-0 mb-2  font-semibold text-gray-800'>Mais Visualizadas:</p>
            <div className='w-full flex flex-row gap-1 flex-wrap mb-2'>
                {data.top_paginas.map(item =>(
                    <div className='flex flex-col p-2 justify-center align-middle items-center text-[18pt] font-semibold text-gray-800 rounded-md border-[1px] border-gray-600 shadow-md'>
                        <p className='text-[12pt] p-0 m-0'>pagina: {item.pagina}</p>
                        <p className='p-0 m-0'>{item.total_acessos}</p>
                    </div>
                ))}
            </div>
            <hr/>
            <GenericDataGrid rows={data.paginas} columns={columns} per_page={10} title="Acesso por Página" />
            
            <hr/>
            <MarketingProdutos/>
          </div>
        ) : (
          <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
            <CircularProgress color="success"/>
          </div>
        )}
    </div>

  )
}

export default MarketingGeral