import React, { useState, useEffect } from "react";
import { initMercadoPago } from '@mercadopago/sdk-react';
import { Payment } from '@mercadopago/sdk-react';
import { GenPost } from "Loja/state/api";
import {
  Grid,
  Box
} from "@mui/material";

const PUBLIC_KEY = 'TEST-65eaeb4a-3be0-4225-a53e-4a701a9569c3';

function Pagamento() {
  const initialization = {
    amount: 0,
  };

  const customization = {
    paymentMethods: {
      ticket: "all",
      bankTransfer: "all",
      creditCard: "all",
      debitCard: "all",
      mercadoPago: "all",
    },
  };

  const onSubmit = async (
    { selectedPaymentMethod, formData }
  ) => {
    return new Promise((resolve, reject) => {
      GenPost("api/process_payment", JSON.stringify(formData))
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const onError = async (error) => {
    
  };

  useEffect(() => {
    initMercadoPago(PUBLIC_KEY);
  }, []);

  return (
        <Grid item xs={12} sm={8} md={6}>
          <Payment
            initialization={initialization}
            customization={customization}
            onSubmit={onSubmit}
          />
        </Grid>
  );
}

export default Pagamento;
