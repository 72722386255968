import React, { useEffect, useState } from 'react';
import { GenGet } from 'Loja/state/api';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import Oferta from './Oferta';
import bgOferta from '../../assets/bg-ofertas.png';
import OfertaCarousel from './OfertaCarrousel';

function Ofertas() {
  const [ofertas, setOfertas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getOfertas() {
      try {
        const response = await GenGet('api/ofertas');
        setOfertas(response);
        setIsLoading(false);
      } catch (err) {
        // Tratar erro
        setIsLoading(false);
      }
    }
    getOfertas();
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', width: '100%', height: '70vh', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }
    return (<>
      {ofertas && ofertas.length > 0 ? (
              <div className='flex justify-center align-middle items-center p-4 h-[100%]' style={{
                backgroundImage: `url(${bgOferta})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
              }}>
                <div className='flex overflow-hidden w-full rounded-md shadow-lg relative'>
                  <OfertaCarousel>
                    {ofertas.map((item) => (
                      <div className={'flex align-middle items-center justify-center w-full gap-2'} key={item.id}>
                        <Oferta item={item} />
                      </div>
                    ))}
                  </OfertaCarousel>
                </div>
              </div>
      ) : null}
      </>

    );
}

export default Ofertas;