import React from "react";
import { useNavigate } from "react-router-dom";
import {Box, Typography, Button} from '@mui/material';

function PedidoFinalizado() {
  const navigate = useNavigate();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      flexDirection={'column'}
      height={600}
    >
      <Typography variant={'h4'}>Agradecemos por sua Compra!</Typography>
      <Typography variant={'body1'}>Seu pedido está sendo preparado para ser enviado!</Typography>
      <br/>
      <Button onClick={() => navigate(`/`)}>Voltar a pagina inicial</Button>
    </Box>
  );
}

export default PedidoFinalizado;