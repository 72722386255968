import React, { useRef, useState, useEffect } from 'react';

function PhotoWidgetSingle(props) {
  const { data, setData, title } = props;
  const inputRef = useRef();

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile && droppedFile.type.startsWith('image/')) {
      setData({ ...data, imagem: droppedFile });
    }
  };

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      setData({ ...data, imagem: selectedFile });
    }
  };

  const handleRadioChange = (e) => {
    const value = e.target.value === 'true';
    setData({ ...data, capa: value });
  };

  const removeImage = () => {
    setData({ imagem: null });
  };

  // Check if inputRef.current is not null before calling click()
  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
  const updateComponentWidth = () => {
    const width = window.innerWidth;
    const isMobile = width <= 600; // Limite para considerar dispositivos móveis
    setComponentWidth(isMobile ? '100%' : '70%');
    setComponentDirection(isMobile ? 'col' : 'row');
    setImgCompWidth(isMobile ? '100%' : '49%');
    setMobile(isMobile ? true : false);
  };
  const [componentWidth, setComponentWidth] = useState('70%');
  const [imgCompWidth, setImgCompWidth] = useState('49%');
  const [componentDirection, setComponentDirection] = useState('col');
  const [mobile, setMobile] = useState(false);
  useEffect(() => {
      updateComponentWidth(); // Atualiza a largura inicialmente
      window.addEventListener('resize', updateComponentWidth); // Adiciona o evento de redimensionamento
      return () => {
        window.removeEventListener('resize', updateComponentWidth); // Remove o evento ao desmontar o componente
      };
    }, []);

  return (
    <div className='p-2'>
    <h3 className="text-[12pt]">{!title ? ('Nova imagem: ') : title  }</h3>
    <div className={`flex flex-${componentDirection} justify-center items-center align-middle`}>
      
      <div
        className={`h-[200px] w-[${imgCompWidth}] m-1 flex flex-col bg-slate-300 rounded-lg p-4 justify-center items-center cursor-pointer relative`}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={handleClick}
      >
        {data.imagem ? (
          <>
            <span
              className='absolute bg-slate-500 rounded-full pl-2 pr-2 -pt-1 text-white -right-2 -top-1 cursor-pointer opacity-80 flex justify-center items-center'
              onClick={removeImage}
            >
              x
            </span>
            <img src={URL.createObjectURL(data.imagem)} alt='Uploaded' className='h-full rounded-md' />
          </>
        ) : (
          <>
            <p className="text-center">Arraste e solte uma foto aqui ou clique</p>
            <p className="text-center">para adicionar uma imagem</p>
            <input
              type='file'
              onChange={handleFileSelect}
              accept='image/*'
              hidden
              ref={inputRef}
            />
          </>
        )}
      </div>
      {!props.semcapa && (
        <div className={`flex flex-col m-1 justify-center align-middle items-center w-[${imgCompWidth}]`}>
        <p className='font-semibold'>Tornar essa imagem capa?</p>
        <div className='flex'>
          <label className="inline-flex items-center">
          <input
            type="radio"
            value={true}
            checked={data.capa === true}
            onChange={handleRadioChange}
            className="form-radio h-5 w-5 text-green-500 checked:bg-green-600"
          />
          <span className="ml-2 text-gray-700">Sim</span>
        </label>
        <label className="inline-flex items-center ml-6">
          <input
            type="radio"
            value={false}
            checked={data.capa === false}
            onChange={handleRadioChange}
            className="form-radio h-5 w-5 text-red-500 checked:bg-red-600"
          />
          <span className="ml-2 text-gray-700">Não</span>
        </label>
        </div>
        
      </div>
      )}

    </div>
    
    </div>
  );
}

export default PhotoWidgetSingle;