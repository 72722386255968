import React , { useEffect, useState } from "react";
import axios from "Admin/state/axios";
import { Box,
    Typography,
    Button,
    Alert,
    CircularProgress, 
    FormControl } from "@mui/material";
import NovaOferta from "./NovaOferta";

const NovoDestaque = () => {
    const [msg, setMsg] = useState(null);
    const [produtos, setProdutos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);
    
    const handleMsg = (e) =>{
        setMsg(e)
        setTimeout(() => {
            setMsg(null);
        }, 5000);
    }

      const [formData, setFormData] = useState({
        produto: 1,
        tipo: "destaques",
      });

      const tipos = (['destaques','promocao','novidades']);

        const handleId = (e) => {
            setFormData((prevData) => ({
              ...prevData,
              produto: e.target.value,
            }));

          };

          const handleTipos = (e) => {
            setFormData((prevData) => ({
              ...prevData,
              tipo: e.target.value,
            }));
          };

      const handleSubmit = (e) => {
        e.preventDefault();
        axios.post("featured", formData)
          .then((response) => {
            handleMsg(response.data.message);
            setRefresh(false);
          })
          .catch((error) => {
            handleMsg(error.data.message);
          });
      };   
      useEffect(() => {
        async function getProdutos() {
          try {
            const response = await axios({
              url: "uprodutos",
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
              },
              withCredentials: true,
            });

            setProdutos(response.data);

            setIsLoading(false);
            setRefresh(false);
          } catch (err) {
            setRefresh(false);
          }
        }
        getProdutos();
      }, [refresh]);
      
  if (isLoading){
    return (<Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}><CircularProgress color="success"/></Box>)
  }   
  else 
  return (
    <div className='text-[10pt]'>
        <h3 className='text-[12pt]'>
          Novo Destaque     
        </h3><hr/>
        <br/>
        <Box display='flex' flexDirection="column" sx={{p:0,m:1}} >

            <FormControl variant="standard">
            <Typography>Produto:</Typography>
            <select className="form-select" aria-label="tipo" 
            labelId="produto"
            id="produto"
            name="produto"
            value={formData.produto}
            onChange={handleId}
            >
            {produtos ? (
                produtos.map(produto => (
                  <option value={produto.id} key={produto.id}>{produto.nome}</option>
              ))
            ) : null}
            </select>
            </FormControl>
            </Box>

            <Box display='flex' flexDirection="column" sx={{p:0,m:1}}  maxWidth={'140px'}>
            <FormControl variant="standard">
            <Typography>Tipo:</Typography>
            <select className="form-select" aria-label="tipo" 
              labelId="tipo"
              id="tipo"
              value={formData.tipo}
              onChange={handleTipos}
            >
              {tipos ? (
                tipos.map((value) => (
                  <option value={value} key={value}>{value}</option>
                ))
              ) : null}
            </select>

          </FormControl>
            </Box>
            <Box sx={{marginTop:5}} display={'flex'} flexDirection={'row-reverse'}>
                <button onClick={handleSubmit} className={'bg-blue-400 hover:bg-blue-500 admin-button'}>Cadastrar</button>
            </Box>
            {msg ? 
            <Box display={'flex'} flexDirection={'row-reverse'} padding={1} >
                <Alert severity="info" sx={{width:320}} > {msg} </Alert>
            </Box>
            : null}
            <NovaOferta produtos={produtos}/>

    </div>
  )
}

export default NovoDestaque