import React, { useState, useEffect } from 'react'
import{
  Button,
  TextField,
  Grid,
  CircularProgress,
  Box,
  Typography,
  Badge
  } from "@mui/material";
  import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { Card } from 'react-bootstrap';
import { GetMessages, GetUsers, GenPost, GenGet } from 'Admin/state/api';

const user_id = localStorage.getItem('id');

function Mensagens() {
  const [active, setActive] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingMsg, setLoadingMsg] = useState(false);
  const [msgData, setMsgData] = useState([]);
  const [destinatario, setDest] = useState();
  const [formData, setFormData] = useState({
    mensagem: "",
    status: "enviada",
    origem: user_id,
    destino: "",
  });

  const setMensagem = (e) => {
    setFormData({
      ...formData,
      mensagem: e.target.value,
      destino: destinatario
    });
  };


      async function getMsgs(user_id, remetente) {
        try {
          setActive(true);
          setLoadingMsg(true);
          setDest(remetente);
          const response = await GetMessages(user_id, remetente);
          setMsgData(response.mensagens);
          setLoadingMsg(false);

          await GenGet(`msglida/${user_id}/${remetente}`);
        } catch (err) {

        }
      }

    useEffect(() => {
      async function getUsers() {
        try {
          const response = await GetUsers();
          setData(response);
          setIsLoading(false);
        } catch (err) {

        }
      }
      getUsers();
    }, []);
  
    const handleSubmit = () => {
      GenPost("/novamsg", formData)
        .then(() => {
          getMsgs(user_id, destinatario);
        })
        .catch((error) => {

        });
    };

  return (
    <Box className='container' sx={{padding:1}}>
          {!isLoading ? (
          <Grid container spacing={2}>
          <Grid item md={3} xs={12}>
              <Card className='shadow'>
                <Box sx={{padding:1}}  maxHeight="70vh" overflow={'auto'}>
                  {data.map((item) => {
                    if (item.id == user_id) {
                      return null; // Pula a renderização do item
                    }
                    return (
                      <Box  display={'flex'} flexDirection='row' sx={{marginTop:.4, backgroundColor:"#DCDCDC", padding:1}} alignItems="center" key={item.id} 
                      onClick={() => {
                        const newData = data.map((i) => {
                          if (i.id === item.id) {
                            return {...i, mensagens_count: 0};
                          }
                          return i;
                        });
                        setData(newData);
                        getMsgs(user_id, item.id);
                        
                      }}>
                        {item.mensagens_count > 0 ?
                          <Badge badgeContent={item.mensagens_count} color="error">
                            <AccountCircleIcon />
                          </Badge>
                          : <AccountCircleIcon />
                        }
                        <Typography sx={{ ml: 3, fontSize:'14pt'}}>{item.name}</Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Card>
            </Grid>
            <Grid item md={9} xs={12}>
              <Card className='shadow'>
                <Box sx={{padding:1}}  height="70vh">
                  <Typography variant='h6'>Converssa</Typography>
                  <hr/>
                  <Box
                      display={'flex'}
                      height="73%"
                      flexDirection='column'
                      alignItems='flex-start'
                      sx={{
                        marginTop: 'auto',
                        marginLeft: 1,
                        marginBottom: 2,
                        maxHeight: '73%',
                        overflowY: 'auto'
                      }}
                    >
                      
                        {active && loadingMsg ? (
                          <Grid item xs={12}>
                            <Typography variant='body1' sx={{fontSize:'10pt'}}>
                              carregando mensagens.
                            </Typography>
                          </Grid>
                        ) : active && !loadingMsg ? (
                          <>
                            {msgData.length > 0 ? (
                              <>
                              {msgData.map(({id, origem, mensagem, data}) => (

                                    <Grid container spacing={1} display='flex' flexDirection={'row'} sx={{ backgroundColor:"#E6E6FA", borderRadius: 3, padding:1, marginTop:1 }} key={id}> 
                                      <Grid item xs={12}>
                                          <Typography variant='body1' sx={{fontSize:'10pt'}}>
                                              {data}
                                          </Typography>
                                      </Grid>
                                      <Grid item xs={12}>
                                          <Grid container spacing={0.2} display='flex' flexDirection={'row'} > 
                                              <Grid item xs={2.2} >
                                                  <Typography variant='body1' sx={{fontSize:'10pt'}}>
                                                      <b>{origem} :</b>
                                                  </Typography>
                                              </Grid>
                                              <Grid item xs={9.8}>
                                                  <Typography variant='body2' >
                                                      {mensagem}
                                                  </Typography>
                                              </Grid>
                                          </Grid>
                                      </Grid>
                                  </Grid>
                              ))}
                              </>
                            ) : (
                              <Grid item xs={12}>
                                <Typography variant='body1' sx={{fontSize:'10pt'}}>
                                  nenhuma mensagem.
                                </Typography>
                              </Grid>
                            )}
                          </>
                        ) : (
                          <Grid item xs={12}>
                            <Typography variant='body1' sx={{fontSize:'10pt'}}>
                              abra uma caixa de mensagem.
                            </Typography>
                          </Grid>
                        )}

                    </Box>
                  <Box                     
                    display={'flex'}
                    marginTop='5'
                    flexDirection='column'
                    alignItems='flex-end'
                    sx={{ marginLeft: 1}}>
                      <Box display={'flex'} flexDirection={"row"} width="100%">
                      <TextField
                        hiddenLabel
                        id="filled-hidden-label-small"
                        label="sua mensagem:"
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={setMensagem} 
                      />
                      <Button  onClick={handleSubmit}>enviar</Button>
                      </Box> 

                    </Box>
                </Box>          
              </Card>
            </Grid>

              
        </Grid>) : <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}><CircularProgress color="success"/></Box>}
        </Box>
  )
}

export default Mensagens