import React , { useEffect, useState } from "react";
import TiposEstoque from './TiposEstoque'
import PainelProdutoEstoqueComposto from "./PainelProdutoEstoque";


function PainelEstoqueProduto() {
    const [refresh, setRefresh] = useState(false);

  return (
    <div className='w-full flex flex-col gap-2 '>
        <TiposEstoque handleRefresh={setRefresh}/>
    </div>
  )
}

export default PainelEstoqueProduto