import React, {useState, useEffect} from 'react';
import axios from "Admin/state/axios";
import { Box,
   Typography,
   TextField,
   Grid,
   Button,
   FormControl, 
   Alert
   } from "@mui/material";
   const token = localStorage.getItem('token');

  const NovoArtigo = (props) => {
  const produto = props.pointer
  const [scssMsg , setScssMsg] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const tipos =['descrição para venda','especificações','texto livre'];
  const [tipo, setTipo] = useState('descrição para venda');
  const [validationErrors, setValidationErrors] = useState({
    titulo: "",
    subtitulo: "",
    conteudo:"",
    itens:[],
    tipo:"",
    produto_id: produto,
  });
  const [formData, setFormData] = useState({
    titulo: "",
    subtitulo: "",
    conteudo:"",
    itens:[],
    tipo:"descrição para venda",
    produto_id: produto,
  });

  const novoArt = {
    titulo: "",
    subtitulo: "",
    conteudo:"",
    itens:[],
    tipo:"",
    produto_id: produto,
  };

  useEffect(() => {
    if(refresh){
      setFormData(novoArt)
      setRefresh(false)
    }

  }, [refresh]);

  const handleSelect = (e) =>{
    setTipo(e.target.value)    
    setFormData({
      titulo: "",
      subtitulo: "",
      conteudo:"",
      itens:[],
      tipo: e.target.value,
      produto_id: produto,
    });
  }

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    if (name === 'itens') {
      const updatedItens = [...formData.itens];
      updatedItens[index] = value;
      setFormData(prevState => ({
        ...prevState,
        itens: updatedItens
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };
  const handleAddItem = () => {
    setFormData(prevState => ({
      ...prevState,
      itens: [...prevState.itens, ''] // Adiciona um novo item vazio
    }));
  };

  const handleRemoveItem = (index) => {
    const updatedItens = [...formData.itens];
    updatedItens.splice(index, 1); // Remove o item pelo índice
    setFormData(prevState => ({
      ...prevState,
      itens: updatedItens
    }));
  };  
  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationErrors = {
      conteudo: formData.conteudo ? "" : "Campo obrigatório.",
      tipo: formData.tipo ? "" : "Campo obrigatório.",
    };
  
    // Update validation errors state
    setValidationErrors(validationErrors);
  
    // Check if any validation errors exist
    if (Object.values(validationErrors).some((error) => !!error)) {
      return;
    }
  
    try {
      const response = await axios.post('artigos', formData, {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'Authorization': `Bearer ${token}`,
        },
      });
      setScssMsg(response.data.message)
      setRefresh(true)
      setTimeout(() => {
        setScssMsg(null);
      }, 3000);

    } catch (error) {
      setScssMsg(error.data.message)
      setTimeout(() => {
        setScssMsg(null);
      }, 3000);
      
    }
  };

  return <>
      <div className='w-full p-1 min-w-[400px]'>
        <h3 className='text-[14pt]'>Novo Artigo</h3> 
      <div className='flex flex-col gap-1 justify-between'>
        <div className='w-full m-[4px] flex-grow'>
        {scssMsg ? 
          <Box display={'flex'} flexDirection={'row-reverse'} padding={1} >
            <Alert severity="info" sx={{width:320}} > {scssMsg} </Alert>
          </Box>
        : null}

          <div className='flex flex-col gap-1 w-full p-0 m-1'>
          <Typography>Tipo:</Typography>
          <FormControl fullWidth variant="standard">
            <select
              className="form-select"
              aria-label="categoria"
              onChange={handleSelect}
              id="categoria_id"
              name="categoria_id"
              value={tipo}
            >
              {tipos ? tipos.map((id) => (
                <option value={id} key={id}>{id}</option>
              )) : <></>}
            </select>
          </FormControl>
          {tipo === 'descrição para venda'? (
            <>
                    <Box display='flex' flexDirection="column" sx={{p:0,m:1 }}>   
                        <TextField  
                        fullWidth 
                          multiline
                          variant="standard"
                          rows={3}
                          label='Conteúdo :'
                          id="conteudo" 
                          name="conteudo" 
                          value = {formData.conteudo}
                          onChange={handleChange}
                          error={!!validationErrors.conteudo}
                          helperText={validationErrors.conteudo}  
                          />
                    </Box>
            </>
          ):null}
          {tipo === 'texto livre'? (
            <>
                    <Box display='flex' flexDirection="column" sx={{p:0,m:1 }}>   
                        <TextField  
                        fullWidth 
                          variant="standard"
                          label='Título :'
                          id="titulo" 
                          name="titulo" 
                          value = {formData.titulo}
                          onChange={handleChange}
                          error={!!validationErrors.titulo}
                          helperText={validationErrors.titulo}  
                          />
                    </Box>
                    <Box display='flex' flexDirection="column" sx={{p:0,m:1 }}>   
                        <TextField  
                        fullWidth 
                          multiline
                          variant="standard"
                          rows={3}
                          label='Conteúdo :'
                          id="conteudo" 
                          name="conteudo" 
                          value = {formData.conteudo}
                          onChange={handleChange}
                          error={!!validationErrors.conteudo}
                          helperText={validationErrors.conteudo}  
                          />
                    </Box>

            </>
          ):null}
          {tipo === 'especificações'? (
            <>
                    <Box display='flex' flexDirection="column" sx={{p:0,m:1 }}>   
                        <TextField  
                        fullWidth 
                          multiline
                          variant="standard"
                          rows={3}
                          label='Conteúdo :'
                          id="conteudo" 
                          name="conteudo" 
                          value = {formData.conteudo}
                          onChange={handleChange}
                          error={!!validationErrors.conteudo}
                          helperText={validationErrors.conteudo}  
                          />
                    </Box>
                    <Typography variant="h6">Itens:</Typography>
                    {formData.itens.map((item, index) => (
                      <Box key={index} display="flex" flexDirection="row" alignItems="center">
                        <TextField
                          fullWidth
                          variant="standard"
                          label={`Item ${index + 1}`}
                          id={`item-${index}`}
                          name="itens"
                          value={item}
                          onChange={(e) => handleChange(e, index)}
                        />
                        <Button onClick={() => handleRemoveItem(index)}>Remover</Button>
                      </Box>
                    ))}
                    <Button onClick={handleAddItem}>Adicionar Item</Button>
            </>
          ):null}            
          </div>
          <div className='w-full flex gap-1 justify-end p-1 pt-2 mb-2'
          >
          <button className='bg-gray-500 hover:bg-gray-400 p-2 rounded-sm' onClick={props.handleCloseMenu}>Fechar</button>  
          <button className='bg-blue-500 hover:bg-blue-400 p-2 rounded-sm' onClick={handleSubmit}>Publicar</button>
          </div>
          </div>
        </div>

        </div> 
   </>
}

export default NovoArtigo;