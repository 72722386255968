import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Box, Typography , Stack} from "@mui/material";
import FreteRapido from '../../assets/FreteRapido.png';
import FreteBrasil from '../../assets/FreteBrasil.png';
import Pagamento from '../../assets/PagamentoSeguro.png';

const useStyles = makeStyles({
    icon: {
      "&:hover": {
        width:'100px'
      }
    }
  });

function Selos() {
    const classes = useStyles();
  return (
    <Stack direction="row" spacing={2} justifyContent="center" mt={4}>
        <Box 
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="40vh"
        sx={{ height:200, width:200}}>
            <div className={'flex flex-col justify-center align-middle items-center'}>
            <img src={FreteRapido} width='80px' className={classes.icon} alt={'Entrega Logo'}/>
                
                <Typography variant="h6" textAlign="center">
                    Entrega Rápida
                </Typography>
            </div>
        </Box>
        <Box 
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="40vh"
            gap="2"
            sx={{ height:200, width:200}}>
            <div className={'flex flex-col justify-center align-middle items-center'}>
            <img src={FreteBrasil} width='80px' className={classes.icon} alt={'Ampla Entrega Logo'}/>
                
                <Typography variant="h6" textAlign="center">
                    Envio Para todo o Brasil
                </Typography> 
            </div>    
        </Box>
        <Box 
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="40vh"
            sx={{ height:200, width:200}} >
                
            <div className={'flex flex-col justify-center align-middle items-center'}>
            <img src={Pagamento} width='80px' className={classes.icon} alt={'Pagamento Seguro Logo'}/>
                <Typography variant="h6" textAlign="center">
                Pagamento Seguro
                </Typography>
            </div>
        </Box>
    </Stack>
  )
}

export default Selos