import React, {useEffect, useState} from 'react';
import { Box, Typography, Grid } from "@mui/material";

import { GenGet } from 'Loja/state/api';
import CircularProgress from '@mui/material/CircularProgress';
import Artigo from 'Loja/components/compLoja/Artigo';

function Artigos() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  

  useEffect(() => {
    async function getArtigos() {
      try {
        const response = await GenGet('api/uartigos');
        setData(response);
        setIsLoading(false);
      } catch (err) {

      }
    }
    getArtigos();
  }, []);

  return (
    <Grid container spacing={2} minHeight={'60vh'} padding={3}>
        <Typography variant='h5'>Artigos da Maya!</Typography>
      {!isLoading && data ? (
        <Grid container sx={{margin:"2px"}} gap={4} flexWrap={'wrap'} justifyContent={'center'}>
              {data.map(({id,titulo,subtitulo,conteudo,created_at,imagem, curtidas}) => (
                <Grid item xs={12} sx={{margin:"2px"}} key={id} >
                  <Artigo
                    id={id}
                    curtidas={curtidas}            
                    titulo={titulo}
                    subtitulo={subtitulo}
                    conteudo={conteudo}
                    data={new Date(created_at).toLocaleDateString('pt-BR')}
                    imagem={imagem}
                    
                  />
                </Grid>
                ))}
          </Grid>
          ) : (
            <Box sx={{ p: 1, m: 1, width: {md:"100%"}, minHeight:'64vh'}} display="flex"
            justifyContent="center" flexDirection={'column'}>
              <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}><CircularProgress color="success"/></Box>
            </Box>
          )}     

        </Grid>
          
  );
}

export default Artigos;
