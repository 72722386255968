import React, { useState, useEffect } from 'react'
import { GenGet } from 'Admin/state/api';
import NovoCupom from './NovoCupom'
import { CircularProgress } from '@mui/material';

const CupomItem = ({cupom}) =>{
  
    return(
      <div className='flex flex-col max-w-[200px] p-4 rounded-md shadow-md bg-slate-50'>
        <h4 className='text-left text-[14pt]'>{cupom.nome}</h4>
        <p className='text-left text-[11pt] pl-2'>{cupom.descricao}</p>
        <p className='text-left text-[11pt] pl-2'>{cupom.codigo}</p>
        <div className='flex w-[100%] justify-end'>
          <button className='p-1 shadow-md text-[11pt] bg-cyan-500 rounded-md hover:bg-cyan-400'>Ver mais</button>
        </div>
      </div>
    );
  }

function Cupoms() {
    const [refresh, setRefresh]= useState(false);
    const [ loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [ cupoms, setCupoms] = useState([]);

    async function getCupoms() {
        try {
          const response = await GenGet(`/cupoms/index`);
          console.log(response)
          setCupoms(response);
          setList(response.ativos);

          setLoading(false);
          setRefresh(false)
        } catch (err) {
        }
      }

      const handleChange = (e) => {     
        const value = e.target.value;
            if (value === 'ativo') {
                setList(cupoms.ativos);
            } else if (value === 'inativo') {
                setList(cupoms.inativos);
            }
        };

      useEffect(() => {
        getCupoms();
      }, []);

      useEffect(() => {
        if (refresh) {  
          getCupoms();
        }
      }, [refresh]);

  return (
  <>
    {!loading ? (
        <div className='flex flex-col w-full text-[10pt]'>
            <h3 className='text-[14pt]'>Seus Cupoms</h3>
            <p className='text-[9pt] -mt-2'>crie e gerencie seus cupoms de desconto.</p>
            <hr/>
            <h3 className='text-[12pt]'>Cupoms cadastrados:</h3>
        <div className='flex flex-row justify-start gap-4 px-4'>
          <div className='flex flex-row justify-start gap-2'>
              <label className='font-semibold'>Status:</label>
              <input 
                  className={` bg-slate-300 rounded m-2 flex p-1.5`}                 
                  id="ativo"
                  name="ativo"
                  type="radio"
                  value="ativo"
                  onChange={handleChange}
              />
              <label htmlFor="ativo">Ativos</label>
              <input 
                  className={` bg-slate-300 rounded m-2 flex p-1.5`}                 
                  id="inativo"
                  name="ativo"
                  type="radio"
                  value="inativo"
                  onChange={handleChange}
              />
              <label htmlFor="inativo">Inativos</label>
          </div>
          </div>
          <div className='flex flex-wrap gap-2 px-4 pt-1'>
            {list && list.length > 0 ? list.map((item) =>{
                return <CupomItem cupom={item}/>
            }) : <p>nenhum cupom cadastrado.</p>}
          </div>

        <NovoCupom handleRefresh={setRefresh}/>
    </div>

    ) : (
        <div className='rounded-md flex flex-grow bg-slate-50 overflow-x-hidden overflow-y-scroll'>
        <div className='w-[100vw] sm:w-[85vw] h-[89vh] p-2 flex items-center justify-center'>
          <div className='flex flex-col items-center'>
            <CircularProgress />
          </div>
        </div>
      </div>
    )}
    </>
  )
}

export default Cupoms