import React, {useEffect, useState} from 'react';
import { 
  Box, 
  CircularProgress,
} from "@mui/material";
import axios from "Admin/state/axios";
import NovoArtigo from "../forms/artigos/NovoArtigo";
import GenericModal from 'Admin/components/GenericModal';
import { handleAdminArticle } from "Admin/components/HandleAdminArtigos";

const token = localStorage.getItem('token');


function Artigos(props) {
  const [data, setData] = useState({});
  const id = props.pointer;
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(false)

  const [modalSate, setModalState]= useState({
    active:false,
    component: null,
    pointer:null
  });
  const handleModals = ({ component, pointer }) => {
    setModalState({
      active: true,
      component: component,
      pointer: pointer
    });
  };

  async function getArtigos() {
      try {
        const response = await axios({
          url: `artigos/${id}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });
        setData(response.data);
        setRefresh(false);
        setIsLoading(false);
      } catch (err) {

      }
    }

  const handleDelete = (id) =>{
    async function deleteArtigos() {
      try {
        const response = await axios({
          url: `artigo/delete/${id}`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });
        setData(response.data);
        setRefresh(true);
        setIsLoading(false);
      } catch (err) {

      }
    }
    deleteArtigos();
  }


  useEffect(() => {  
    getArtigos();
  }, []);

  useEffect(() => {
    if (refresh){
      getArtigos();
    }
  }, [refresh]);

  return (<>{!isLoading ?<>
    <div className='flex gap-1 flex-col w-full text-[10pt]'>
    <GenericModal
          Component={modalSate.component}
          pointer={modalSate.pointer}
          active={modalSate.active}
          setActive={(active) => setModalState({ ...modalSate, active })}
          setRefresh={setRefresh}
        /> 
    
      {data.length > 0 ? <>
      <h2 className='text-[12pt]'>Artigos do Produto:</h2>
            <div className='flex flex-col flex-grow gap-2'>
            {Object.values(data).map((item, index) => (
              <div className='w-full' key={index}>{handleAdminArticle({ dataset: item, setState:handleModals, handleDelete:handleDelete })}</div>
            ))}
            </div>
            </> : (
                <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}>
                <p className='font-semibold text-slate-500'> Nenhum artigo encontrado</p>
                </Box>
          )}
    <div className='flex w-[100%] justify-end pt-2'>
      <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={() => handleModals({ component: NovoArtigo, pointer: id })}>novo artigo</button>
    </div>
    </div>
    </> : <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}><CircularProgress color="success"/></Box>}
    

  
 </>);
}

export default Artigos;