import React, {useState} from 'react';
import { Alert} from "@mui/material";
import { GenPost } from 'Admin/state/api';

const NovoUsuario = () => {
  const [msg, setMsg] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    nome: "",
    sobrenome: "",
    telefone: "",
    cpf:"",
  });

  const newUser = {
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    nome: "",
    sobrenome: "",
    telefone: "",
    cpf:"",
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  }

  const formatarTelefone = (valor) => {
    // Remove todos os caracteres não numéricos
    const cleaned = valor.replace(/\D/g, '');
    // Aplica a máscara para 10 ou 11 dígitos
    if (cleaned.length === 10) {
      return cleaned.replace(/(\d{2})(\d{4})(\d{4})/, '($1)$2-$3');
    } else if (cleaned.length === 11) {
      return cleaned.replace(/(\d{2})(\d{5})(\d{4})/, '($1)$2-$3');
    }
    return valor;
  };

  const validateForm = () => {
    for (let key in formData) {
      if (formData[key] === "") {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setMsg("Preencha todos os campos");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
      return;
    }

    async function PostForm() {
      console.log(formData)
      try {
        const response = await GenPost('newuser', formData);
        setMsg(response.message);
        setTimeout(() => {
          setMsg(null);
        }, 3000);
        setFormData(newUser);
      } catch (err) {
      setMsg(err.message);
      setTimeout(() => {
          setMsg(null);
        }, 3000);
      }
    }
    PostForm();
  };

  return ( 
    <div className='flex flex-col gap-2 mt-1 p-2'>
        <h3 className='text-[14pt]'>Novo usuário</h3>
        <p className='text-[9pt] -mt-2'>cadastre um novo usuário no sistema</p>
        <hr/>
          <div className='flex flex-col gap-1 w-[100%] flex-wrap p-2'>
            <h3 className='text-[12pt]'> Dados de Usuário</h3>
            <input 
              className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
              id="name"
              name="name"
              placeholder="Nome de usuário"
              value={formData.name} 
              onChange={handleChange}  
              />
            <input 
              className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
              id="email"
              name="email"
              placeholder="Email"
              value={formData.email} 
              onChange={handleChange}  
              />
              
            <input 
              className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
              id="password"
              name="password"
              placeholder="Senha"
              type='password'
              value={formData.password} 
              onChange={handleChange}  
              />
            <input 
              className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
              id="password_confirmation"
              name="password_confirmation"
              type='password'
              placeholder="Confirme a Senha"
              value={formData.password_confirmation} 
              onChange={handleChange}  
              />
            <hr/>
            <h3 className='text-[12pt]'> Dados Pessoais</h3>
            <input 
              className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
              id="nome"
              name="nome"
              placeholder="Nome"
              value={formData.nome} 
              onChange={handleChange}  
              />
            <input className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
              id="sobrenome"
              name="sobrenome"
              placeholder="Sobrenome"
              value={formData.sobrenome} 
              onChange={handleChange}  
              />
  
            </div>
          <div  className='flex flex-col gap-1 w-[100%] flex-wrap p-2'>
          <input
            className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
            id="cpf"
            name="cpf"
            placeholder="CPF"
            maxLength={11}
            value={formData.cpf} 
            onChange={(e) => {
              // Remove all non-numeric characters
              const value = e.target.value.replace(/\D/g, '');
              
              // Call handleChange once with the modified value
              handleChange({
                target: {
                  name: 'cpf',
                  value,
                },
              });
            }}
          />
            <input
                className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
                id="telefone"
                name="telefone"
                maxLength={14}
                placeholder="Telefone (com DDD) digite apenas numeros."
                value={formatarTelefone(formData.telefone)}
                onChange={handleChange}
              />
          </div>
          <div className='w-full flex flex-row-reverse pt-2'>
          <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handleSubmit}>Cadastrar</button>
          {msg ? 
            <div className='flex justify-end p-1' >
              <Alert severity="info" sx={{width:320}} > {msg} </Alert>
            </div>
          : null}
          </div>
          </div> 
  )
}

export default NovoUsuario;