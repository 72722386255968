import React, { useState, useEffect } from 'react'
import { Typography, Box } from '@mui/material';

function Apresentacao() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <Box>

    </Box>

  )
}

export default Apresentacao