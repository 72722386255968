import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Typography,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { GetVendas, GetVendasGrid, GetVendasSales, GetVendasSalesGrid } from "Admin/state/api";

const colunas = [
  {
    field: "id",
    headerName: "id",
    flex: 0.3,
  },
  {
    field: "ordem_id",
    headerName: "id Pedido",
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 0.8,
  },
  {
    field: "tipo",
    headerName: "Tipo",
    flex: 1,
  },
  {
    field: "created_at",
    headerName: "Data",
    flex: 0.8,
  },
  {
    field: "valor",
    headerName: "Valor",
    flex: 1,
  },
];

function VendasVendedor() {
  const [data, setData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState(null);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  useEffect(() => {
    async function getVendas() {
      try {
        const response = await GetVendasSales();
        setData(response);
      } catch (err) {
        console.error(err);
      }
    }

    async function getVendasGrid() {
      try {
        const dataGrid = await GetVendasSalesGrid();
        setGridData(dataGrid);
      } catch (err) {
        console.error(err);
      }
    }

    Promise.all([getVendas(), getVendasGrid()]).then(() => {
      setIsLoading(false);
    });
  }, [setData, setGridData]);

  const filteredData = filter
    ? gridData.filter(
        (item) =>
          item.id?.toString().includes(filter) ||
          item.ordem_id?.toString().includes(filter)
      )
    : gridData;

  if (isLoading) {
    return (
      <div className="flex justify-center mt-10 mb-10">
        <CircularProgress color="success" />
      </div>
    );
  }

  return (
    <div className="container mx-auto p-5">
      <Typography variant="h6">Resumo de vendas</Typography>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">      
        <div className="shadow rounded bg-white p-4 col-span-2 md:col-span-1">
          <Typography variant="h6">Hoje</Typography>
          <hr />
          <div className="h-36 flex items-center justify-center">
            <Typography variant="h5">{data.vendas.hoje}</Typography>
          </div>
        </div>

        <div className="shadow rounded bg-white p-4 col-span-2 md:col-span-1">
          <Typography variant="h6">Este Mês</Typography>
          <hr />
          <div className="h-36 flex items-center justify-center">
            <Typography variant="h5">{data.vendas.mes}</Typography>
          </div>
        </div>

        <div className="shadow rounded bg-white p-4 col-span-2 md:col-span-1">
          <Typography variant="h6">Este Ano</Typography>
          <hr />
          <div className="h-36 flex items-center justify-center">
            <Typography variant="h5">{data.vendas.ano}</Typography>
          </div>
        </div>

        <div className="shadow rounded bg-white p-4 col-span-2 md:col-span-1">
          <Typography variant="h6">Total</Typography>
          <hr />
          <div className="h-36 flex items-center justify-center">
            <Typography variant="h5">{data.vendas.todas.length}</Typography>
          </div>
        </div>
        <div className="shadow rounded bg-white p-4 col-span-2">
          <div className="flex justify-between">
            <Typography variant="h6">Vendas</Typography>
            <TextField
              id="outlined-basic"
              label="Buscar por id"
              variant="outlined"
              size="small"
              color="secondary"
              onChange={(event) => {
                setFilter(event.target.value);
              }}
            />
          </div>
          <hr />
          <div className="h-96">
            <DataGrid
              rows={filteredData}
              columns={colunas}
              pageSize={5}
              checkboxSelection
              disableSelectionOnClick
            />
          </div>
        </div>

        
      </div>
    </div>
  );
}

export default VendasVendedor;
