import { React} from "react";
import { Outlet } from "react-router-dom";
import { Box} from "@mui/material";
import Footer from '../../components/compLoja/Footer';
import NavBar from '../../components/compLoja/NavBar';
import PoliticaAviso from "Loja/components/compLoja/PoliticaAviso";

const Layout = () => {

  return (
    <Box>
      <NavBar/>
        <div className="flex-grow mt-[15px]">
          <Outlet />
        </div>
      <Footer/>
      <PoliticaAviso/>
    </Box>
          
  );
};

export default Layout;