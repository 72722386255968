import React, {useState, useEffect, createContext} from 'react'
import { CssBaseline} from "@mui/material";
import { Navigate, Route, Routes, useLocation  } from "react-router-dom";
import {ReqUserAuth} from 'Loja/components/RequireAuth';
import Layout from "./Loja/scenes/layout/Index"
import LojaIndex from './Loja/scenes/lojaIndex/Index';
import Produtos from './Loja/scenes/produtos/Index';
import NovaSenha from 'Loja/scenes/novaSenha/Index';
import EsqueceuSenha from 'Loja/scenes/esqueceuSenha/Index';
import Login from 'Loja/scenes/login/Login';
import ContaUsr from 'Loja/scenes/usuario/ContaUsr';
import PerfilUsuario from 'Loja/scenes/usuario/PerfilUsuario';
import Pagamento from 'Loja/scenes/pagamento/Index';
import { GenGet } from 'Loja/state/api';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Artigos from 'Loja/scenes/artigos/Index';
import Featured from 'Loja/scenes/featured/Index';
import NotFound from 'Loja/scenes/404/Index';
import PedidoFinalizado from 'Loja/scenes/finalizado/Index';
import PedidosUsr from 'Loja/scenes/pedidos/Index';
import PainelCliente from 'Loja/scenes/usuario/PainelCliente';
import MultiStep from 'Loja/scenes/novoUsuario/MultiStep';
import Verify from 'Loja/scenes/verify/Index';
import ProdutoDet2 from 'Loja/scenes/produtoDet/Index2';
import ProdutosCategoria from './Loja/scenes/produtos/ProdutoCategoria';
import FinalizarPedido from 'Loja/scenes/ordem/FinalizarPedido';
import TermosDeUso from 'Loja/scenes/Politicas/TermosDeUso';
import TermoDevolucoes from 'Loja/scenes/Politicas/TermoDevolucoes';

import { CombinedAuth } from 'context/Auth';


const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(','),
  },
});

export const CartContext = createContext();
export const EnvContext = createContext();
const cartFromLocal = JSON.parse(localStorage.getItem('cart')  || '[]');
const token = localStorage.getItem('token');

function EcommerceRoot() {

  const [cart, setCart] = useState(cartFromLocal);
  const [frete_infos, setFreteInfos] = useState([]);
  const [logging, setLogging] = useState(false);
  const [cartEvent, setcartEvent] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const appUrl = process.env.REACT_APP_BASE_URL;

  const [track, setTrack] = useState(`${appUrl}`)
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
    
    //ajuste para sessão realizado aqui, observar...
    useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const checkTokenValidity = async () => {
        try {
        const authResult = await CombinedAuth();
        const isLogged = authResult.user;

        if (!isLogged) {
          localStorage.removeItem('token');
          window.location.reload()
        }

      } catch (err) {
        localStorage.removeItem('token');
        window.location.reload()
      }
      };

      checkTokenValidity();
    }
    }, []);


  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart))
    if (!logging && cartEvent || refresh) {
    
    if(token){
    const getNewCart = async () => {
      localStorage.setItem('cart', []);
      let cart = await GenGet(`api/ucart`);

      if (cart.cartitens){
        newCart(cart.cartitens);
        setFreteInfos(cart.frete_infos);
      }

    };
      getNewCart();
      setcartEvent(false);
      setRefresh(false)
    }else{
      localStorage.setItem('cart', JSON.stringify(cart));
    }
  }   
  },[token, logging, cartEvent, refresh, cart]);



  const addCart = (product) => {
    setCart([...cart, product]);
    localStorage.setItem('cart', JSON.stringify(cart));
  };
  
  const newCart = (cart) => {
    setCart(cart);
  };

  const rmvCart = (id) => {
    const novoCarrinho = cart.filter((item) => item.produto_id !== id);
    setCart(novoCarrinho);
    
  };

  const setCartIten = (updatedProduct) => {
   
    const updatedCart = cart.map((item) => {
      if (item.produto_id === updatedProduct.produto_id) {
        return { ...item, quantidade: updatedProduct.quantidade };
      } else {

        return item;
      }
    });
    setCart(updatedCart);
  };

  return (
    <div className="app">
<ThemeProvider theme={theme}>
        <CartContext.Provider value={{ frete_infos, setFreteInfos ,cart, addCart, rmvCart, setCartIten, newCart, setLogging, setcartEvent, track, setTrack, appUrl }}>
        <EnvContext.Provider value={{appUrl}}> 
        <CssBaseline />
        <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<LojaIndex/>} />
                <Route path="/produtos" element={<Produtos />} />
                <Route path="/produtos/:categoria" element={<ProdutosCategoria />} />
                <Route path="/produto/:slug" element={<ProdutoDet2/>} />
                <Route path='/login' element={<Login/>}/>         
                <Route path="/home" element={<Navigate to="/" replace />} />
                <Route path="/termos" element={<TermosDeUso/>} />
                <Route path="/politica-devolucao" element={<TermoDevolucoes/>} />     
                <Route path='/artigos' element={<Artigos/>} />
                <Route path='/destaques' element={<Featured/>} />
                <Route path="/novousuario" element={<MultiStep/>} />    
                <Route path="/novasenha" element={<NovaSenha/>} />
                <Route path="/recuperarsenha" element={<EsqueceuSenha/>} />
                <Route path="/novasenha/:token" element={<NovaSenha/>} />
                <Route path='/confirmar-conta/:token' element={<Verify/>}/>
                <Route path="/*" element={<Navigate to="/" replace />} />
                <Route path="/novaordem" element={<FinalizarPedido/>} />
                <Route path="/finalizar" element={<Pagamento/>} />
                <Route path="/finalizado" element={<PedidoFinalizado/>}/>
              <Route element={<ReqUserAuth/>}>              
                <Route path='/meuperfil' element={<PerfilUsuario/>}/>
                <Route path='/meupainel' element={<PainelCliente/>}/>
                <Route path='/pedidos' element={<PedidosUsr/>}/>
                <Route path='/minhaconta' element={<ContaUsr/>}/>
              </Route>
            </Route>    
        </Routes>
        </EnvContext.Provider>
        </CartContext.Provider>
        </ThemeProvider>
    </div>
  );
}

export default EcommerceRoot;

/* 
import Sobre from 'Loja/scenes/institucional/Sobre';
import Contato from 'Loja/scenes/institucional/Contato';


                <Route path="/sobre" element={<Sobre/>} />
                <Route path="/contato" element={<Contato/>} />  

*/