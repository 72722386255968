import React, { useEffect, useState } from 'react';
import { GenGet } from 'Admin/state/api';
import NovoEmailTemplate from './NovoEmailTemplate';
import GenericDataGrid from 'Admin/components/DataGrids/GenericDataGrid';
import EmailTemplate from './EmailTemplate';
import { CircularProgress, Dialog, DialogContent } from '@mui/material';

function EmailTemplates() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [pointer, setPointer] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const fetchData = async () => {
    try {
      const response = await GenGet('/email/templates');
      setData(response.lista);
      setLoading(false);
      setRefresh(false);
    } catch (err) {
      console.error('Error fetching fretes:', err);
    }
  };

  const handleTemplate = (id) =>{  
    const template = data.find(item => item.id === id);
    if (template) {
      setPointer(template.id);
      setOpen(true);
    } else console.log(id)
  }

  const handleCloseLogin = () => {
    setOpen(false);
    setPointer(null);
  };

  useEffect(() => {
    fetchData();
  }, []);
  
  useEffect(() => {
    if (refresh) {
      fetchData();      
    }
  }, [refresh]);

  const actions = [{ action: 'Visualizar', function: handleTemplate }];
  const columns = ['ID', 'Nome', 'Tipo','Título'];

  return (
    <div className='flex flex-col w-full p-2 text-[10pt]'>
    <h3 className='text-[14pt]'>Templates de email</h3>
    <p className='text-[9pt] -mt-2'>Configurações de seus templates</p>
    <hr/>
    <Dialog open={open} onClose={handleCloseLogin} style={{minWidth:'70vw'}}>
        <DialogContent>
          <EmailTemplate id={pointer} />
        </DialogContent>
      </Dialog>
    {!loading ? (
      <GenericDataGrid rows={data} columns={columns} per_page={5} actions={actions} title="Templates Cadastrados"/>
    ):(
      <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
        <CircularProgress color="success"/>
      </div>
    )}
    <hr/>
    <NovoEmailTemplate />
    </div>
  )
}

export default EmailTemplates